import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
    DateAdapter,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { NgxMaskModule, IConfig } from 'ngx-mask';

import {SharedModule} from '../shared/shared.module';
import {PagesModule} from './pages-routing.module';

import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { MainComponent } from './main/main.component';

import {LoginComponent} from "./login/login.component";
import {SignUpComponent} from "./signup/signup.component";

import {MatSliderModule} from '@angular/material/slider';
import {FocusMonitor} from "@angular/cdk/a11y";
import {Platform} from "@angular/cdk/platform";

import { NgxCurrencyModule } from "ngx-currency";
import {HomeComponent} from "./home/home.component";
import {SellComponent} from "./sell/sell.component";
import {NewUserComponent} from "./sell/new-user/new-user.component";
import {ExistingUserComponent} from "./sell/existing-user/existing-user.component";
import {ExistingUserInfoComponent} from "./sell/existing-user-info/existing-user-info.component";
import {DatepickerCustomHeaderExample, ExampleHeader} from "../shared/datapicker-header/datapicker-header";
import {DemoMaterialModule} from "../material.module";
import {InstallComponent} from "./install/install.component";
import {NgxDateRangeModule} from "ngx-daterange";
import {CustomDateAdapter} from "../adapters/custom-date-adapter";
import {TermsAndConditionsComponent} from "./terms-and-conditions/terms-and-conditions.component";
import {PopupSellItemComponent} from "./sell/popup-sell-item/popup-sell-item.component";
import {PopupDepositComponent} from "./sell/popup-deposit/popup-deposit.component";
import {ReportsComponent} from "./reports/reports.component";
import {SellLocationNavComponent} from "./sell-location-nav/sell-location-nav.component";
import {PopupSettingsComponent} from "./sell/popup-settings/popup-settings.component";
import {PopupReportsTableComponent} from "./reports/popup-reports-table/popup-reports-table.component";
import {PopupReportsDetailsComponent} from "./reports/popup-reports-details/popup-reports-details.component";
import {SettingsComponent} from "./settings/settings.component";
import {PopupSettingsStripeComponent} from "./settings/popup-settings-stripe/popup-settings-stripe.component";
import {PopupSettingsSquareComponent} from "./settings/popup-settings-square/popup-settings-square.component";
import {PopupSettingsPaypalComponent} from "./settings/popup-settings-paypal/popup-settings-paypal.component";
import {PopupSellCheckboxesComponent} from "./sell/popup-sell-checkboxes/popup-sell-checkboxes.component";
import {TicketsComponent} from "./tickets/tickets.component";
import {TerminalComponent} from "./terminal/terminal.component";
import {PopupTerminalSettingsComponent} from "./terminal/popup-terminal-settings/popup-terminal-settings.component";
import {UsersComponent} from "./users/users.component";
import {PopupUsersSettingsComponent} from "./users/popup-users-settings/popup-users-settings.component";
import {CashRegisterComponent} from "./cash-register/cash-register.component";
import {PopupCashRegisterOpeningComponent} from "./cash-register/popup-cash-register-opening/popup-cash-register-opening.component";
import {PopupSettingsPaynoteComponent} from "./settings/popup-settings-paynote/popup-settings-paynote.component";

@NgModule({
    declarations: [
        MainComponent,
        LoginComponent,
        SignUpComponent,

        HomeComponent,

        SellComponent,
        NewUserComponent,
        ExistingUserComponent,
        ExistingUserInfoComponent,

        DatepickerCustomHeaderExample,
        ExampleHeader,
        InstallComponent,

        TermsAndConditionsComponent,
        PopupSellItemComponent,
        PopupDepositComponent,
        ReportsComponent,
        SellLocationNavComponent,
        PopupSettingsComponent,
        PopupReportsTableComponent,
        PopupReportsDetailsComponent,
        SettingsComponent,
        PopupSettingsStripeComponent,
        PopupSettingsSquareComponent,
        PopupSettingsPaypalComponent,
        PopupSettingsPaynoteComponent,
        PopupSellCheckboxesComponent,

        TerminalComponent,
        PopupTerminalSettingsComponent,

        TicketsComponent,

        UsersComponent,
        PopupUsersSettingsComponent,
        CashRegisterComponent,
        PopupCashRegisterOpeningComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PagesModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        MatRadioModule,
        MatDialogModule,
        SharedModule,
        NgxMaskModule.forRoot(),
        SweetAlert2Module.forRoot(),
        MatSliderModule,
        // ChartsModule,
        NgxCurrencyModule,
        // CarouselModule,
        // SwiperModule,
        // SlickCarouselModule
        DemoMaterialModule,
      NgxDateRangeModule,
    ],
    providers: [
        FocusMonitor,
        Platform,
        {provide: DateAdapter, useClass: CustomDateAdapter }
        // {
        //     provide: SWIPER_CONFIG,
        //     useValue: DEFAULT_SWIPER_CONFIG
        // }
    ],
    exports: [
        LoginComponent,
        SignUpComponent,
        SellComponent,
        DatepickerCustomHeaderExample,
        ExampleHeader,
        InstallComponent
    ],
    entryComponents: [
        LoginComponent,
        SignUpComponent,
        SellComponent,
        DatepickerCustomHeaderExample, ExampleHeader
    ]
})
export class ChatQuizModule { }
