<div class="page page--secondary page--scrolled" [class.page--blured]="popupDeposit.show || popupSellItem.show || popupSettings.show">
    <div class="page__inner  js-history-parent">
        <h1 class="page__title">Home</h1>
        <div class="page__body" (scroll)="scroll($event, historyData)">
            <div class="sell-header-wrapper" [class.is-admin]="isUserRoleAdmin()">
                <div class="sell-header">
                    <div class="sell-header__balance" [class.is-operator]="isUserRoleOperator()">
                        <ng-container *ngIf="!isUserRoleAdmin()">
                            <div>Your balance:</div>
                            <div>{{balance | currency}}</div>
                        </ng-container>
                    </div>
                    <button class="button snake-btn is-notouched sell-header__button"
                            *ngIf="!isUserRoleAdmin()"
                            (click)="requestRefill()">
                        REQUEST REFILL
                        <svg><rect></rect></svg>
                    </button>
                </div>
                <div class="sell-header">
<!--                    <button class="button snake-btn is-notouched sell-header__button"-->
<!--                            *ngIf="isUserRoleOperator()"-->
<!--                            (click)="showSettingsPopup()">-->
<!--                        Settings-->
<!--                        <svg><rect></rect></svg>-->
<!--                    </button>-->
                    <button class="button snake-btn is-notouched sell-header__button sell-header__create-btn"
                            (click)="showSellItemPopup(POPUP_SELL_ITEM_TYPE.create, null)">
                        Create
                        <svg><rect></rect></svg>
                    </button>
                </div>
                <div class="sell-header" *ngIf="isUserRoleOperator()">
                    <button class="button snake-btn is-notouched sell-header__button sell-header__create-btn"
                            (click)="goToSettings()">
                        Settings
                        <svg><rect></rect></svg>
                    </button>
                </div>
            </div>

            <div class="history-table-wrapper">
                <table class="history-table">
                    <thead class="history-table__head">
                    <tr class="js-history-item">
                        <th width="10">Id</th>
                        <th>Name</th>
                        <th>Balance</th>
                        <th>Share</th>
                        <th class="history-table__settings">Settings</th>
                    </tr>
                    </thead>
                    <tbody class="history-table__body">
                    <tr *ngFor="let item of historyData.items" class="js-history-item">
                        <td>{{item.id}}</td>
                        <td>{{item.name}}</td>
                        <td>{{item.balance | currency}}</td>
                        <td>{{item.rate ? item.rate : 0}}%</td>
                        <td class="history-table__settings">
                            <button class="history-table__button" (click)="showDeposit(item.id)">Deposit</button>
                            <button class="edit-btn" (click)="showSellItemPopup(POPUP_SELL_ITEM_TYPE.edit, item.id)">
                                <svg>
                                    <use [attr.xlink:href]="'../../../../assets/images/sprite.svg#pencil'"></use>
                                </svg>
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<app-popup-sell-item [show]="popupSellItem.show"
                     [info]="popupSellItem.info"
                     (onClose)="closePopup($event)"></app-popup-sell-item>
<app-popup-deposit [show]="popupDeposit.show"
                   [info]="popupDeposit.info"
                   (onClose)="closePopup($event)"></app-popup-deposit>
<app-popup-settings [show]="popupSettings.show"
                   (onClose)="closePopup($event)"></app-popup-settings>
