import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SettingsService} from "../../../service/settings.service";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {PROJECT_MESSAGES} from "../../../config/project";
import {LoaderService} from "../../../service/loader.service";

@Component({
    selector: 'app-popup-settings-paypal',
    templateUrl: './popup-settings-paypal.component.html',
    styleUrls: ['./popup-settings-paypal.component.scss']
})
export class PopupSettingsPaypalComponent implements OnInit {
    @Input() show: boolean;
    @Output('onClose') emitClose: EventEmitter<any> = new EventEmitter();

    popupForm: FormGroup;

    constructor(private formBuilder: FormBuilder,
                private popupComponent: PopupComponent,
                private loaderService: LoaderService,
                private settingsService: SettingsService) {
        this.popupForm = this.formBuilder.group({
            name: [null, Validators.required],
            clientId: [null, Validators.required],
            clientSecret: [null, Validators.required]
        });
    }

    ngOnInit() {}

    ngOnChanges(changes) {

    }

    closePopup(needUpdate = false) {
        this.popupForm.reset();
        this.emitClose.emit(needUpdate);
    }

    onSubmit() {
        const name = this.popupForm.controls.name.value;
        const clientId = this.popupForm.controls.clientId.value;
        const clientSecret = this.popupForm.controls.clientSecret.value;
        if (name && clientId && clientSecret) {
            this.settingsService.addPaypalItem(name, clientId, clientSecret).subscribe((data) => {
                this.handleSubmitResult(data);
            }, () => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else {
            this.popupComponent.showPopupError({text: PROJECT_MESSAGES.notAllFieldsAreFilled});
        }
    }

    handleSubmitResult(data) {
        try {
            if (data.status) {
                this.popupComponent.showPopupSuccess({text: data.message});
                this.closePopup(true);
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }
}
