/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./users.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./popup-users-settings/popup-users-settings.component.ngfactory";
import * as i4 from "./popup-users-settings/popup-users-settings.component";
import * as i5 from "@angular/forms";
import * as i6 from "../../shared/popup/popup.component";
import * as i7 from "../../service/loader.service";
import * as i8 from "../../service/users.service";
import * as i9 from "./users.component";
import * as i10 from "../../service/blurMenu.service";
import * as i11 from "../../service/userRole.service";
var styles_UsersComponent = [i0.styles];
var RenderType_UsersComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UsersComponent, data: { "animation": [{ type: 7, name: "slideToLeft", definitions: [{ type: 0, name: "void", styles: { type: 6, styles: { position: "fixed", width: "100%" }, offset: null }, options: undefined }, { type: 0, name: "*", styles: { type: 6, styles: { position: "fixed", width: "100%" }, offset: null }, options: undefined }, { type: 1, expr: ":enter", animation: [{ type: 6, styles: { transform: "translateX(100%)" }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateX(0%)" }, offset: null }, timings: "0.5s ease-in-out" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 6, styles: { transform: "translateX(0%)" }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateX(100%)" }, offset: null }, timings: "0.5s ease-in-out" }], options: null }], options: {} }] } });
export { RenderType_UsersComponent as RenderType_UsersComponent };
function View_UsersComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "tr", [["class", "js-stripe-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 5, "td", [["style", "text-align: center; display: flex; align-items: baseline; justify-content: space-between; min-width: 8rem"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "button", [["class", "edit-btn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onEditItem(_v.context.$implicit.user_id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, ":svg:svg", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, ":svg:use", [], [[1, ":xlink:href", 0]], null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "history-table__button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDelete(_v.context.$implicit.user_id, _v.context.$implicit.user_name) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Delete"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.user_name; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.getUserRole(_v.context.$implicit.role_short_name); _ck(_v, 4, 0, currVal_1); var currVal_2 = "../../../../assets/images/sprite.svg#pencil"; _ck(_v, 8, 0, currVal_2); var currVal_3 = !_co.isCashierType(_v.context.$implicit.role_short_name); _ck(_v, 9, 0, currVal_3); }); }
export function View_UsersComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 23, "div", [["class", "page page--scrolled"]], [[2, "page--blured", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 22, "div", [["class", "page__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["class", "page__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Users"])), (_l()(), i1.ɵeld(4, 0, null, null, 19, "div", [["class", "page__body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "header-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "sell-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "button", [["class", "button snake-btn is-notouched sell-header__button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCreate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Create "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, ":svg:svg", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, ":svg:rect", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 12, "div", [["class", "history-table-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 11, "table", [["class", "history-table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 7, "thead", [["class", "history-table__head"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 6, "tr", [["class", "js-history-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Login"])), (_l()(), i1.ɵeld(17, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Type"])), (_l()(), i1.ɵeld(19, 0, null, null, 1, "th", [["style", "text-align: center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Settings"])), (_l()(), i1.ɵeld(21, 0, null, null, 2, "tbody", [["class", "history-table__body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UsersComponent_1)), i1.ɵdid(23, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(24, 0, null, null, 1, "app-popup-users-settings", [], null, [[null, "onClose"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClose" === en)) {
        var pd_0 = (_co.onCloseEditPopup($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_PopupUsersSettingsComponent_0, i3.RenderType_PopupUsersSettingsComponent)), i1.ɵdid(25, 638976, null, 0, i4.PopupUsersSettingsComponent, [i5.FormBuilder, i6.PopupComponent, i7.LoaderService, i8.UsersService], { show: [0, "show"], isEditing: [1, "isEditing"], id: [2, "id"] }, { emitClose: "onClose" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.pageData; _ck(_v, 23, 0, currVal_1); var currVal_2 = _co.editPopup.show; var currVal_3 = _co.editPopup.isEditing; var currVal_4 = _co.editPopup.id; _ck(_v, 25, 0, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.editPopup.show; _ck(_v, 0, 0, currVal_0); }); }
export function View_UsersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-users", [], [[40, "@slideToLeft", 0]], null, null, View_UsersComponent_0, RenderType_UsersComponent)), i1.ɵdid(1, 114688, null, 0, i9.UsersComponent, [i6.PopupComponent, i5.FormBuilder, i7.LoaderService, i10.BlurMenuService, i11.UserRoleService, i8.UsersService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
var UsersComponentNgFactory = i1.ɵccf("app-users", i9.UsersComponent, View_UsersComponent_Host_0, {}, {}, []);
export { UsersComponentNgFactory as UsersComponentNgFactory };
