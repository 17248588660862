/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tickets.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./tickets.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "../../service/loader.service";
import * as i6 from "../../shared/popup/popup.component";
import * as i7 from "../../service/support/support.service";
var styles_TicketsComponent = [i0.styles];
var RenderType_TicketsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TicketsComponent, data: { "animation": [{ type: 7, name: "slideToLeft", definitions: [{ type: 0, name: "void", styles: { type: 6, styles: { position: "fixed", width: "100%" }, offset: null }, options: undefined }, { type: 0, name: "*", styles: { type: 6, styles: { position: "fixed", width: "100%" }, offset: null }, options: undefined }, { type: 1, expr: ":enter", animation: [{ type: 6, styles: { transform: "translateX(100%)" }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateX(0%)" }, offset: null }, timings: "0.5s ease-in-out" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 6, styles: { transform: "translateX(0%)" }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateX(100%)" }, offset: null }, timings: "0.5s ease-in-out" }], options: null }], options: {} }] } });
export { RenderType_TicketsComponent as RenderType_TicketsComponent };
function View_TicketsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "iframe", [["frameborder", "0"], ["height", "100%"], ["width", "100%"]], [[8, "src", 5]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.frameUrl; _ck(_v, 0, 0, currVal_0); }); }
export function View_TicketsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "page page--scrolled"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "page__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "page__body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TicketsComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.frameUrl; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_TicketsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tickets", [], [[40, "@slideToLeft", 0]], null, null, View_TicketsComponent_0, RenderType_TicketsComponent)), i1.ɵdid(1, 114688, null, 0, i3.TicketsComponent, [i4.DomSanitizer, i5.LoaderService, i6.PopupComponent, i7.SupportService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
var TicketsComponentNgFactory = i1.ɵccf("app-tickets", i3.TicketsComponent, View_TicketsComponent_Host_0, {}, {}, []);
export { TicketsComponentNgFactory as TicketsComponentNgFactory };
