import {Injectable} from '@angular/core';
import {SELL_CONFIG} from "../../config/api";
import {HttpClient} from "@angular/common/http";
import {LoaderService} from "../loader.service";

@Injectable({
    providedIn: 'root'
})
export class SellService {
    private config = SELL_CONFIG;

    constructor(private http: HttpClient,
                private loaderService: LoaderService) {}

    getPageInfo(page) {
        this.loaderService.show();
        return this.http.post<any>(this.config.getPageInfo, {page});
    }

    getItemInfo(id?) {
        this.loaderService.show();
        return this.http.post<any>(this.config.getItemInfo, {id});
    }

    onDeposit(id, amount) {
        this.loaderService.show();
        return this.http.post<any>(this.config.onDeposit, {id, amount});
    }

    onCreate(name, login, password, rate, email, games, payments, credentials, redeems, cashMessage, cashTickets, timeZoneId, cashPayoutLimit, referralBonus, cashRegister, nftBonusWheel, commission, support, promoCode?) {
        this.loaderService.show();
        if (promoCode !== null) {
            return this.http.post<any>(this.config.onCreate, {name, login, password, rate, email, games, payments, credentials, redeems, cashMessage, cashTickets, timeZoneId, cashPayoutLimit, referralBonus, cashRegister, nftBonusWheel, commission, support, promoCode});
        } else {
            return this.http.post<any>(this.config.onCreate, {name, login, password, rate, email, games, payments, credentials, redeems, cashMessage, cashTickets, timeZoneId, cashPayoutLimit, referralBonus, cashRegister, nftBonusWheel, commission, support});
        }
    }

    onEdit(id, name, login, password, rate, email, games, payments, credentials, redeems, cashMessage, cashTickets, timeZoneId, cashPayoutLimit, referralBonus, cashRegister, nftBonusWheel, commission, support, promoCode?) {
        this.loaderService.show();
        if (promoCode !== null) {
            return this.http.post<any>(this.config.onEdit, {id, name, login, password, rate, email, games, payments, credentials, redeems, cashMessage, cashTickets, timeZoneId, cashPayoutLimit, referralBonus, cashRegister, nftBonusWheel, commission, support, promoCode});
        } else {
            return this.http.post<any>(this.config.onEdit, {id, name, login, password, rate, email, games, payments, credentials, redeems, cashMessage, cashTickets, timeZoneId, cashPayoutLimit, referralBonus, cashRegister, nftBonusWheel, commission, support});
        }
    }

    getLimit() {
        this.loaderService.show();
        return this.http.post<any>(this.config.getLimit, {});
    }

    requestRefill() {
        this.loaderService.show();
        return this.http.post<any>(this.config.requestRefill, {});
    }

    getCredentialsList(shortName) {
        this.loaderService.show();
        return this.http.post<any>(this.config.getCredentialsList, {shortName});
    }
}
