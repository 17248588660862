<div class="child" [class.child--blured]="menu">
    <router-outlet></router-outlet>
</div>

<div class="menu" [class.is-blurred]="menuBlurred">
    <a routerLinkActive="is-active" class="menu__link" [routerLink]="getHomeLink()">
        Home
    </a>
    <a routerLinkActive="is-active" class="menu__link" [routerLink]="getReportsLink()">
        REPORTS
    </a>
    <a *ngIf="isUserRoleLocation() || isUserRoleCashier()"
       routerLinkActive="is-active"
       class="menu__link"
       [routerLink]="getTicketsLink()">
        Tickets
    </a>
    <button (click)="menu = true" class="menu__btn">
        <svg class="menu__burger" width="28" height="17">
            <use xlink:href="../../../../assets/images/sprite.svg#burger"></use>
        </svg>
    </button>
</div>
<div class="addition-menu" [class.is-active]="menu">
    <ul class="addition-menu__list">
<!--        <li (click)="goToDirectDeposit()" class="addition-menu__item">Direct Deposit</li>-->
<!--        &lt;!&ndash;<li (click)="goToCollectionsSummary()" class="addition-menu__item">Collections Summary</li>&ndash;&gt;-->
<!--        <li (click)="goToCollections()" class="addition-menu__item">Collections Details</li>-->
<!--        <li *ngIf="!isRiverPay" (click)="goToTermsAndConditions()" class="addition-menu__item">Terms and Conditions</li>-->
<!--        <li (click)="goToFillRequestForm()" class="addition-menu__item">Fill Request</li>-->
        <li (click)="upgradeApp()" class="addition-menu__item">Upgrade APP</li>
        <li *ngIf="isUserRoleLocation()"
            (click)="goToUsersPage()"
            class="addition-menu__item">Users</li>
        <li *ngIf="isUserRoleAdminOrOperator()"
            (click)="goToTerminalPage()"
            class="addition-menu__item">Terminals</li>
        <li (click)="logout()" class="addition-menu__item">Logout</li>
    </ul>
    <button (click)="menu = false" class="addition-menu__btn">Cancel</button>
</div>
