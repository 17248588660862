import {Component, OnInit} from '@angular/core';
import {slideToLeft} from "../../animations/router.animations";
import {PopupComponent} from "../../shared/popup/popup.component";
import {LoaderService} from "../../service/loader.service";
import {BlurMenuService} from "../../service/blurMenu.service";
import {UserRoleService} from "../../service/userRole.service";
import {UsersService} from "../../service/users.service";
import {FormBuilder} from "@angular/forms";
import {USER_ROLES_SHORT_NAME_CONFIG, USERS_NAMES_BY_SHORT_NAME_CONFIG} from "../../config/user-roles.config";

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class UsersComponent implements OnInit {
    pageData = [];
    editPopup = {
      show: false,
      isEditing: false,
      id: null
    };
    constructor(private popupComponent: PopupComponent,
                private formBuilder: FormBuilder,
                private loaderService: LoaderService,
                private blurMenuService: BlurMenuService,
                private userRoleService: UserRoleService,
                private usersService: UsersService) {
    }

  ngOnInit() {
    this.getPageInfo();
  }

  getPageInfo() {
    this.usersService.getPageInfo().subscribe(data => {
      this.setPageInfo(data);
    }, () => {
      this.popupComponent.showPopupTryAgain();
    }, () => {
      this.loaderService.hide();
    });
  }

  setPageInfo(data) {
      try {
          if (data.status) {
            this.pageData = data.result;
          } else if (data.status === false) {
              //this.popupComponent.showPopupError({text: data.message})
          } else {
              this.popupComponent.showPopupTryAgain();
          }
      } catch (e) {
          this.popupComponent.showPopupTryAgain();
      }
  }

  onCloseEditPopup(needUpdate) {
    this.blurMenuService.focus();
    this.resetEditPopup();
    if (needUpdate) {
      this.getPageInfo();
    }
  }

  onEditItem(id) {
    this.blurMenuService.blur();
    this.editPopup.show = true;
    this.editPopup.isEditing = true;
    this.editPopup.id = id;
  }

  resetEditPopup() {
      this.editPopup = {
        show: false,
        isEditing: false,
        id: null
      }
  }

  onCreate() {
    this.blurMenuService.blur();
    this.editPopup.show = true;
    this.editPopup.isEditing = false;
    this.editPopup.id = null;
  }

  onDelete(id, name) {
      this.popupComponent.showPopupAgreement({text: `Do you really want to delete ${name}?`}, (res) => {
        if (res) {
          this.usersService.deleteItem(id).subscribe(data => {
            this.showDeleteResult(data);
          }, () => {
            this.popupComponent.showPopupTryAgain();
          }, () => {
            this.loaderService.hide();
          });
        }
      });
  }

  showDeleteResult(data) {
      try {
         if (data.status) {
           this.popupComponent.showPopupSuccess({text: data.message});
           this.getPageInfo();
         } else {
           this.popupComponent.showPopupError({text: data.message});
         }
      } catch (e) {
        this.popupComponent.showPopupTryAgain();
      }
  }

  isCashierType(type) {
      return type === USER_ROLES_SHORT_NAME_CONFIG.cashier;
  }

  getUserRole(shortName) {
    return USERS_NAMES_BY_SHORT_NAME_CONFIG[shortName];
  }
}
