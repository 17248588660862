import {Component, OnInit, ElementRef, ViewChild,} from '@angular/core';
import {slideToLeft} from "../../../animations/router.animations";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ExistingUserService} from "../../../service/sell/existingUser.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {UserInfo} from "../../../models/userInfo";
import {PAYMENT_TYPES} from "../../../config/payment_types";
import {PAYOUT_TYPES} from "../../../config/payout_types";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {formatCurrency} from "@angular/common";
import {LoaderService} from "../../../service/loader.service";
import {PhoneMaskService} from "../../../service/phoneMask.service";
import {ResetPageScrollService} from "../../../service/pageScroll/resetPageScroll";
import {PaginationDataModel, SetPaginationDataModel} from "../../../models/scrollPagination/paginationData.model";
import {PaginationScrollService} from "../../../service/pageScroll/paginationScroll.service";
import {PaginationData} from "../../../models/scrollPagination/paginationData";
import {TemplateService} from "../../../service/template.service";
import {AMOUNT_TYPE, PAYMENT_METHOD_TYPE} from "./config";
import {PartnerService} from "../../../service/partner.service";
import {RouterPaths} from "../../../config/router-paths.model";
import {BlurMenuService} from "../../../service/blurMenu.service";
import {CREDENTIALS_TYPES} from "../../../config/credentials_types";

@Component({
    selector: 'app-existing-user',
    templateUrl: './existing-user-info.component.html',
    styleUrls: ['./existing-user-info.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class ExistingUserInfoComponent implements OnInit {
    sellForm: FormGroup;
    buyForm: FormGroup;
    userInfo;
    PAYMENT_TYPES = PAYMENT_TYPES;
    PAYOUT_TYPES = PAYOUT_TYPES;
    userId;

    prevPageTopOffset = 0;
    prevChildTopOffset = 0;
    maxPageScrollPosition = 0;

    popupReverse = {
        show: false,
        amount: null
    };

    AMOUNT_TYPE = AMOUNT_TYPE;
    PAYMENT_METHOD_TYPE = PAYMENT_METHOD_TYPE;

    debitData: PaginationDataModel = new PaginationData('issued', '.js-debit-item', '.js-debit-parent', 'issued');
    creditData: PaginationDataModel = new PaginationData('redeems', '.js-credit-item', '.js-credit-parent', 'redeems');

    @ViewChild('userTel', {static: false}) userTel: ElementRef;

    constructor(private formBuilder: FormBuilder,
                private route: ActivatedRoute,
                private eRef: ElementRef,
                private loaderService: LoaderService,
                private popupComponent: PopupComponent,
                private phoneMaskService: PhoneMaskService,
                private resetPageScrollService: ResetPageScrollService,
                private router: Router,
                private blurMenuService: BlurMenuService,
                private partnerService: PartnerService,
                private paginationScrollService: PaginationScrollService,
                private templateService: TemplateService,
                private existingUserService: ExistingUserService) {}


    ngOnInit() {
        this.sellForm = this.formBuilder.group({
            amount: [null]
        });

        this.buyForm = this.formBuilder.group({
            amount: [null, Validators.required],
        });

        this.route.params.subscribe((params: Params) => {
            this.userId = params.id;

            this.getUserInfo();
        });

        document.body.addEventListener('touchstart', (e) => {
            this.resetPageScrollService.onTouch(e);
        });
    }

    onSubmit() {
        const amount = this.sellForm.controls.amount.value;
        const phone = this.userTel.nativeElement.innerHTML;

        let text = `${phone} <br><br>Membership/Sweeps: ${formatCurrency(amount, 'en','$')}`;

        this.popupComponent.showPopupAgreement({text: text}, (status) => {
            if (status) {
                this.submit();
            }
        });
    }

    onSubmitBuy() {
        const amount = this.buyForm.controls.amount.value;
        const phone = this.userTel.nativeElement.innerHTML;

        this.popupComponent.showPopupAgreement({text: `${phone} <br><br> ${formatCurrency(amount, 'en','$')}`}, (status) => {
            if (status) {
                this.submitBuy();
            }
        });
    }

    submit() {
        const userId = this.route.snapshot.params.id;
        const amount = this.sellForm.controls.amount.value ? this.sellForm.controls.amount.value : 0;

        this.existingUserService.sell(userId, amount).subscribe(data => {
            this.loaderService.hide();
            if(data.status){
                this.popupComponent.showPopupSuccess({text:data.message});
                this.sellForm.reset();

                this.getUserInfo();
            } else {
                this.popupComponent.showPopupError({text:data.message});
            }
        });
    }

    submitBuy() {
        const userId = this.route.snapshot.params.id;
        const amount = this.buyForm.controls.amount.value;

        this.existingUserService.buy(userId, amount).subscribe(data => {
            this.loaderService.hide();
            if(data.status){
                this.popupComponent.showPopupSuccess({text:data.message});
                this.buyForm.reset();

                this.getUserInfo();
            } else {
                this.popupComponent.showPopupError({text:data.message});
            }
        });
    }

    getUserInfo(){
        this.existingUserService.getUserInfo(this.userId, 1).subscribe(data => {
            try {
                if (data.status) {
                    this.paginationScrollService.resetPagination(this.creditData);
                    this.paginationScrollService.resetPagination(this.debitData);

                    this.userInfo = data;

                    const debitDataSettings: SetPaginationDataModel = {
                        itemsList: data.issued,
                        scrollLimit: data.issuedLimit,
                        total: data.issuedTotal
                    };
                    this.initScrolledData(this.debitData, debitDataSettings);


                    const creditDataSettings: SetPaginationDataModel = {
                        itemsList: data.redeems,
                        scrollLimit: data.redeemsLimit,
                        total: data.redeemsTotal
                    };
                    this.initScrolledData(this.creditData, creditDataSettings);
                }
            } catch (e) {
                this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
            }

        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });

    }

    getPhone(item){
        const activityItem = new UserInfo(item);
        return activityItem.phone;
    }

    getPayout(shortName){
        switch(shortName) {
            case CREDENTIALS_TYPES.stripeBankAccount:
            case CREDENTIALS_TYPES.stripeBankAccountCashApp:
            case CREDENTIALS_TYPES.paynote:
                return 'bank';

            case CREDENTIALS_TYPES.cashPayout:
                return 'shop';

            case CREDENTIALS_TYPES.stripeDebitcard:
                return 'card';

            case CREDENTIALS_TYPES.reloadlyAmazon:
            case CREDENTIALS_TYPES.reloadlyVisa:
                return 'giftCard';

            case CREDENTIALS_TYPES.paypalPayout:
                return 'payPal';

            case CREDENTIALS_TYPES.venmoPayout:
                return 'venmo';
            case CREDENTIALS_TYPES.checkPayout:
                return 'bank_check';

            default:
                return 'shop';
        }
        // const type = Object.keys(this.PAYOUT_TYPES).find(key => +this.PAYOUT_TYPES[key] === +id);
        // switch(type) {
        //     case 'cash':
        //         return 'shop';
        //
        //     case 'card':
        //         return 'card';
        //
        //     case 'payPal':
        //         return 'payPal';
        //
        //     case 'check':
        //         return 'bill';
        //
        //     default:
        //         return 'shop';
        // }
    }

    getPayment(shortName){
        // const type = Object.keys(this.PAYMENT_TYPES).find(key => +this.PAYMENT_TYPES[key] === +id);
        switch(shortName) {
            case CREDENTIALS_TYPES.paymentCash:
                return 'shop';

            case CREDENTIALS_TYPES.paymentCard:
                return 'card';

            case CREDENTIALS_TYPES.paymentCashApp:
                return 'card';

            default:
                return 'card';
        }
        // const type = Object.keys(this.PAYMENT_TYPES).find(key => +this.PAYMENT_TYPES[key] === +id);
        // switch(type) {
        //     case 'cash':
        //         return 'shop';
        //
        //     case 'card':
        //         return 'card';
        //
        //     case 'payPal':
        //         return 'payPal';
        //
        //     default:
        //         return 'shop';
        // }
    }

    toggleDropdown(e) {
        const hidden = e.target.offsetParent.classList.contains('is-hidden') ? true : false;
        document.body.querySelectorAll('.js-dropdown').forEach(el => {
            el.classList.add('is-hidden');
        });

        if (hidden) {
            e.target.offsetParent.classList.remove('is-hidden');

            setTimeout(() => {
                const pageScroll = (<HTMLElement>document.querySelectorAll('.page')[0]).scrollHeight - (<HTMLElement>document.querySelectorAll('.page')[0]).offsetHeight;
                const scrollTo = pageScroll <= this.maxPageScrollPosition || this.maxPageScrollPosition === 0 ? pageScroll : this.maxPageScrollPosition;

                (<HTMLElement>document.querySelectorAll('.page')[0]).scrollIntoView({behavior: 'smooth'});
                (<HTMLElement>document.querySelectorAll('.page')[0]).scroll({
                    top: scrollTo,
                    left: 0,
                    behavior: 'smooth'
                });
            }, 350)

        }
    }

    getDate(date) {
        return date.replace(/\s/g, 'T');
    }

    getPhoneMask(item){
        if(item){
            if(item.country_id){
                return this.phoneMaskService.getPhoneMaskByCountryId(item.country_id);
            }else{
                return this.phoneMaskService.getDefaultPhoneMask();
            }
        } else{
            return this.phoneMaskService.getDefaultPhoneMask();
        }
    }

    getWinningBalance() {
        if (this.userInfo) {
            return this.userInfo.availableAmount;
        }
    }

    pageScroll($event, to) {
        const direction = this.prevPageTopOffset > $event.target.scrollTop ? 'up' : 'down';
        const waitingTime = direction === 'up' ? 500 : 0;
        const pageHasScroll = $event.target.scrollHeight;

        const toOffset = 10;
        this.maxPageScrollPosition = to.offsetTop - toOffset;

        setTimeout(() => {
            if (($event.target.scrollTop > this.maxPageScrollPosition) && pageHasScroll) {

                $event.target.style.overflowY = 'hidden';
                $event.target.scrollTop = this.maxPageScrollPosition;
            }
        }, waitingTime);

        this.prevPageTopOffset = $event.target.scrollTop;
    }

    getBackLink() {
        return RouterPaths.sell.existingUser.list;
    }

    getAmount(action, type) {
        const amount = action.amount;
        const paymentMethod = action.payment_method;

        if (type === this.AMOUNT_TYPE.free && paymentMethod === this.PAYMENT_METHOD_TYPE.free) {
            return amount;
        }

        if (type === this.AMOUNT_TYPE.amount && paymentMethod !== this.PAYMENT_METHOD_TYPE.free) {
            return amount;
        }

        return 0;
    }

    onReverseRequest() {
        this.existingUserService.reverse(this.userId).subscribe(data => {
            this.handleReverseRequestResult(data);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide()
        });
    }

    handleReverseRequestResult(data) {
        try {
            if (data.status) {
                this.showReversePopup(data.amount);
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    onReverseConfirm() {
        this.existingUserService.reverseCreate(this.userId).subscribe(data => {
            this.handleReverseResult(data);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide()
        });
    }

    handleReverseResult(data) {
        try {
            if (data.status) {
                this.popupComponent.showPopupSuccess({text: data.message});
                this.closeReversePopup();
                this.getUserInfo();
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    showReversePopup(amount) {
        this.blurMenuService.blur();
        this.popupReverse.show = true;
        this.popupReverse.amount = amount;
    }

    closeReversePopup() {
        this.blurMenuService.focus();
        this.popupReverse.show = false;
        this.popupReverse.amount = null;
    }

    ///////---SCROLLING PAGINATION FUNCTIONAL---///////
    // DETECTING SCROLL
    scroll(e, settings: PaginationDataModel) {
        e.stopImmediatePropagation();
        e.stopPropagation();

        this.paginationScrollService.onScroll(e, settings, this.loadNewData.bind(this), true);
    }

    // REQUESTS ON ADDING NEW DATA
    loadNewData(page: number, step = 'end', settings: PaginationDataModel): void {
        this.existingUserService.getUserInfo(this.userId, page, settings.name).subscribe(data => {
              this.paginationScrollService.dataAddingLogic(page, step, data, settings, true)
          },
          () => { this.loaderService.hideAndShowTryAgain() },
          () => { this.loaderService.hide() }
        );
    }

    initScrolledData(paginationDataName: PaginationDataModel, settings: SetPaginationDataModel) {
        paginationDataName = this.paginationScrollService.initScrolledData(paginationDataName, settings);
    }
}
