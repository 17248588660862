<app-popup-wrapper [show]="show" (onClose)="closePopup()">
    <form [formGroup]="popupForm" (ngSubmit)="onSubmit()" class="form-simple form-simple--primary">
        <div class="form-simple__item">
            <input formControlName="name"
                   placeholder="Login"
                   [type]="'text'"
                   class="form-simple__input"
                   autocomplete="off">
            <span class="form-simple__ad-info">Name</span>
        </div>

        <div class="form-simple__item">
            <input formControlName="password"
                   placeholder="Password"
                   [type]="'password'"
                   class="form-simple__input"
                   autocomplete="off">
            <span class="form-simple__ad-info" *ngIf="!isEditing">Password</span>
            <span class="form-simple__ad-info" *ngIf="isEditing">Not required</span>
        </div>


        <div class="form-simple__item">
            <input formControlName="repeatPassword"
                   placeholder="Repeat Password"
                   [type]="'password'"
                   class="form-simple__input"
                   autocomplete="off">
            <span class="form-simple__ad-info" *ngIf="!isEditing">Repeat Password</span>
            <span class="form-simple__ad-info" *ngIf="isEditing">Not required</span>
        </div>

<!--        <div class="form-simple__item" *ngIf="typesList">-->
<!--            <mat-form-field>-->
<!--                <mat-select #typeSelect-->
<!--                            formControlName="type"-->
<!--                            placeholder="Type"-->
<!--                            [(value)]="selectedType" panelClass="my-select-panel-border-yellow">-->
<!--                    <mat-select-trigger>-->
<!--                        {{typeSelect?.value?.name}}-->
<!--                    </mat-select-trigger>-->
<!--                    <mat-option *ngFor="let method of typesList" [value]="method">-->
<!--                        {{method.name}}-->
<!--                    </mat-option>-->
<!--                </mat-select>-->
<!--            </mat-form-field>-->
<!--            <span class="form-simple__ad-info">Owner Location</span>-->
<!--        </div>-->

        <div class="form-simple__btns">
            <button class="button snake-btn is-notouched"
                    [disabled]="popupForm.invalid || (popupForm.controls.password.value !== popupForm.controls.repeatPassword.value)">
                <ng-container *ngIf="isEditing">
                    Update
                </ng-container>
                <ng-container *ngIf="!isEditing">
                    Create
                </ng-container>
                <svg><rect></rect></svg>
            </button>
        </div>
    </form>
</app-popup-wrapper>



