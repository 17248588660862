import { REPORTS_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../loader.service";
var ReportsService = /** @class */ (function () {
    function ReportsService(http, loaderService) {
        this.http = http;
        this.loaderService = loaderService;
        this.config = REPORTS_CONFIG;
    }
    ReportsService.prototype.getPageInfo = function (page, date) {
        this.loaderService.show();
        return this.http.post(this.config.getPageInfo, { page: page, date: date });
    };
    ReportsService.prototype.settle = function () {
        this.loaderService.show();
        return this.http.post(this.config.settle, {});
    };
    ReportsService.prototype.tableSettle = function (id) {
        this.loaderService.show();
        return this.http.post(this.config.tableSettle, { id: id });
    };
    ReportsService.prototype.confirmSettle = function (id) {
        this.loaderService.show();
        return this.http.post(this.config.confirmSettle, { id: id });
    };
    ReportsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ReportsService_Factory() { return new ReportsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: ReportsService, providedIn: "root" });
    return ReportsService;
}());
export { ReportsService };
