import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {LoaderService} from "./loader.service";
import {CASH_REGISTER_CONFIG} from "../config/api";

@Injectable({
    providedIn: 'root'
})
export class CashRegisterService {
    private config = CASH_REGISTER_CONFIG;

    constructor(private http: HttpClient,
                private loaderService: LoaderService) {

    }

    getPageInfo(page) {
        this.loaderService.show();
        return this.http.post<any>(this.config.getPageInfo, {page});
    }

    open(amount) {
        this.loaderService.show();
        return this.http.post<any>(this.config.open, {amount});
    }

    close() {
        this.loaderService.show();
        return this.http.post<any>(this.config.close, {});
    }
}
