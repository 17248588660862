import {Component, OnInit} from '@angular/core';
import {slideToRight} from "../../animations/router.animations";
import {SellService} from "../../service/sell/sell.service";
import {PopupComponent} from "../../shared/popup/popup.component";
import {LoaderService} from "../../service/loader.service";
import {BlurMenuService} from "../../service/blurMenu.service";
import {Router} from "@angular/router";
import {RouterPaths} from "../../config/router-paths.model";

@Component({
    selector: 'app-sell-location-nav',
    templateUrl: './sell-location-nav.component.html',
    styleUrls: ['./sell-location-nav.component.scss'],
    animations: [slideToRight],
    host: {'[@slideToRight]': ''}
})
export class SellLocationNavComponent implements OnInit {
    balance = 0;
    rate;
    earning;
    hasCashRegister;
    constructor(private sellService: SellService,
                private blurMenuService: BlurMenuService,
                private router: Router,
                private popupComponent: PopupComponent,
                private loaderService: LoaderService) {}


    ngOnInit() {
        this.blurMenuService.focus();
        this.getBalance();
    }

    getBalance() {
        this.sellService.getLimit().subscribe(data => {
            if (data.status) {
                this.balance = data.balance;
                this.rate = data.rate;
                this.earning = data.earning;
                this.hasCashRegister = !!+data.cashRegister;
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    requestRefill() {
        this.sellService.requestRefill().subscribe(data => {
            if (data.status) {
                this.popupComponent.showPopupSuccess({text: data.message});
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    isGrossNet() {
        return +this.earning === 1;
    }

    goToCashRegisterPage() {
        this.router.navigate([RouterPaths.cashRegister.main]);
    }

}
