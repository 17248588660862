import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {LoaderService} from "./loader.service";
import {USERS_CONFIG} from "../config/api";

@Injectable({
    providedIn: 'root'
})
export class UsersService {
    private config = USERS_CONFIG;

    constructor(private http: HttpClient,
                private loaderService: LoaderService) {

    }

    getPageInfo() {
        this.loaderService.show();
        return this.http.post<any>(this.config.getPageInfo, {});
    }

    deleteItem(id) {
        this.loaderService.show();
        return this.http.post<any>(this.config.deleteItem, {id});
    }

    getItem(id) {
        this.loaderService.show();
        return this.http.post<any>(this.config.getItem, {id});
    }

    editItem(id, password) {
        this.loaderService.show();
        return this.http.post<any>(this.config.editItem, {id, password});
    }

    createItem(login, password) {
        this.loaderService.show();
        return this.http.post<any>(this.config.createItem, {login, password});
    }
}
