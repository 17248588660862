<div class="page page--scrolled" [class.page--blured]="editPopup.show">
    <div class="page__inner">
        <h1 class="page__title">Users</h1>

        <div class="page__body">
            <div class="header-wrapper">
                <div class="sell-header">
                    <button class="button snake-btn is-notouched sell-header__button"
                            (click)="onCreate()">
                        Create
                        <svg><rect></rect></svg>
                    </button>
                </div>
            </div>

            <div class="history-table-wrapper">
                <table class="history-table">
                    <thead class="history-table__head">
                        <tr class="js-history-item">
                            <th>Login</th>
                            <th>Type</th>
                            <th style="text-align: center">Settings</th>
                        </tr>
                    </thead>
                    <tbody class="history-table__body">
                        <tr *ngFor="let item of pageData" class="js-stripe-item">
                            <td>{{item.user_name}}</td>
                            <td>{{getUserRole(item.role_short_name)}}</td>
                            <td style="text-align: center; display: flex; align-items: baseline; justify-content: space-between; min-width: 8rem">
                                <button type="button" class="edit-btn"
                                        (click)="onEditItem(item.user_id)">
                                    <svg>
                                        <use [attr.xlink:href]="'../../../../assets/images/sprite.svg#pencil'"></use>
                                    </svg>
                                </button>
                                <button class="history-table__button"
                                        [disabled]="!isCashierType(item.role_short_name)"
                                        (click)="onDelete(item.user_id, item.user_name)">Delete</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<app-popup-users-settings [show]="editPopup.show"
                          [id]="editPopup.id"
                          [isEditing]="editPopup.isEditing"
                          (onClose)="onCloseEditPopup($event)"></app-popup-users-settings>
