import {Component, HostListener, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {MenuService} from "./service/menu.service";
import {LoginService} from "./service/login.service";
import {NavigationEnd, NavigationStart, Router} from "@angular/router";

import {PhoneService} from "./service/phone.service";
import {MenuBottomAddContentService} from "./service/menu/menuBottomAddContent.service";
import {InstallService} from "./service/install.service";
import {AuthenticationService} from "./modules/auth/_services/authentication.service";
import {ConnectionService} from "ng-connection-service";
import {PopupComponent} from "./shared/popup/popup.component";
import {AppVersionrService} from "./service/app-version/appVersionr.service";

//declare var Stripe: Stripe;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    menu;
    signup = false;
    login = false;
    search = true;

    canListen = false;
    androidInstallBtn = false;
    IOSInstallBtn = false;
    deferredPrompt: any;
    isConnected = true;
    noInternetConnection = false;

    @HostListener('click', ['$event'])
    clickInside(event) {
        if  (this.canListen) {
            const snakeBtns = document.querySelectorAll('.snake-btn');
            snakeBtns.forEach(el => {
                el.classList.remove('is-notouched');
            });
        };

    }

    constructor(private menuService: MenuService,
                private router: Router,
                private phoneService:PhoneService,
                private installService: InstallService,
                private connectionService: ConnectionService,
                private authenticationService: AuthenticationService,
                private popupComponent: PopupComponent,
                private appVersionrService: AppVersionrService,
                private menuBottomAddContentService: MenuBottomAddContentService,
                private loginService: LoginService) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                //console.log('Navigation Start')
                this.canListen = false;
            }

            if (event instanceof NavigationEnd) {
                //console.log('Navigation End')
                const snakeBtns = document.querySelectorAll('.snake-btn');
                snakeBtns.forEach(el => {
                    (<HTMLElement>el).classList.add('is-notouched');
                })
                setTimeout(()  => {
                    this.canListen = true;
                }, 0)

            }
        });
        this.connectionService.monitor().subscribe(isConnected => {
            this.isConnected = isConnected;
            if (this.isConnected) {
                this.noInternetConnection = false;
                this.popupComponent.closePopup();
            } else {
                this.noInternetConnection = true;
                this.popupComponent.showPopupError({text: 'No Internet Connection: please, try to connect to the Internet', closeBtn: false})
            }
        });
    }

    ngOnInit() {
        this.phoneService.init();

        this.loginService.loginChanged.subscribe((value: any) => this.loginChanged(value));

        setTimeout(() => {
            this.menuService.menuChanged.subscribe((value: any) => this.menuChanged(value));
        });

        this.detectBrowser();

        this.refreshToken();

        this.appVersion();

        this.authenticationService.setStamp();
        document.addEventListener('visibilitychange', () => {
            this.authenticationService.checkStamp();
        });

        if (this.isIos()) {
            document.body.classList.add('is-ios');
        }

        window.addEventListener('resize', this.appHeight)
        this.appHeight()

    }

    ngOnDestroy() {

    }

    refreshToken(){
        let $this = this;
        setTimeout(function(){
            $this.authenticationService.refreshToken();
        },60*5*1000);
    }

    menuChanged(status){
        this.menu = status;
    }

    loginChanged(status){
        this.login = status;
    }

    addToHomeScreen() {
        // wait for click install button by user

        this.deferredPrompt.prompt();
        this.deferredPrompt.userChoice.then(choiceResult => {
            if (choiceResult.outcome === 'accepted') {
                // user accept the prompt

                // lets hidden button
                this.androidInstallBtn = false;
                //overlay_button.classList.add('is-app');

            } else {
                console.log('User dismissed the prompt');
            }
            this.deferredPrompt = null;
        });
    }

    detectBrowser() {
        this.installService.appInstalled.next(true);
        this.installService.hide();
        this.IOSInstallBtn = false;
        // if (window.matchMedia('(display-mode: standalone)').matches || !!localStorage.useBrowser) {
        //     this.installService.appInstalled.next(true);
        //     this.installService.hide();
        //     this.IOSInstallBtn = false;
        // } else {
        //     this.installService.show();
        // }

    }

    appVersion(){
        this.appVersionrService.checkVersion();
    }

    isIos(){
        let userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test( userAgent );
    }

    appHeight() {
        const doc = document.documentElement;
        doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
}


