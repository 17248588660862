<app-popup-wrapper [show]="show" (onClose)="closePopup()">
    <div class="history-table-wrapper" id="details-table">
        <div class="popup-details">
            <div class="popup-details__controls">
                <button class="popup-details__button js-tab-btn is-active"
                        [attr.data-id]="1"
                        (click)="showTab(1)">Overview</button>
                <button class="popup-details__button js-tab-btn"
                        [attr.data-id]="2"
                        (click)="showTab(2)">Digital</button>
                <button class="popup-details__button js-tab-btn"
                        [attr.data-id]="3"
                        (click)="showTab(3)">Cash</button>
                <button class="popup-details__button js-tab-btn"
                        [attr.data-id]="4"
                        (click)="showTab(4)">Providers</button>
                <button class="popup-details__button js-tab-btn"
                        [attr.data-id]="5"
                        (click)="showTab(5)">Promos</button>
                <button class="popup-details__button js-tab-btn"
                        [attr.data-id]="6"
                        (click)="showTab(6)">Checks</button>
            </div>
            <div class="popup-details__block js-tab is-active" [attr.data-id]="1">
                <h2 class="popup-details__title">Overview</h2>
                <!--<h3 class="popup-details__text">Statistical <span *ngIf="earning">GROSS </span> <span>Net</span> overview of the performance</h3>-->
                <div class="popup-details__table">
                    <div class="popup-details-table">
                        <div class="popup-details-table__row">
                            <div class="popup-details-table__col">Digital</div>
                            <div class="popup-details-table__col">
                                {{overviewDigital | currency}}
                            </div>
                        </div>
                        <div class="popup-details-table__row">
                            <div class="popup-details-table__col">Cash</div>
                            <div class="popup-details-table__col">
                                {{overviewCash | currency}}
                            </div>
                        </div>
                        <div class="popup-details-table__row">
                            <div class="popup-details-table__col">Providers</div>
                            <div class="popup-details-table__col">
                                {{getAbs(overviewProviders) | currency}}
                            </div>
                        </div>
                        <div class="popup-details-table__row">
                            <div class="popup-details-table__col">Promos</div>
                            <div class="popup-details-table__col">
                                {{getAbs(overviewPromo) | currency}}
                            </div>
                        </div>
                        <div class="popup-details-table__row">
                            <div class="popup-details-table__col">Checks</div>
                            <div class="popup-details-table__col">
                                {{getAbs(overviewChecks) | currency}}
                            </div>
                        </div>

                        <div class="popup-details-table__row popup-details-table__row--total" *ngIf="!earning">
                            <div class="popup-details-table__col">Net</div>
                            <div class="popup-details-table__col">
                                {{net | currency}}
                            </div>
                        </div>
                        <div class="popup-details-table__row popup-details-table__row--total" *ngIf="earning">
                            <div class="popup-details-table__col">Gross Net</div>
                            <div class="popup-details-table__col">
                                {{grossNet | currency}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="popup-details__block js-tab" [attr.data-id]="2">
                <h2 class="popup-details__title">Digital transactions</h2>
                <h3 class="popup-details__text">Inflows and Outflows of all digital transactions with the exception of transfers from one platform to another.</h3>
                <div class="popup-details__table">
                    <div class="popup-details-table">
                        <div class="popup-details-table__row">
                            <div class="popup-details-table__col">in</div>
                            <div class="popup-details-table__col">
                                {{digital?.in | currency}}
                            </div>
                        </div>
                        <div class="popup-details-table__row">
                            <div class="popup-details-table__col">out</div>
                            <div class="popup-details-table__col">
                                {{digital?.out | currency}}
                            </div>
                        </div>
                        <div class="popup-details-table__row">
                            <div class="popup-details-table__col">fees</div>
                            <div class="popup-details-table__col">
                                {{digital?.fee | currency}}
                                <!--<span *ngIf="commission === 1"-->
                                      <!--class="popup-details-table__addition-info">AI image generation, NFT minting, etc.</span>-->
                            </div>
                        </div>

                        <div class="popup-details-table__row popup-details-table__row--total">
                            <div class="popup-details-table__col">Total</div>
                            <div class="popup-details-table__col">
                                {{digital?.total | currency}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="popup-details__block js-tab" [attr.data-id]="3">
                <h2 class="popup-details__title">Cash Transactions</h2>
                <h3 class="popup-details__text">Inflows and Outflows of all cash transactions.</h3>
                <div class="popup-details__table">
                    <div class="popup-details-table">
                        <div class="popup-details-table__row">
                            <div class="popup-details-table__col">in</div>
                            <div class="popup-details-table__col">
                                {{cash?.in | currency}}
                            </div>
                        </div>
                        <div class="popup-details-table__row">
                            <div class="popup-details-table__col">out</div>
                            <div class="popup-details-table__col">
                                {{cash?.out | currency}}
                            </div>
                        </div>
                        <div class="popup-details-table__row">
                            <div class="popup-details-table__col">fees</div>
                            <div class="popup-details-table__col">
                                {{cash?.fee | currency}}
                            </div>
                        </div>

                        <div class="popup-details-table__row popup-details-table__row--total">
                            <div class="popup-details-table__col">Total</div>
                            <div class="popup-details-table__col">
                                {{cash?.total | currency}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="popup-details__block js-tab" [attr.data-id]="4">
                <h2 class="popup-details__title">Provider Payments</h2>
                <h3 class="popup-details__text">All the payments due to software companies including the deposit made through transfers from one provider to another.</h3>
                <div class="popup-details__table">
                    <div class="popup-details-table">

                        <div *ngFor="let game of providersGames" class="popup-details-table__row">
                            <div class="popup-details-table__col">{{game['1']?.name}}</div>
                            <div class="popup-details-table__col">
                                {{game['1']?.fee | currency}}
                            </div>
                        </div>

                        <div class="popup-details-table__row popup-details-table__row--total">
                            <div class="popup-details-table__col">Total</div>
                            <div class="popup-details-table__col">
                                {{providers?.total | currency}}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="popup-details__block js-tab" [attr.data-id]="5">
                <h2 class="popup-details__title">Customer Promo Payments</h2>
                <h3 class="popup-details__text">All the payments issued to customers for their promotions</h3>
                <div class="popup-details__table">
                    <div class="popup-details-table">
                        <div class="popup-details-table__row">
                            <div class="popup-details-table__col">in</div>
                            <div class="popup-details-table__col">
                                {{customer?.in | currency}}
                            </div>
                        </div>
                        <div class="popup-details-table__row">
                            <div class="popup-details-table__col">out</div>
                            <div class="popup-details-table__col">
                                {{customer?.out | currency}}
                            </div>
                        </div>
                        <div class="popup-details-table__row">
                            <div class="popup-details-table__col">fees</div>
                            <div class="popup-details-table__col">
                                {{customer?.fee | currency}}
                            </div>
                        </div>

                        <div class="popup-details-table__row popup-details-table__row--total">
                            <div class="popup-details-table__col">Total</div>
                            <div class="popup-details-table__col">
                                {{customer?.total | currency}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="popup-details__block js-tab" [attr.data-id]="6">
                <h2 class="popup-details__title">Checks Transactions</h2>
                <h3 class="popup-details__text">Inflows and Outflows of all checks transactions.</h3>
                <div class="popup-details__table">
                    <div class="popup-details-table">
                        <div class="popup-details-table__row">
                            <div class="popup-details-table__col">in</div>
                            <div class="popup-details-table__col">
                                {{check?.in | currency}}
                            </div>
                        </div>
                        <div class="popup-details-table__row">
                            <div class="popup-details-table__col">out</div>
                            <div class="popup-details-table__col">
                                {{check?.out | currency}}
                            </div>
                        </div>
                        <div class="popup-details-table__row">
                            <div class="popup-details-table__col">fees</div>
                            <div class="popup-details-table__col">
                                {{check?.fee | currency}}
                            </div>
                        </div>

                        <div class="popup-details-table__row popup-details-table__row--total">
                            <div class="popup-details-table__col">Total</div>
                            <div class="popup-details-table__col">
                                {{check?.total | currency}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-popup-wrapper>



