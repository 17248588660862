<app-popup-wrapper [show]="show" (onClose)="closePopup()">
    <div class="history-table-wrapper">
        <table class="history-table">
            <thead class="history-table__head">
            <tr class="js-history-item">
                <th>Date</th>
                <th>Amount</th>
                <th class="history-table__settle"></th>
            </tr>
            </thead>
            <tbody class="history-table__body">
                <tr *ngFor="let item of tableItems">
                    <td>{{item.created | date: 'MM/dd/yyyy'}}</td>
                    <td>{{item.amount | currency}}</td>
                    <td class="history-table__settle">
                        <span class="history-table__confirmed-text"
                              *ngIf="+item.status === 1">
                            CONFIRMED
                        </span>

                        <button class="history-table__button"
                                *ngIf="+item.status !== 1"
                                (click)="onSubmit(item.id)">Confirm</button>
                    </td>
                </tr>
            </tbody>
            <tfoot class="history-table__footer">
                <tr>
                    <td>Total</td>
                    <td>{{totalAmount| currency}}</td>
                </tr>
            </tfoot>
        </table>
    </div>
</app-popup-wrapper>



