import {Component, OnInit} from '@angular/core';
import {slideToLeft} from "../../animations/router.animations";
import {PopupComponent} from "../../shared/popup/popup.component";
import {LoaderService} from "../../service/loader.service";
import {PaginationScrollService} from "../../service/pageScroll/paginationScroll.service";
import {BlurMenuService} from "../../service/blurMenu.service";
import {TerminalService} from "../../service/terminal.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
    selector: 'app-terminal',
    templateUrl: './terminal.component.html',
    styleUrls: ['./terminal.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class TerminalComponent implements OnInit {
    pageData = [];
    editPopup = {
      show: false,
      id: null
    };

    terminalsListForm: FormGroup;
    terminalsList = [];
    selectedTerminal;

    constructor(private popupComponent: PopupComponent,
                private loaderService: LoaderService,
                private blurMenuService: BlurMenuService,
                private formBuilder: FormBuilder,
                private paginationScrollService: PaginationScrollService,
                private terminalService: TerminalService) {
    }

  ngOnInit() {
    this.terminalsListForm = this.formBuilder.group({
      terminal: [null, Validators.required]
    });
    //this.getPageInfo();
    this.getTerminalList();
  }

  getTerminalList() {
    this.terminalService.getTerminalsList().subscribe(data => {
      this.setTerminalList(data);
    }, () => {
      this.popupComponent.showPopupTryAgain();
    }, () => {
      this.loaderService.hide();
    });
  }

  setTerminalList(data) {
    try {
      if (data.status) {
        this.terminalsList = data.result;
        if (this.terminalsList && this.terminalsList.length) {
          this.selectedTerminal = this.terminalsList[0];
          this.terminalsListForm.controls.terminal.setValue(this.selectedTerminal);
          this.getPageInfo(this.selectedTerminal.id);
        }
      } else if (data.status === false) {
        this.popupComponent.showPopupError({text: data.message})
      } else {
        this.popupComponent.showPopupTryAgain();
      }
    } catch (e) {
      this.popupComponent.showPopupTryAgain();
    }
  }

  getPageInfo(id) {
    this.terminalService.getPageInfo(id).subscribe(data => {
      this.setPageInfo(data);
    }, () => {
      this.popupComponent.showPopupTryAgain();
    }, () => {
      this.loaderService.hide();
    });
  }

  setPageInfo(data) {
      try {
          if (data.status) {
            this.pageData = data.result;
          } else if (data.status === false) {
              //this.popupComponent.showPopupError({text: data.message})
          } else {
              this.popupComponent.showPopupTryAgain();
          }
      } catch (e) {
          this.popupComponent.showPopupTryAgain();
      }
  }

  onCloseEditPopup(needUpdate) {
    this.blurMenuService.focus();
    this.resetEditPopup();
    if (needUpdate) {
      this.getPageInfo(this.selectedTerminal.id);
    }
  }

  isDisable(item){
      if(item != 1){
        return true;
      }else{
        return false;
      }
  }

  onEditItem(id) {
    this.blurMenuService.blur();
    this.editPopup.show = true;
    this.editPopup.id = id;
  }

  resetEditPopup() {
      this.editPopup = {
        show: false,
        id: null
      }
  }

  onTerminalChanged($event) {
    this.getPageInfo(this.selectedTerminal.id);
  }
}
