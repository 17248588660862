import { PAYMENT_SETTINGS_CONFIG } from "../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./loader.service";
var PaymentSettingsService = /** @class */ (function () {
    function PaymentSettingsService(http, loaderService) {
        this.http = http;
        this.loaderService = loaderService;
        this.config = PAYMENT_SETTINGS_CONFIG;
    }
    PaymentSettingsService.prototype.getPageInfo = function () {
        this.loaderService.show();
        return this.http.post(this.config.getInfo, {});
    };
    PaymentSettingsService.prototype.saveStripe = function (private_key, webhook_key) {
        this.loaderService.show();
        return this.http.post(this.config.saveStripe, { private_key: private_key, webhook_key: webhook_key });
    };
    PaymentSettingsService.prototype.saveSquare = function (access_token, application_id, location_id) {
        this.loaderService.show();
        return this.http.post(this.config.saveSquare, { access_token: access_token, application_id: application_id, location_id: location_id });
    };
    PaymentSettingsService.prototype.savePayPal = function (clientId, clientSecret) {
        this.loaderService.show();
        return this.http.post(this.config.savePayPal, { clientId: clientId, clientSecret: clientSecret });
    };
    PaymentSettingsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PaymentSettingsService_Factory() { return new PaymentSettingsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: PaymentSettingsService, providedIn: "root" });
    return PaymentSettingsService;
}());
export { PaymentSettingsService };
