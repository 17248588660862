import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {PROJECT_MESSAGES} from "../../../config/project";
import {LoaderService} from "../../../service/loader.service";
import {TerminalService} from "../../../service/terminal.service";

@Component({
    selector: 'app-popup-terminal-settings',
    templateUrl: './popup-terminal-settings.component.html',
    styleUrls: ['./popup-terminal-settings.component.scss']
})
export class PopupTerminalSettingsComponent implements OnInit {
    @Input() show: boolean;
    @Input() id: any;
    @Output('onClose') emitClose: EventEmitter<any> = new EventEmitter();

    popupForm: FormGroup;
    status;
    keyboard;

    constructor(private formBuilder: FormBuilder,
                private popupComponent: PopupComponent,
                private loaderService: LoaderService,
                private terminalService: TerminalService) {
        this.popupForm = this.formBuilder.group({
            name: [null, Validators.required],
            location: [null, Validators.required],
            status: [null, Validators.required],
            teamViewerLogin: [null, Validators.required],
            teamViewerPassword: [null, Validators.required],
            keyboard: [null, Validators.required]
        });
    }

    ngOnInit() {}

    ngOnChanges(changes) {
        if (this.popupForm) {
            this.popupForm.reset();
        }

        if (changes.show && changes.show.currentValue && changes.id && changes.id.currentValue) {
            this.getItemInfo();
        }

    }

    getItemInfo() {
        this.terminalService.getItemInfo(this.id).subscribe(data => {
           this.setItemInfo(data);
        }, () => {
          this.loaderService.hideAndShowTryAgain();
        }, () => {
          this.loaderService.hide();
        });
    }

    setItemInfo(data) {
      try {
        if (data.status) {
          const name = data.result.name;
          const location = data.result.location;
          const status = data.result.status ? 1 : 0;
          const keyboard = data.result.keyboard ? 1 : 0;
          const teamViewerPassword = data.result.teamViewerPassword;
          const teamViewerLogin = data.result.teamViewerLogin;
          this.popupForm.controls.name.setValue(name);
          this.popupForm.controls.location.setValue(location);

          this.status = status;
          this.popupForm.controls.status.setValue(status);

          this.keyboard = keyboard;
          this.popupForm.controls.keyboard.setValue(keyboard);

          this.popupForm.controls.teamViewerPassword.setValue(teamViewerPassword);
          this.popupForm.controls.teamViewerLogin.setValue(teamViewerLogin);
        } else {
          this.popupComponent.showPopupError({text: data.mmessage});
          this.closePopup();
        }
      } catch (e) {
        this.loaderService.hideAndShowTryAgain();
      }
    }

    closePopup(needUpdate = false) {
        this.popupForm.reset();
        this.emitClose.emit(needUpdate);
    }

    onSubmit() {
        const name = this.popupForm.controls.name.value;
        const location = this.popupForm.controls.location.value;
        const status = this.status;
        const keyboard = this.keyboard;
        const teamViewerLogin = this.popupForm.controls.teamViewerLogin.value;
        const teamViewerPassword = this.popupForm.controls.teamViewerPassword.value;
        if (name && teamViewerLogin && teamViewerPassword) {
            this.terminalService.setItemInfo(this.id, name, location, status, teamViewerLogin, teamViewerPassword, keyboard).subscribe((data) => {
                this.handleSubmitResult(data);
            }, () => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else {
            this.popupComponent.showPopupError({text: PROJECT_MESSAGES.notAllFieldsAreFilled});
        }
    }

    handleSubmitResult(data) {
        try {
            if (data.status) {
                this.popupComponent.showPopupSuccess({text: data.message});
                this.closePopup(true);
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    onStatusChange() {
      this.status = this.status ? 0 : 1;
      this.popupForm.controls.status.setValue(this.status);
    }

  onKeyboardChange() {
    this.keyboard = this.keyboard ? 0 : 1;
    this.popupForm.controls.keyboard.setValue(this.keyboard);
  }
}
