import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {LoaderService} from "../../../service/loader.service";
import {SellService} from "../../../service/sell/sell.service";
import {POPUP_SELL_ITEM_TYPE} from "../sell-popup-type.config";
import {UserRoleService} from "../../../service/userRole.service";
import {CREDENTIALS_TYPES} from "../../../config/credentials_types";

@Component({
    selector: 'app-popup-sell-item',
    templateUrl: './popup-sell-item.component.html',
    styleUrls: ['./popup-sell-item.component.scss']
})
export class PopupSellItemComponent implements OnInit {
    @Input() show: boolean;
    @Input() info: any;
    @Output('onClose') emitClose: EventEmitter<boolean> = new EventEmitter();

    popupForm: FormGroup;
    cashMessageForm: FormGroup;

    isFirstStep = true;

    maxRate = 0;
    moreThenAvailableRate = false;

    CREDENTIALS_TYPES = CREDENTIALS_TYPES;

    games: Array<{id: number, name: string, status: number, percentage?: number}> = [];
    redeems = [];
    payments = [];

    cashMessage = null;
    cashTickets = 0;
    cashPayoutLimit = 1;
    referralBonus = 1;
    cashRegister = 0;
    nftBonusWheel = 0;
    distributorCommission = 0;
    support = 1;
    supportShow = false;
    showCashMessagePopup = false;

    sellCheckboxesPopup = {
        show: false,
        info: {
            items: [],
            title: "",
            selected: "",
            id: null
        }
    };

    updateIsClicked = false;
    timeZone;
    selectedTimeZone;
    showCommission = 0;
    commissionRadioValues = [
        {
            value: 0,
            name: 'Digital Deposits: Operator pays 7.5% CC Fees and charges 7.5% CC Fees. Cash Deposits: Operator pays Product Fees and charges Product Fees. Digital Redeems: Operator pays 7.5% Fees and charges 7.5% Fees. Cash Redeems: No Fees.'
        },
        {
            value: 1,
            name: 'Digital Deposits: Operator pays Product Fees and charges 7.5% CC Fees. Cash Deposits: Operator pays Product Fees and charges Product Fees. Digital Redeems: Operator pays 1% Fee and charges 7.5% Fees. Cash Redeems: No Fees.'
        },
        {
            value: 2,
            name: 'Digital Deposits: Operator pays Product Fees and charges either 7.5% CC Fees or Product Fees. Cash Deposits: Operator pays Product Fees and charges Product Fees. Digital Redeems: Operator pays 1% Fee and charges either 7.5% Fees or 1%. Cash Redeems: No Fees.'
        },
        {
            value: 3,
            name: 'None: Product is chosen by either operator or location. No Digital transactions allowed.'
        }
    ];
    commissionRadioValue = 0;
    constructor(private formBuilder: FormBuilder,
                private loaderService: LoaderService,
                private popupComponent: PopupComponent,
                private userRoleService: UserRoleService,
                private cdRef : ChangeDetectorRef,
                private sellService: SellService) {

    }

    ngOnInit() {
        this.popupForm = this.formBuilder.group({
            name: [null, Validators.required],
            login: [null, Validators.required],
            email: [null, Validators.required],
            rate: [null, Validators.required],
            password: [null],
            repeatPassword: [null],
            promoCode: [null],
            games: [null],
            payments: [null],
            redeems: [null],
            timeZone: [null, Validators.required],
            referralBonus: [null],
            cashRegister: [null],
            nftBonusWheel: [null],
            distributorCommission: [null],
            support: [null]
        });
        this.cashMessageForm = this.formBuilder.group({
            message: [null, Validators.required],
            tickets: [0, Validators.required],
            cashPayoutLimit: [1, Validators.required]
        });
    }

    ngAfterViewChecked() {
        this.cdRef.detectChanges();
    }

    ngOnChanges(changes) {
        if (this.popupForm) {
            this.popupForm.reset();
            this.cashMessageForm.reset();
            this.isFirstStep = true;
            this.popupForm.controls.login.enable();
        }

        if (changes.show && changes.show.currentValue) {
            this.updateIsClicked = false;
            if (this.isEditing()) {
                this.sellService.getItemInfo(this.info.id).subscribe(data => {
                    this.setFormValues(data, true);
                }, () => {
                    this.loaderService.hideAndShowTryAgain();
                }, () => {
                    this.loaderService.hide()
                });
            } else {
                this.sellService.getItemInfo().subscribe(data => {
                    this.setFormValues(data);
                }, () => {
                    this.loaderService.hideAndShowTryAgain();
                }, () => {
                    this.loaderService.hide()
                });
            }

        }
    }

    setFormValues(data, isEditing = false) {
        try {
            if (data.status) {
                this.maxRate = +data.maxRate;

                this.games = data.games;
                this.payments = data.payments;
                this.redeems = data.redeems;
                this.timeZone = data.timeZoneList;
                const timeZoneId = data.timeZoneId;
                this.cashTickets = data.cashTickets ? 1 : 0;
                this.showCommission = +data.commissionShow;
                this.supportShow = !!+data.supportShow;
                this.commissionRadioValue = 0;

                if (this.timeZone && timeZoneId) {
                    this.timeZone.forEach(el => {
                        if (el.id === timeZoneId) {
                            this.selectedTimeZone = el;
                            this.popupForm.controls.timeZone.setValue(this.selectedTimeZone);
                        }
                    })
                }

                if (isEditing) {
                    const name = data.name;
                    const login = data.login;
                    const email = data.email;
                    const rate = data.rate;
                    this.cashMessage = data.cashMessage;
                    this.cashPayoutLimit = data.cashPayoutLimit ? 1 : 0;
                    this.referralBonus = data.referralBonus ? 1 : 0;
                    this.cashRegister = +data.cashRegister ? 1 : 0;
                    this.nftBonusWheel = +data.nftBonusWheel ? 1 : 0;
                    this.distributorCommission = +data.commission === 0 ? 1 : 0;
                    this.support = data.support && +data.support ? 1 : 0;
                    this.commissionRadioValue = +data.commission;

                    this.popupForm.controls.name.setValue(name);
                    this.popupForm.controls.login.setValue(login);
                    this.popupForm.controls.email.setValue(email);
                    this.popupForm.controls.rate.setValue(rate);

                    this.popupForm.controls.login.disable();

                    if (this.isUserRoleOperator() && data.promoCode) {
                        this.popupForm.controls.promoCode.setValue(data.promoCode);
                    }

                    if (this.cashMessage) {
                        this.cashMessageForm.controls.message.setValue(this.cashMessage);
                    }

                    this.cashMessageForm.controls.tickets.setValue(this.cashTickets);
                } else {
                    this.cashPayoutLimit = 1;
                    this.referralBonus = this.isUserRoleOperator() ? 1 : null;
                    this.cashRegister = this.isUserRoleOperator() ? 0 : null;
                    this.nftBonusWheel = this.isUserRoleOperator() ? 0 : null;
                    this.distributorCommission = this.isUserRoleOperator() ? 1 : null;
                    this.support = 1;
                }
                this.cashMessageForm.controls.cashPayoutLimit.setValue(this.cashPayoutLimit);
                this.popupForm.controls.referralBonus.setValue(this.referralBonus);
                this.popupForm.controls.cashRegister.setValue(this.cashRegister);
                this.popupForm.controls.nftBonusWheel.setValue(this.nftBonusWheel);
                this.popupForm.controls.distributorCommission.setValue(this.distributorCommission);
                this.popupForm.controls.support.setValue(this.support);
                setTimeout(() => {
                  document.querySelectorAll('.js-percent').forEach(el => {
                    (el as HTMLInputElement).value = el.getAttribute('initial-value') ? (+el.getAttribute('initial-value')).toFixed(2) : '00.00';
                    el.dispatchEvent(new Event('input', { 'bubbles': true }));
                  })
                })
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    onSubmit() {
        if (!this.isAllSettingsSelected()) {
            this.updateIsClicked = true;
            this.popupComponent.showPopupError({text: 'Add settings'});
            return;
        }

        const name = this.popupForm.controls.name.value;
        const login = this.popupForm.controls.login.value.replace(/\s/g, '');
        const password = this.popupForm.controls.password.value;
        const email = this.popupForm.controls.email.value;
        const rate = this.popupForm.controls.rate.value;
        const id = this.info.id;
        const promoCode = this.isUserRoleOperator() && this.popupForm.controls.promoCode.value ? this.popupForm.controls.promoCode.value : null;
        const timeZoneId = this.popupForm.controls.timeZone.value ? this.popupForm.controls.timeZone.value.id : null;

        const games = this.games.filter(game => +game.status).map(a => {
            return {
                id: a.id,
                percentage: a.percentage ? a.percentage : 0
            }
        });

        const payments = this.payments.filter(payment => +payment.status).map(a => a.id);
        const redeems = this.redeems.filter(redeem => +redeem.status).map(a => a.id);
        const cashMessage = !this.cashMessage ? null : this.cashMessage.trim() ? this.cashMessage : null;
        const cashTickets = this.cashTickets;
        const cashPayoutLimit = this.cashPayoutLimit;
        const referralBonus = this.referralBonus;
        const cashRegister = this.cashRegister;
        const nftBonusWheel = this.nftBonusWheel;
        const support = this.supportShow ? this.support : 0;
        let commission;
        if (this.isUserRoleOperator()) {
            commission = this.distributorCommission ? 0 : 1;
        } else if (this.isUserRoleDistributor()) {
            commission = this.commissionRadioValue;
        }

        const credentials = this.getSelectedCredentials();

        if (this.isEditing()) {
            this.sellService.onEdit(id, name, login, password, rate, email, games, payments, credentials, redeems, cashMessage, cashTickets, timeZoneId, cashPayoutLimit, referralBonus, cashRegister, nftBonusWheel, commission, support, promoCode).subscribe(data => {
                if(data.status){
                    this.popupComponent.showPopupSuccess({text:data.message});
                    this.closePopup(true);
                    this.updateIsClicked = false;
                } else {
                    this.popupComponent.showPopupError({text:data.message});
                }
            }, () => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else {
            this.sellService.onCreate(name, login, password, rate, email, games, payments, credentials, redeems, cashMessage, cashTickets, timeZoneId, cashPayoutLimit, referralBonus, cashRegister, nftBonusWheel, commission, support, promoCode).subscribe(data => {
                if(data.status){
                    this.popupComponent.showPopupSuccess({text:data.message});
                    this.closePopup(true);
                    this.updateIsClicked = false;
                } else {
                    this.popupComponent.showPopupError({text:data.message});
                }
            }, () => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        }

    }

    closePopup(needUpdate = false) {
        this.emitClose.emit(needUpdate);
        this.popupForm.reset();
        this.cashMessageForm.reset();
        this.cashMessage = null;
    }

    isEditing() {
        if (this.info && this.info.type) {
            return this.info.type === POPUP_SELL_ITEM_TYPE.edit;
        }
        return false
    }

    changeMethod(e, block, item) {
        switch (block) {
            case 'games':
                this.games.find(game => game.id === item.id).status = e.target.checked ? 1 : 0;
                break;
            case 'gamesPercent':
                if (!e.target.value) {
                    (e.target as HTMLInputElement).value = '00.00';
                    e.target.dispatchEvent(new Event('input', { 'bubbles': true }));
                }
                this.games.find(game => game.id === item.id).percentage = e.target.value ? +e.target.value.slice(0, -1) : 0.00;
                break;
            case 'payments':
                this.payments.find(payment => payment.id === item.id).status = e.target.checked ? 1 : 0;
                break;
            case 'redeems':
                this.redeems.find(redeem => redeem.id === item.id).status = e.target.checked ? 1 : 0;
                break;
        }
    }

    onInputRate() {
        const activeForm = this.popupForm;
        const avaliable = typeof this.maxRate === 'number' ? this.maxRate : Number.parseFloat(this.maxRate);
        const entered = Number.parseFloat(activeForm.controls.rate.value);

        if (avaliable < entered) {
            activeForm.controls.rate.setValue(String(avaliable));
            this.moreThenAvailableRate = true;
        } else {
            this.moreThenAvailableRate = false;
        }
    }

    showSecondStep() {
        this.isFirstStep = false;
    }

    isUserRoleOperator(): boolean {
        return this.userRoleService.isUserRoleOperator();
    }

    isUserRoleDistributor(): boolean {
        return this.userRoleService.isUserRoleDistributor();
    }

    onLoginInput($event) {
        const newValue = ($event.target as HTMLInputElement).value.replace(/\s/g, '');
        this.popupForm.controls.login.setValue(newValue);
    }

    openMessageCashPopup() {
        this.showCashMessagePopup = true;
        this.cashMessageForm.controls.message.setValue(this.cashMessage);
    }

    closeCashMessagePopup() {
        this.showCashMessagePopup = false;
    }

    setNewMessage(message) {
        this.cashMessage = message;
        this.closeCashMessagePopup();
    }

    isAllSettingsSelected() {
        let result = true;
        this.redeems.forEach(item => {
            if (item.credential && !item.credentialId && !!+item.status) {
                result = false;
            }
        });
        this.payments.forEach(item => {
            if (item.credential && !item.credentialId && !!+item.status) {
                result = false;
            }
        });
        return result;
    }

    getSelectedCredentials() {
        let result = [];
        this.redeems.forEach(item => {
            if (item.credential && item.credentialId && !!+item.status) {
                result.push({
                    id: item.id,
                    credentialId: item.credentialId
                });
            }
        });
        this.payments.forEach(item => {
            if (item.credential && item.credentialId && !!+item.status) {
                result.push({
                    id: item.id,
                    credentialId: item.credentialId
                });
            }
        });
        return result;
    }

    getCredentialsList(shortName) {
        this.sellService.getCredentialsList(shortName).subscribe(data => {
            this.showCredentialsList(data, shortName);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    showCredentialsList(data, shortName) {
        try {
            if (data.status) {
                let selectedRedeem = this.redeems.find(el => el.shortName === shortName);
                if (!selectedRedeem) {
                    selectedRedeem = this.payments.find(el => el.shortName === shortName);
                }

                this.sellCheckboxesPopup.show = true;
                this.sellCheckboxesPopup.info.items = data.result;
                this.sellCheckboxesPopup.info.title = selectedRedeem.name;
                this.sellCheckboxesPopup.info.id = selectedRedeem.id;
                this.sellCheckboxesPopup.info.selected = selectedRedeem.credentialId;

                this.sellCheckboxesPopup.info.items.forEach(el => {
                    if (+el.id === +this.sellCheckboxesPopup.info.selected) {
                        el.status = 1;
                    } else {
                        el.status = 0;
                    }
                });
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    closeSellCheckboxPopup(id) {
        this.sellCheckboxesPopup.show = false;

        if (id) {
            let selectedRedeem = this.redeems.find(el => el.id === this.sellCheckboxesPopup.info.id);
            if (!selectedRedeem) {
                selectedRedeem = this.payments.find(el => el.id === this.sellCheckboxesPopup.info.id);
            }
            selectedRedeem.credentialId = id;
        }

        this.sellCheckboxesPopup.info.items = [];
        this.sellCheckboxesPopup.info.title = "";
        this.sellCheckboxesPopup.info.selected = "";
        this.sellCheckboxesPopup.info.id = null;
    }

    onCashTicketsChange() {
        this.cashTickets = this.cashTickets ? 0 : 1;
    }

    onCashPayoutLimitChange() {
        this.cashPayoutLimit = this.cashPayoutLimit ? 0 : 1;
    }

    onReferralBonusChange() {
        this.referralBonus = this.referralBonus ? 0 : 1;
    }

    onCashRegisterChange() {
        this.cashRegister = this.cashRegister ? 0 : 1;
    }

    onNFTBonusWheelChange() {
        this.nftBonusWheel = this.nftBonusWheel ? 0 : 1;
    }

    onDistributorCommissionChange() {
        this.distributorCommission = this.distributorCommission ? 0 : 1;
    }

    onSupportChange() {
        this.support = this.support ? 0 : 1;
    }

    onCommissionRadioChange($event) {
        this.commissionRadioValue = +$event.target.value;
    }

    onPercentInput($event) {
        console.log($event.target.value);
        const maxAfterDotLength = 2;
        const value = $event.target.value;
        const valueNoSuffix = value ? $event.target.value.slice(0, -1) : '';
        const valueNoSuffixBeforeDot = valueNoSuffix.split('.')[0];
        const valueNoSuffixAfterDot = valueNoSuffix.split('.')[1];
        const valueNoSuffixAfterDotCorrect = valueNoSuffixAfterDot && valueNoSuffixAfterDot.length > maxAfterDotLength ? valueNoSuffixAfterDot.slice(0, maxAfterDotLength) : valueNoSuffixAfterDot;
        if (valueNoSuffixAfterDot && valueNoSuffixAfterDot.length > maxAfterDotLength) {
            $event.target.value = `${valueNoSuffixBeforeDot}.${valueNoSuffixAfterDotCorrect}%`
        }
    }

    setGamePercentValue(percent) {
        if (percent) {
            return percent+'%';
        }

        return 0+'%';
    }
}
