import { USERS_CONFIG } from "../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./loader.service";
var UsersService = /** @class */ (function () {
    function UsersService(http, loaderService) {
        this.http = http;
        this.loaderService = loaderService;
        this.config = USERS_CONFIG;
    }
    UsersService.prototype.getPageInfo = function () {
        this.loaderService.show();
        return this.http.post(this.config.getPageInfo, {});
    };
    UsersService.prototype.deleteItem = function (id) {
        this.loaderService.show();
        return this.http.post(this.config.deleteItem, { id: id });
    };
    UsersService.prototype.getItem = function (id) {
        this.loaderService.show();
        return this.http.post(this.config.getItem, { id: id });
    };
    UsersService.prototype.editItem = function (id, password) {
        this.loaderService.show();
        return this.http.post(this.config.editItem, { id: id, password: password });
    };
    UsersService.prototype.createItem = function (login, password) {
        this.loaderService.show();
        return this.http.post(this.config.createItem, { login: login, password: password });
    };
    UsersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UsersService_Factory() { return new UsersService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: UsersService, providedIn: "root" });
    return UsersService;
}());
export { UsersService };
