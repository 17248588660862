export const PROJECT_CONFIG = {
    defaultAvatar: '../../../../assets/images/default_user.png',
    defaultWomanAvatar: '../../../../assets/images/woman-avatar.jpg',
    defaultImage: '../../../../assets/images/default_image.png',
    transactionsTypeDebit: '1',
    transactionsTypeCredit: '2',
    postPaymentTypeFree: 1,
    postPaymentTypePay: 0
};

export const PROJECT_MESSAGES = {
    unknownError: 'Sorry there was an error try again later.',
    notAllFieldsAreFilled: 'Please, fill all required fields.',
    incorrectDataFormat: 'Incorrect data format.'
}

export const PARTNERS_DOMAINS = {
    riverPay: 'studionovi'
}

export const PARTNERS_INFO = {
    riverPay: {
        name: 'senet',
        logo: '../../../../assets/images/activate_yourself.png'
    },
    default: {
        name: 'senet',
        logo: '../../../../assets/images/activate_yourself.png'
    }
}

export const PROVIDERS_GAME_STATUS = {
    notAvailable: 2
}
