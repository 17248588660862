import {Component, OnInit} from '@angular/core';
import {slideToRight} from "../../animations/router.animations";
import {SellService} from "../../service/sell/sell.service";
import {LoaderService} from "../../service/loader.service";
import {PopupComponent} from "../../shared/popup/popup.component";
import {POPUP_SELL_ITEM_TYPE} from "./sell-popup-type.config";
import {BlurMenuService} from "../../service/blurMenu.service";
import {UserRoleService} from "../../service/userRole.service";
import {PaginationData} from "../../models/scrollPagination/paginationData";
import {PaginationDataModel, SetPaginationDataModel} from "../../models/scrollPagination/paginationData.model";
import {PaginationScrollService} from "../../service/pageScroll/paginationScroll.service";
import {Router} from "@angular/router";
import {RouterPaths} from "../../config/router-paths.model";

@Component({
    selector: 'app-sell',
    templateUrl: './sell.component.html',
    styleUrls: ['./sell.component.scss'],
    animations: [slideToRight],
    host: {'[@slideToRight]': ''}
})
export class SellComponent implements OnInit {
    POPUP_SELL_ITEM_TYPE = POPUP_SELL_ITEM_TYPE;

    historyList = [];
    balance = null;

    popupSellItem = {
        show: false,
        info: {
            type: '',
            id: ''
        }
    };

    popupDeposit = {
        show: false,
        info: {
            id: '',
            availableBalance: null
        }
    };

    popupSettings = {
        show: false
    };

    historyData: PaginationDataModel = new PaginationData('history', '.js-history-item', '.page__body');
    constructor(private sellService: SellService,
                private blurMenuService: BlurMenuService,
                private userRoleService: UserRoleService,
                private paginationScrollService: PaginationScrollService,
                private router: Router,
                private popupComponent: PopupComponent,
                private loaderService: LoaderService) {}

    ngOnInit() {
        this.blurMenuService.focus();
        this.getPageInfo();
    }

    getPageInfo() {
        this.sellService.getPageInfo(1).subscribe(data => {
            this.setPageInfo(data);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    setPageInfo(data) {
        try {
            if (data.status) {
                this.paginationScrollService.resetPagination(this.historyData);
                // this.historyList = data.result;
                this.balance = data.balance;

                const historyDataSettings: SetPaginationDataModel = {
                    itemsList: data.result,
                    scrollLimit: data.limit,
                    total: data.total
                };
                this.initScrolledData(this.historyData, historyDataSettings);
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    showSellItemPopup(type, id) {
        this.blurMenuService.blur();
        this.popupSellItem.show = true;
        this.popupSellItem.info.type = type;
        this.popupSellItem.info.id = id;
    }

    showDeposit(id) {
        this.blurMenuService.blur();
        this.popupDeposit.show = true;
        this.popupDeposit.info.id = id;

        if (this.isUserRoleAdmin()) {
            this.popupDeposit.info.availableBalance = null;
        } else {
            this.popupDeposit.info.availableBalance = this.balance;
        }
    }

    showSettingsPopup() {
        this.blurMenuService.blur();
        this.popupSettings.show = true;
    }

    closePopup(needUpdate = false) {
        this.blurMenuService.focus();
        this.closePopupDeposit();
        this.closePopupSellItem();
        this.closePopupSettings();
        if (needUpdate) {
            this.getPageInfo();
        }
    }

    closePopupSellItem() {
        this.popupSellItem = {
            show: false,
            info: {
                type: '',
                id: ''
            }
        };
    }

    closePopupDeposit() {
        this.popupDeposit = {
            show: false,
            info: {
                id: '',
                availableBalance: null
            }
        };
    }

    closePopupSettings() {
        this.popupSettings = {
            show: false
        };
    }

    isUserRoleAdmin() {
        return this.userRoleService.isUserRoleAdmin();
    }

    isUserRoleOperator() {
        return this.userRoleService.isUserRoleOperator();
    }

    requestRefill() {
        this.sellService.requestRefill().subscribe(data => {
            if (data.status) {
                this.popupComponent.showPopupSuccess({text: data.message});
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    ///////---SCROLLING PAGINATION FUNCTIONAL---///////
    // DETECTING SCROLL
    scroll(e, settings: PaginationDataModel) {
        e.stopImmediatePropagation();
        e.stopPropagation();

        this.paginationScrollService.onScroll(e, settings, this.loadNewData.bind(this), true);
    }

    // REQUESTS ON ADDING NEW DATA
    loadNewData(page: number, step = 'end', settings: PaginationDataModel): void {
        if (settings.name ===  'history') {
            this.sellService.getPageInfo(page).subscribe(data => {
                  this.paginationScrollService.dataAddingLogic(page, step, data, settings, true)
              },
              () => { this.loaderService.hideAndShowTryAgain() },
              () => { this.loaderService.hide() }
            );
        }
    }

    initScrolledData(paginationDataName: PaginationDataModel, settings: SetPaginationDataModel) {
        paginationDataName = this.paginationScrollService.initScrolledData(paginationDataName, settings);
    }

    goToSettings() {
        this.router.navigate([RouterPaths.settings.main]);
    }
}
