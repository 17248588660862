import { EXISTING_USER_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../loader.service";
var ExistingUserService = /** @class */ (function () {
    function ExistingUserService(http, loaderService) {
        this.http = http;
        this.loaderService = loaderService;
        this.config = EXISTING_USER_CONFIG;
    }
    ExistingUserService.prototype.getUsers = function (page, phone) {
        return this.http.post(this.config.getUsers, { page: page, phone: phone });
    };
    ExistingUserService.prototype.getUserInfo = function (id, page, list) {
        if (list === void 0) { list = null; }
        this.loaderService.show();
        return this.http.post(this.config.userInfo, { id: id, page: page, list: list });
    };
    ExistingUserService.prototype.sell = function (id, amount) {
        this.loaderService.show();
        return this.http.post(this.config.sell, { id: id, amount: amount });
    };
    ExistingUserService.prototype.buy = function (id, amount) {
        this.loaderService.show();
        return this.http.post(this.config.buy, { id: id, amount: amount });
    };
    ExistingUserService.prototype.reverse = function (id) {
        this.loaderService.show();
        return this.http.post(this.config.reverse, { id: id });
    };
    ExistingUserService.prototype.reverseCreate = function (id) {
        this.loaderService.show();
        return this.http.post(this.config.reverseCreate, { id: id });
    };
    ExistingUserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ExistingUserService_Factory() { return new ExistingUserService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: ExistingUserService, providedIn: "root" });
    return ExistingUserService;
}());
export { ExistingUserService };
