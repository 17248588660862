<app-popup-wrapper [show]="show" (onClose)="closePopup()">
    <div class="page__form">
        <form [formGroup]="popupForm" (ngSubmit)="onSubmit()" class="form-simple form-simple--primary">
            <div class="form-simple__item form-simple__item--amount">
                <input mask="separator.2"
                       [allowNegativeNumbers]="true"
                       thousandSeparator=","
                       (input)="onInputAmount()"
                       formControlName="amount"
                       placeholder="Amount"
                       [type]="'tel'"
                       class="form-simple__input"
                       autocomplete="off">
                <div class="page__form-note" [class.is-active]="moreThenAvailablePayout">
                    Available {{info.availableBalance | currency : 'USD' : 'symbol'}}
                </div>
            </div>

            <div class="form-simple__btns">
                <button class="button snake-btn is-notouched"
                        [disabled]="popupForm.invalid || moreThenAvailablePayout || !+popupForm.controls.amount.value">
                    Send
                    <svg><rect></rect></svg>
                </button>
            </div>
        </form>
    </div>
</app-popup-wrapper>



