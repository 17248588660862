export const CREDENTIALS_TYPES = {
  stripeCard: 'stripe_card',
  squareCashApp: 'square_cash_app',
  cash: 'cash',

  stripeBankAccount: 'stripe_bank_account',
  reloadlyVisa: 'reloadly_visa',
  reloadlyAmazon: 'reloadly_amazon',
  paypalPayout: 'paypal_payout',
  venmoPayout: 'venmo_payout',
  stripeDebitcard: 'stripe_debitcard',
  paynote: 'paynote',
  cashPayout: 'cash_payout',
  checkPayout: 'check_payout',
  stripeBankAccountCashApp: 'stripe_bank_account_cash_app',

    paymentCash: 1,
    paymentCard: 2,
    paymentCashApp: 3,
}
