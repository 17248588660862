<app-popup-wrapper [show]="show" (onClose)="closePopup()">
    <div class="page__form">
        <form [formGroup]="popupForm" (ngSubmit)="onSubmit()" class="form-simple form-simple--primary">
            <div class="checkbox-list">
                <div class="checkbox-list__heading">{{info.title}}</div>
<!--                <div class="checkbox-list__subheading">Cash payments are accepted by default.</div>-->
                <div class="checkbox-list__list">
                    <div *ngFor="let item of info.items" class="form-simple__item checkbox-list__item">
                        <input formControlName="items"
                               [id]="'item_' + item.id"
                               [type]="'radio'"
                               class="form-simple__radio"
                               [value]="item.id"
                               name="item"
                               [checked]="+item.status"
                               autocomplete="off">
                        <label [for]="'item_' + item.id">{{item.name}}</label>
                    </div>
                </div>
            </div>

            <div class="form-simple__btns">
                <button class="button snake-btn is-notouched"
                        style="margin-left: 0.5rem"
                        [disabled]="popupForm.invalid">
                    Update
                    <svg><rect></rect></svg>
                </button>
            </div>
        </form>
    </div>
</app-popup-wrapper>



