export const RouterPaths = {
  home: {
    admin: '/home/sell',
    usersList: '/home/sell/existing-user',
  },
  reports: {
    main: '/home/reports',
  },
  settings: {
    main: '/home/settings',
  },
  terminal: {
    main: '/home/terminal',
  },
  users: {
    main: '/home/users',
  },
  sell: {
    main: '/home/sell',
    locationNav: '/home/sell/sell-location-nav',
    newUser: {
      create: '/home/sell/new-user',
      selectGame: '/home/sell/games-providers/new-user'
    },
    existingUser: {
      list: '/home/sell/existing-user',
      info: '/home/sell/existing-user/existing-user-info',
      selectGame: '/home/sell/existing-user/existing-user-games',
    },
  },
  termsAndConditions: {
    main: '/home/terms-and-conditions'
  },
  tickets: {
    main: '/home/tickets',
  },
  cashRegister: {
    main: '/home/cash-register',
  }
};
