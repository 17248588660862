import {Component, OnInit} from '@angular/core';
import {slideToLeft} from "../../animations/router.animations";
import {PopupComponent} from "../../shared/popup/popup.component";
import {LoaderService} from "../../service/loader.service";
import {BlurMenuService} from "../../service/blurMenu.service";
import {UserRoleService} from "../../service/userRole.service";
import {UsersService} from "../../service/users.service";
import {FormBuilder} from "@angular/forms";
import {PaginationDataModel, SetPaginationDataModel} from "../../models/scrollPagination/paginationData.model";
import {PaginationData} from "../../models/scrollPagination/paginationData";
import {PaginationScrollService} from "../../service/pageScroll/paginationScroll.service";
import {CashRegisterService} from "../../service/cash-register.service";

@Component({
    selector: 'app-cash-register',
    templateUrl: './cash-register.component.html',
    styleUrls: ['./cash-register.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class CashRegisterComponent implements OnInit {
    popupDeposit = {
      show: false
    };
    pageData: PaginationDataModel = new PaginationData('history', '.js-history-item', '.page__body');

  constructor(private popupComponent: PopupComponent,
                private formBuilder: FormBuilder,
                private loaderService: LoaderService,
                private cashRegisterService: CashRegisterService,
                private paginationScrollService: PaginationScrollService,
                private blurMenuService: BlurMenuService,
                private userRoleService: UserRoleService,
                private usersService: UsersService) {
    }

  ngOnInit() {
    this.getPageInfo();
  }

  getPageInfo() {
    this.cashRegisterService.getPageInfo(1).subscribe(data => {
      this.setPageInfo(data);
    }, () => {
      this.popupComponent.showPopupTryAgain();
    }, () => {
      this.loaderService.hide();
    });
  }

  setPageInfo(data) {
      try {
          if (data.status) {
            this.paginationScrollService.resetPagination(this.pageData);

            const pageDataSettings: SetPaginationDataModel = {
              itemsList: data.result,
              scrollLimit: data.limit,
              total: data.total
            };
            this.initScrolledData(this.pageData, pageDataSettings);
          } else if (data.status === false) {
              //this.popupComponent.showPopupError({text: data.message})
          } else {
              this.popupComponent.showPopupTryAgain();
          }
      } catch (e) {
          this.popupComponent.showPopupTryAgain();
      }
  }

  onDelete(id, name) {
      this.popupComponent.showPopupAgreement({text: `Do you really want to delete ${name}?`}, (res) => {
        if (res) {
          this.usersService.deleteItem(id).subscribe(data => {
            this.showDeleteResult(data);
          }, () => {
            this.popupComponent.showPopupTryAgain();
          }, () => {
            this.loaderService.hide();
          });
        }
      });
  }

  showDeleteResult(data) {
      try {
         if (data.status) {
           this.popupComponent.showPopupSuccess({text: data.message});
           this.getPageInfo();
         } else {
           this.popupComponent.showPopupError({text: data.message});
         }
      } catch (e) {
        this.popupComponent.showPopupTryAgain();
      }
  }

  onOpen() {
    this.blurMenuService.blur();
    this.popupDeposit.show = true;
  }

  onCloseOpen() {
    this.blurMenuService.focus();
    this.popupDeposit.show = false;
  }

  onSuccessOpen($event) {
    this.onCloseOpen();
    this.getPageInfo()
  }

  onClose() {
    const text = `Please confirm closing`;

    this.popupComponent.showPopupAgreement({text: text,
      confirmButtonText: 'CONFIRM<svg><rect></rect></svg>',
      cancelButtonText: '<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M25.5 24.1719L18.3281 17L25.5 9.82812C25.8668 9.46136 25.8668 8.86676 25.5 8.5C25.1332 8.13324 24.5386 8.13324 24.1719 8.5L17 15.6719L9.82813 8.5C9.46136 8.13324 8.86676 8.13324 8.5 8.5C8.13324 8.86676 8.13324 9.46136 8.5 9.82812L15.6719 17L8.5 24.1719C8.13324 24.5386 8.13324 25.1332 8.5 25.5C8.86676 25.8668 9.46136 25.8668 9.82813 25.5L17 18.3281L24.1719 25.5C24.5386 25.8668 25.1332 25.8668 25.5 25.5C25.8668 25.1332 25.8668 24.5386 25.5 24.1719Z" fill="#FDFD88"/>\n' +
        '</svg>'}, (status) => {
      if (status) {
        this.submitClose();
      }
    });
  }

  submitClose() {
    this.cashRegisterService.close().subscribe(data => {
      if(data.status){
        this.popupComponent.showPopupSuccess({text:data.message});
        this.getPageInfo()
      } else {
        this.popupComponent.showPopupError({text:data.message});
      }
    }, () => {
      this.loaderService.hideAndShowTryAgain();
    }, () => {
      this.loaderService.hide();
    });
  }

  getBalance(item) {
      return (+item.amount + +item.in) - +item.out;
  }

  getDate(date) {
    try {
      if (date) {
        return date.replace(' ', 'T');
      }
    } catch (e) {

    }
  }

  ///////---SCROLLING PAGINATION FUNCTIONAL---///////
  // DETECTING SCROLL
  scroll(e, settings: PaginationDataModel) {
      e.stopImmediatePropagation();
      e.stopPropagation();
      this.paginationScrollService.onScroll(e, settings, this.loadNewData.bind(this), true);
  }

  // REQUESTS ON ADDING NEW DATA
  loadNewData(page: number, step = 'end', settings: PaginationDataModel): void {
    if (settings.name ===  'history') {
      // const from = this.getFromDate();
      // const to = this.getToDate();
      this.cashRegisterService.getPageInfo(page).subscribe(data => {
          this.paginationScrollService.dataAddingLogic(page, step, data, settings, true)
        },
        () => { this.loaderService.hideAndShowTryAgain() },
        () => { this.loaderService.hide() }
      );
    }
  }

  initScrolledData(paginationDataName: PaginationDataModel, settings: SetPaginationDataModel) {
    paginationDataName = this.paginationScrollService.initScrolledData(paginationDataName, settings);
  }
}
