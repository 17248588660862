<app-popup-wrapper [show]="show" (onClose)="closePopup()">
    <form [formGroup]="popupForm" (ngSubmit)="onSubmit()" class="form-simple form-simple--primary">
        <div class="form-simple__item">
            <input formControlName="name"
                   placeholder="Name"
                   [type]="'text'"
                   class="form-simple__input"
                   autocomplete="off">
<!--            <span class="form-simple__ad-info">Name</span>-->
        </div>

        <div class="form-simple__item">
            <input formControlName="secretKey"
                   placeholder="Secret Key"
                   [type]="'text'"
                   class="form-simple__input"
                   autocomplete="off">
<!--            <span class="form-simple__ad-info">Secret Key</span>-->
        </div>

        <div class="form-simple__item">
            <input formControlName="webhookSecretKey"
                   placeholder="Webhook Secret Key"
                   [type]="'text'"
                   class="form-simple__input"
                   autocomplete="off">
            <!--            <span class="form-simple__ad-info">Secret Key</span>-->
        </div>

        <div class="form-simple__btns">
            <button class="button snake-btn is-notouched"
                    [disabled]="popupForm.invalid">
                Create
                <svg><rect></rect></svg>
            </button>
        </div>
    </form>
</app-popup-wrapper>



