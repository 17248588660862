<app-popup-wrapper [show]="show" [blur]="showCashMessagePopup || sellCheckboxesPopup.show" (onClose)="closePopup()">
        <div class="page__form">
            <!--        <button *ngIf="!isFirstStep"-->
            <!--                (click)="isFirstStep = true"-->
            <!--                class="back-button">-->
            <!--            Back-->
            <!--        </button>-->
            <form [formGroup]="popupForm" (ngSubmit)="onSubmit()" class="form-simple form-simple--primary">
                <div [hidden]="!isFirstStep">
                    <div class="form-simple__item">
                        <input formControlName="name"
                               placeholder="Name"
                               [type]="'text'"
                               class="form-simple__input"
                               autocomplete="off">
                        <span class="form-simple__ad-info">Name</span>
                    </div>

                    <div class="form-simple__item">
                        <input formControlName="login"
                               (input)="onLoginInput($event)"
                               placeholder="User Name"
                               [type]="'text'"
                               class="form-simple__input"
                               autocomplete="off">
                        <span class="form-simple__ad-info">User Name</span>
                    </div>

                    <div class="form-simple__item">
                        <input formControlName="email"
                               placeholder="Email"
                               [email]="true"
                               [type]="'email'"
                               class="form-simple__input"
                               autocomplete="off">
                        <span class="form-simple__ad-info">Email</span>
                    </div>

                    <div class="form-simple__item">
                        <input mask="separator.0"
                               thousandSeparator=","
                               (input)="onInputRate()"
                               formControlName="rate"
                               placeholder="Percentage Rate"
                               [type]="'tel'"
                               class="form-simple__input"
                               autocomplete="off">
                        <span class="form-simple__ad-info">Percentage Rate</span>
                        <div class="page__form-note" [class.is-active]="moreThenAvailableRate">
                            Max value of rate is {{maxRate}}
                        </div>
                    </div>

                    <div class="form-simple__item">
                        <input formControlName="password"
                               placeholder="Password"
                               [type]="'password'"
                               class="form-simple__input"
                               autocomplete="off">
                        <span class="form-simple__ad-info" *ngIf="isEditing()">Not required</span>
                    </div>


                    <div class="form-simple__item">
                        <input formControlName="repeatPassword"
                               placeholder="Repeat Password"
                               [type]="'password'"
                               class="form-simple__input"
                               autocomplete="off">
                        <span class="form-simple__ad-info" *ngIf="isEditing()">Not required</span>
                    </div>

                    <div class="form-simple__item"
                         *ngIf="isUserRoleOperator()">
                        <input formControlName="promoCode"
                               placeholder="Promo Code"
                               [type]="'text'"
                               class="form-simple__input"
                               autocomplete="off">
                    </div>

                    <div class="form-simple__item" *ngIf="timeZone">
                        <mat-form-field>
                            <mat-select #methodsSelect
                                        formControlName="timeZone"
                                        placeholder="Time Zone"
                                        [(value)]="selectedTimeZone" panelClass="my-select-panel-border-yellow">
                                <mat-select-trigger>
                                    {{methodsSelect?.value?.name}}
                                </mat-select-trigger>
                                <mat-option *ngFor="let method of timeZone" [value]="method">
                                    {{method.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <span class="form-simple__ad-info">Time Zone</span>
                    </div>

                    <div style="min-width: 9rem; margin-top: 1rem; margin-bottom: 2rem;"
                         *ngIf="isUserRoleOperator()">
                        <input formControlName="referralBonus"
                               id="referralBonus"
                               [type]="'checkbox'"
                               class="form-simple__radio"
                               [value]="referralBonus"
                               name="cashPayoutLimit"
                               [checked]="!!referralBonus"
                               (change)="onReferralBonusChange()"
                               autocomplete="off">
                        <label [for]="'referralBonus'">Referral Bonus</label>
                    </div>

                    <div style="min-width: 9rem; margin-top: 1rem; margin-bottom: 2rem;"
                         *ngIf="isUserRoleOperator()">
                        <input formControlName="cashRegister"
                               id="cashRegister"
                               [type]="'checkbox'"
                               class="form-simple__radio"
                               [value]="cashRegister"
                               name="cashPayoutLimit"
                               [checked]="!!cashRegister"
                               (change)="onCashRegisterChange()"
                               autocomplete="off">
                        <label [for]="'cashRegister'">Cash Register</label>
                    </div>

                    <div style="min-width: 9rem; margin-top: 1rem; margin-bottom: 2rem;"
                         *ngIf="isUserRoleOperator()">
                        <input formControlName="nftBonusWheel"
                               id="nftBonusWheel"
                               [type]="'checkbox'"
                               class="form-simple__radio"
                               [value]="nftBonusWheel"
                               name="cashPayoutLimit"
                               [checked]="!!nftBonusWheel"
                               (change)="onNFTBonusWheelChange()"
                               autocomplete="off">
                        <label [for]="'nftBonusWheel'">NFT Bonus Wheel</label>
                    </div>

                    <div style="min-width: 9rem; margin-top: 1rem; margin-bottom: 2rem;"
                         *ngIf="isUserRoleOperator() && showCommission">
                        <input formControlName="distributorCommission"
                               id="distributorCommission"
                               #distributorCommissionHTML
                               [type]="'checkbox'"
                               class="form-simple__radio"
                               [value]="distributorCommission"
                               name="distributorCommission"
                               [checked]="!!distributorCommission"
                               (change)="onDistributorCommissionChange()"
                               autocomplete="off">
                        <label [for]="'distributorCommission'">
                            <ng-container *ngIf="distributorCommissionHTML.checked">7.5% Commission</ng-container>
                            <ng-container *ngIf="!distributorCommissionHTML.checked">Per Transaction</ng-container>
                        </label>
                    </div>

                    <div class="checkbox-list" *ngIf="isUserRoleDistributor()">
                        <div class="checkbox-list__heading">Commission</div>
                        <div class="checkbox-list__list">
                            <div *ngFor="let type of commissionRadioValues"
                                 style="width: 100%; padding-bottom: .2rem;"
                                 class="form-simple__item checkbox-list__item">
                                <input [id]="'commission_type_' + type.value"
                                       [type]="'radio'"
                                       class="form-simple__radio-type"
                                       [value]="type.value"
                                       [checked]="commissionRadioValue === type.value"
                                       (change)="onCommissionRadioChange($event)"
                                       name="commission_type"
                                       autocomplete="off">
                                <label [for]="'commission_type_' + type.value"
                                        style="white-space: unset;">{{type.name}}</label>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="supportShow"
                         style="min-width: 9rem; margin-top: 1rem; margin-bottom: 2rem;">
                        <input formControlName="support"
                               id="support"
                               [type]="'checkbox'"
                               class="form-simple__radio"
                               [value]="support"
                               name="support"
                               [checked]="!!support"
                               (change)="onSupportChange()"
                               autocomplete="off">
                        <label [for]="'support'">
                            Support
                        </label>
                    </div>

                    <div class="checkbox-list">
                        <div class="checkbox-list__heading">Providers</div>
                        <div class="checkbox-list__list">
                            <div *ngFor="let game of games" class="form-simple__item checkbox-list__item checkbox-list__item--providers">
                                <input formControlName="games"
                                       [id]="'game_' + game.id"
                                       [type]="'checkbox'"
                                       class="form-simple__radio"
                                       [value]="game.id"
                                       name="email"
                                       [checked]="+game.status"
                                       (change)="changeMethod($event, 'games', game)"
                                       autocomplete="off">

                                <label [for]="'game_' + game.id" style="display: flex; align-items: center;">
                                    <div class="form-simple__item" style="max-width: 5rem; min-width: 5rem; margin-bottom: 0;">
                                        <input (input)="onPercentInput($event)"
                                               mask="percent.2"
                                               suffix="%"
                                               [attr.initial-value]="game.percentage"
                                               style="font-size: 1rem; padding: .5rem"
                                               (change)="changeMethod($event, 'gamesPercent', game)"
                                               [type]="'text'"
                                               class="form-simple__input js-percent"
                                               autocomplete="off">
                                        <span class="form-simple__ad-info">Percentage</span>
                                    </div>
                                    <span style="margin-left: .5rem;">
                                        {{game.name}}
                                    </span>

                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div [hidden]="isFirstStep">
                    <div class="checkbox-list">
                        <div class="checkbox-list__heading">Payments</div>
                        <div class="checkbox-list__subheading">Cash payments are accepted by default.</div>
                        <div class="checkbox-list__list">
                            <div *ngFor="let item of payments" class="form-simple__item checkbox-list__item">
                                <div style="min-width: 9rem">
                                    <input formControlName="payments"
                                           [id]="'payment_' + item.id"
                                           [type]="'checkbox'"
                                           class="form-simple__radio"
                                           [value]="item.id"
                                           name="email"
                                           [checked]="+item.status"
                                           (change)="changeMethod($event, 'payments', item)"
                                           autocomplete="off">
                                    <label [for]="'payment_' + item.id">{{item.name}}</label>
                                    <span class="checkbox-list__note"
                                          [class.is-active]="updateIsClicked && item.credential && !item.credentialId && !!+item.status">
                                        settings need updating
                                    </span>
                                </div>
                                <button class="checkbox-list__sm-btn button"
                                        type="button"
                                        [disabled]="!+item.status"
                                        (click)="getCredentialsList(item.shortName)"
                                        *ngIf="item.credential">settings</button>
                            </div>
                        </div>
                    </div>
                    <div class="checkbox-list">
                        <div class="checkbox-list__heading">Redeems</div>
                        <div class="checkbox-list__list">
                            <div *ngFor="let item of redeems" class="form-simple__item checkbox-list__item">
                                <div style="min-width: 9rem">
                                    <input formControlName="redeems"
                                           [id]="'redeem' + item.id"
                                           [type]="'checkbox'"
                                           class="form-simple__radio"
                                           [value]="item.id"
                                           name="email"
                                           [checked]="+item.status"
                                           (change)="changeMethod($event, 'redeems', item)"
                                           autocomplete="off">
                                    <label [for]="'redeem' + item.id">{{item.name}}</label>
                                    <span class="checkbox-list__note"
                                          [class.is-active]="updateIsClicked && item.credential && !item.credentialId && !!+item.status">
                                        settings need updating
                                    </span>
                                </div>
                                <button class="checkbox-list__sm-btn button"
                                        type="button"
                                        (click)="openMessageCashPopup()"
                                        *ngIf="item.shortName === CREDENTIALS_TYPES.cashPayout && isUserRoleOperator()">message</button>
                                <button class="checkbox-list__sm-btn button"
                                        type="button"
                                        [disabled]="!+item.status"
                                        (click)="getCredentialsList(item.shortName)"
                                        *ngIf="item.credential">settings</button>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="form-simple__btns">
                    <button class="button snake-btn is-notouched"
                            type="button"
                            *ngIf="isFirstStep"
                            (click)="showSecondStep()"
                            [disabled]="popupForm.invalid || (popupForm.controls.password.value !== popupForm.controls.repeatPassword.value) || ((!popupForm.controls.password.value) && !isEditing())">
                        Continue
                        <svg><rect></rect></svg>
                    </button>

                    <button *ngIf="!isFirstStep"
                            (click)="isFirstStep = true"
                            style="margin-right: 0.5rem"
                            type="button"
                            class="button snake-btn is-notouched">
                        Back
                    </button>

                    <button class="button snake-btn is-notouched"
                            *ngIf="!isEditing() && !isFirstStep"
                            style="margin-left: 0.5rem"
                            [disabled]="popupForm.invalid || (popupForm.controls.password.value !== popupForm.controls.repeatPassword.value) || (!popupForm.controls.password.value)">
                        Create
                        <svg><rect></rect></svg>
                    </button>

                    <button class="button snake-btn is-notouched"
                            *ngIf="isEditing() && !isFirstStep"
                            style="margin-left: 0.5rem"
                            [disabled]="popupForm.invalid">
                        Update
                        <svg><rect></rect></svg>
                    </button>
                </div>
            </form>
        </div>
    </app-popup-wrapper>

<app-popup-wrapper [show]="showCashMessagePopup" (onClose)="closeCashMessagePopup()">
    <form [formGroup]="cashMessageForm" class="form-simple form-simple--primary">
        <div class="form-simple__item">
<!--            <span class="form-simple__ad-info">Name</span>-->
            <textarea formControlName="message"
                      rows="4"
                      #message
                      placeholder="Message"
                      class="form-simple__input"
                      autocomplete="off"></textarea>
        </div>

        <div style="min-width: 9rem">
            <input formControlName="tickets"
                   id="cashTickets"
                   [type]="'checkbox'"
                   class="form-simple__radio"
                   [value]="cashTickets"
                   name="email"
                   [checked]="!!cashTickets"
                   (change)="onCashTicketsChange()"
                   autocomplete="off">
            <label [for]="'cashTickets'">Tickets</label>
        </div>

        <div style="min-width: 9rem; margin-top: 1rem">
            <input formControlName="cashPayoutLimit"
                   id="cashPayoutLimit"
                   [type]="'checkbox'"
                   class="form-simple__radio"
                   [value]="cashPayoutLimit"
                   name="cashPayoutLimit"
                   [checked]="!!cashPayoutLimit"
                   (change)="onCashPayoutLimitChange()"
                   autocomplete="off">
            <label [for]="'cashPayoutLimit'">$500 payout</label>
        </div>

        <div class="form-simple__btns">
            <button (click)="setNewMessage(message.value)"
                    type="button"
                    class="button snake-btn is-notouched">
                Update
            </button>
        </div>
    </form>
</app-popup-wrapper>

<app-popup-sell-checkboxes [show]="sellCheckboxesPopup.show"
                           [info]="sellCheckboxesPopup.info"
                           (onClose)="closeSellCheckboxPopup($event)"></app-popup-sell-checkboxes>

