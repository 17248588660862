import {Component, OnInit} from '@angular/core';
import {slideToLeft} from "../../animations/router.animations";
import {PopupComponent} from "../../shared/popup/popup.component";
import {LoaderService} from "../../service/loader.service";
import {DomSanitizer} from "@angular/platform-browser";
import {SupportService} from "../../service/support/support.service";

@Component({
    selector: 'app-tickets',
    templateUrl: './tickets.component.html',
    styleUrls: ['./tickets.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class TicketsComponent implements OnInit {
    frameUrl;
    constructor(public sanitizer: DomSanitizer,
                private loaderService: LoaderService,
                private popupComponent: PopupComponent,
                private supportService: SupportService) {
    }

  ngOnInit() {
    this.getChatLink();
  }

  getChatLink() {
    this.supportService.getSession().subscribe(data => {
      this.getChatLinkResult(data);
    }, (error) => {
      this.loaderService.hideAndShowTryAgain();
    }, () => {
      this.loaderService.hide();
    });
  }

  getChatLinkResult(data) {
    try {
      if (data.status) {
        this.frameUrl = this.getLink(data.sessionLink);
      } else if (data.status === false) {
        this.popupComponent.showPopupError({text: data.message});
      } else {
        this.loaderService.hideAndShowTryAgain();
      }
    } catch (e) {
      this.loaderService.hideAndShowTryAgain();
    }

  }

  getLink(url){
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
