<div class="activity" *ngIf="activity">
    <ul class="activity__list" (scroll)="listScroll($event)">

        <li *ngFor="let item of activity" class="activity__item js-activity-item">
            <button class="activity__btn" (click)="openUserSettings(getId(item))">
                <div class="activity__left">
                    <div *ngIf="getPhone(item)" class="activity__title">{{getPhone(item) | mask: getPhoneMask(item)}}</div>
                    <div *ngIf="!getPhone(item)" class="activity__title">{{getEmail(item)}}</div>
                    <div class="activity__info">{{getPin(item)}}</div>
                </div>
                <div class="activity__right">

                        <svg class="activity__direction" width="29" height="36">
                            <use xlink:href="../../../../assets/images/sprite.svg#settings"></use>
                        </svg>
                </div>
            </button>
        </li>
    </ul>
</div>
