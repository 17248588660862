import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {EXISTING_USER_CONFIG} from "../../config/api";
import {HttpClient} from "@angular/common/http";
import {LoaderService} from "../loader.service";

@Injectable({
    providedIn: 'root'
})
export class ExistingUserService {
    private config = EXISTING_USER_CONFIG;

    constructor(private http: HttpClient,
                private loaderService: LoaderService) {}

    getUsers(page, phone) {
        return this.http.post<any>(this.config.getUsers, {page, phone});
    }

    getUserInfo(id, page, list = null) {
        this.loaderService.show();
        return this.http.post<any>(this.config.userInfo, {id, page, list});
    }

    sell(id, amount) {
        this.loaderService.show();
        return this.http.post<any>(this.config.sell, {id, amount});
    }

    buy(id, amount) {
        this.loaderService.show();
        return this.http.post<any>(this.config.buy, {id, amount});
    }

    reverse(id) {
        this.loaderService.show();
        return this.http.post<any>(this.config.reverse, {id});
    }

    reverseCreate(id) {
        this.loaderService.show();
        return this.http.post<any>(this.config.reverseCreate, {id});
    }

}
