import {Inject, Injectable, NgZone} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {PAYMENT_SETTINGS_CONFIG} from "../config/api";
import {LoaderService} from "./loader.service";

@Injectable({
    providedIn: 'root'
})
export class PaymentSettingsService {
    private config = PAYMENT_SETTINGS_CONFIG;

    constructor(private http: HttpClient,
                private loaderService: LoaderService) {}

    getPageInfo() {
        this.loaderService.show();
        return this.http.post<any>(this.config.getInfo, {});
    }

    saveStripe(private_key, webhook_key) {
        this.loaderService.show();
        return this.http.post<any>(this.config.saveStripe, {private_key, webhook_key});
    }

    saveSquare(access_token, application_id, location_id) {
        this.loaderService.show();
        return this.http.post<any>(this.config.saveSquare, {access_token, application_id, location_id});
    }

    savePayPal(clientId, clientSecret) {
        this.loaderService.show();
        return this.http.post<any>(this.config.savePayPal, {clientId, clientSecret});
    }
}
