import { NEW_USER_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../loader.service";
var NewUserService = /** @class */ (function () {
    function NewUserService(http, loaderService) {
        this.http = http;
        this.loaderService = loaderService;
        this.config = NEW_USER_CONFIG;
    }
    NewUserService.prototype.sell = function (phone, amount) {
        this.loaderService.show();
        return this.http.post(this.config.save, { phone: phone, amount: amount });
    };
    NewUserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NewUserService_Factory() { return new NewUserService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: NewUserService, providedIn: "root" });
    return NewUserService;
}());
export { NewUserService };
