import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {LoaderService} from "../../../service/loader.service";
import {PaymentSettingsService} from "../../../service/payment-settings.service";

@Component({
    selector: 'app-popup-settings',
    templateUrl: './popup-settings.component.html',
    styleUrls: ['./popup-settings.component.scss']
})
export class PopupSettingsComponent implements OnInit {
    @Input() show: boolean;
    @Input() info: any;
    @Output('onClose') emitClose: EventEmitter<boolean> = new EventEmitter();

    stripeForm: FormGroup;
    squareForm: FormGroup;
    payPalForm: FormGroup;

    maxPageScrollPosition = 0;
    constructor(private formBuilder: FormBuilder,
                private loaderService: LoaderService,
                private popupComponent: PopupComponent,
                private paymentSettingsService: PaymentSettingsService) {}

    ngOnInit() {
        this.stripeForm = this.formBuilder.group({
            privateKey: [null, Validators.required],
            webhookKey: [null, Validators.required],
        });
        this.squareForm = this.formBuilder.group({
            accessToken: [null, Validators.required],
            applicationId: [null, Validators.required],
            locationId: [null, Validators.required],
        });
        this.payPalForm = this.formBuilder.group({
            clientId: [null, Validators.required],
            clientSecret: [null, Validators.required],
        });
    }

    ngOnChanges(changes) {
        this.resetForms();

        if (changes.show && changes.show.currentValue) {
            setTimeout(() => {
                document.querySelectorAll('.js-dropdown').forEach((item, index) => {
                    index === 0 ? item.classList.remove('is-hidden') : item.classList.add('is-hidden');
                });
            });

            this.getPageInfo();
        }
    }

    resetForms() {
        if (this.stripeForm) {
            this.stripeForm.reset();
        }
        if (this.squareForm) {
            this.squareForm.reset();
        }
        if (this.payPalForm) {
            this.payPalForm.reset();
        }
    }

    getPageInfo() {
        this.paymentSettingsService.getPageInfo().subscribe(data => {
            this.setPageInfo(data);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        })
    }

    setPageInfo(data) {
        try {
            if (data.status) {
                this.resetForms();

                if (data.stripe) {
                    const privateKey = data.stripe.private_key;
                    const webhookKey = data.stripe.webhook_key;

                    this.stripeForm.controls.privateKey.setValue(privateKey);
                    this.stripeForm.controls.webhookKey.setValue(webhookKey);
                }
                if (data.square) {
                    const accessToken = data.square.access_token;
                    const applicationId = data.square.application_id;
                    const locationId = data.square.location_id;

                    this.squareForm.controls.accessToken.setValue(accessToken);
                    this.squareForm.controls.applicationId.setValue(applicationId);
                    this.squareForm.controls.locationId.setValue(locationId);
                }
                if (data.paypal) {
                    const clientId = data.paypal.clientId;
                    const clientSecret = data.paypal.clientSecret;

                    this.payPalForm.controls.clientId.setValue(clientId);
                    this.payPalForm.controls.clientSecret.setValue(clientSecret);
                }
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    closePopup() {
        this.emitClose.emit();
        this.resetForms();
    }

    toggleDropdown(e) {
        const hidden = e.target.offsetParent.classList.contains('is-hidden') ? true : false;
        document.body.querySelectorAll('.js-dropdown').forEach(el => {
            el.classList.add('is-hidden');
        });

        if (hidden) {
            e.target.offsetParent.classList.remove('is-hidden');

            setTimeout(() => {
                const pageScroll = (<HTMLElement>document.querySelectorAll('.page')[0]).scrollHeight - (<HTMLElement>document.querySelectorAll('.page')[0]).offsetHeight;
                const scrollTo = pageScroll <= this.maxPageScrollPosition || this.maxPageScrollPosition === 0 ? pageScroll : this.maxPageScrollPosition;

                (<HTMLElement>document.querySelectorAll('.page')[0]).scrollIntoView({behavior: 'smooth'});
                (<HTMLElement>document.querySelectorAll('.page')[0]).scroll({
                    top: scrollTo,
                    left: 0,
                    behavior: 'smooth'
                });
            }, 350)

        }
    }

    onStripeFormSubmit() {
        const privateKey = this.stripeForm.controls.privateKey.value;
        const webhookKey = this.stripeForm.controls.webhookKey.value;

        this.paymentSettingsService.saveStripe(privateKey, webhookKey).subscribe(data => {
            this.handleSubmitFormResult(data);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    onSquareFormSubmit() {
        const accessToken = this.squareForm.controls.accessToken.value;
        const applicationId = this.squareForm.controls.applicationId.value;
        const locationId = this.squareForm.controls.locationId.value;

        this.paymentSettingsService.saveSquare(accessToken, applicationId, locationId).subscribe(data => {
            this.handleSubmitFormResult(data);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    onPayPalFormSubmit() {
        const clientId = this.payPalForm.controls.clientId.value;
        const clientSecret = this.payPalForm.controls.clientSecret.value;

        this.paymentSettingsService.savePayPal(clientId, clientSecret).subscribe(data => {
            this.handleSubmitFormResult(data);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    handleSubmitFormResult(data) {
        try {
            if (data.status) {
                this.popupComponent.showPopupSuccess({text: data.message});
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }
}
