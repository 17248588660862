import { TERMINAL_CONFIG } from "../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./loader.service";
var TerminalService = /** @class */ (function () {
    function TerminalService(http, loaderService) {
        this.http = http;
        this.loaderService = loaderService;
        this.config = TERMINAL_CONFIG;
    }
    TerminalService.prototype.getTerminalsList = function () {
        this.loaderService.show();
        return this.http.post(this.config.getTerminalsList, {});
    };
    TerminalService.prototype.getPageInfo = function (id) {
        this.loaderService.show();
        return this.http.post(this.config.getPageInfo, { id: id });
    };
    TerminalService.prototype.getItemInfo = function (id) {
        this.loaderService.show();
        return this.http.post(this.config.getItemInfo, { id: id });
    };
    TerminalService.prototype.setItemInfo = function (id, name, location, status, teamViewerLogin, teamViewerPassword, keyboard) {
        this.loaderService.show();
        return this.http.post(this.config.setItemInfo, { id: id, name: name, location: location, status: status, teamViewerLogin: teamViewerLogin, teamViewerPassword: teamViewerPassword, keyboard: keyboard });
    };
    TerminalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TerminalService_Factory() { return new TerminalService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: TerminalService, providedIn: "root" });
    return TerminalService;
}());
export { TerminalService };
