import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
    selector: 'app-popup-sell-checkboxes',
    templateUrl: './popup-sell-checkboxes.component.html',
    styleUrls: ['./popup-sell-checkboxes.component.scss']
})
export class PopupSellCheckboxesComponent implements OnInit {
    @Input() show: boolean;
    @Input() info: any;
    @Output('onClose') emitClose: EventEmitter<boolean> = new EventEmitter();

    popupForm: FormGroup;

    constructor(private formBuilder: FormBuilder) {}

    ngOnInit() {
        this.popupForm = this.formBuilder.group({
            items: [null],
        });
    }

    ngOnChanges(changes) {
        if (this.popupForm) {
            this.popupForm.reset();
        }

        if (changes.show && changes.show.currentValue) {
            if (this.info.selected) {
                this.popupForm.controls.items.setValue(this.info.selected);
            }
        }
    }

    onSubmit() {
        const selectedId = this.popupForm.controls.items.value;
        this.closePopup(selectedId);
    }

    closePopup(selectedId = null) {
        this.emitClose.emit(selectedId);
        this.popupForm.reset();
    }
}
