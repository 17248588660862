<app-popup-wrapper [show]="show" (onClose)="closePopup()">
    <div class="page__form">
        <form [formGroup]="popupForm" (ngSubmit)="onSubmit()" class="form-simple form-simple--primary">
            <div class="form-simple__item form-simple__item--amount">
                <input mask="separator.0"
                       thousandSeparator=","
                       formControlName="amount"
                       placeholder="Amount"
                       [type]="'tel'"
                       class="form-simple__input"
                       autocomplete="off">
            </div>

            <div class="form-simple__btns">
                <button class="button snake-btn is-notouched"
                        [disabled]="!+popupForm.controls.amount.value">
                    Send
                    <svg><rect></rect></svg>
                </button>
            </div>
        </form>
    </div>
</app-popup-wrapper>



