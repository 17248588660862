<div class="page page--scrolled" [class.page--blured]="popupDeposit.show">
    <div class="page__inner">
        <h1 class="page__title">CASH REGISTER REPORT</h1>

        <div class="page__body" (scroll)="scroll($event, pageData)">
            <div class="header-wrapper">
                <div class="sell-header centered-buttons">
                    <button class="button snake-btn is-notouched sell-header__button"
                            (click)="onOpen()">
                        Open
                        <svg><rect></rect></svg>
                    </button>
                    <button class="button snake-btn is-notouched sell-header__button"
                            (click)="onClose()">
                        Close
                        <svg><rect></rect></svg>
                    </button>
                </div>
            </div>

            <div class="history-table-wrapper">
                <table class="history-table">
                    <thead class="history-table__head">
                        <tr class="js-history-item">
                            <th>Opening</th>
                            <th>Cashier</th>
                            <th>Initial</th>
                            <th>In</th>
                            <th>Out</th>
                            <th>Balance</th>
                            <th>Closing</th>
                        </tr>
                    </thead>
                    <tbody class="history-table__body">
                        <tr *ngFor="let item of pageData.items"
                            class="js-history-item"
                            [class.is-opened]="!+item.status">
                            <td style="min-width: 7rem">{{item.open}}</td>
                            <td>{{item.name}}</td>
                            <td>{{item.amount | currency}}</td>
                            <td>{{item.in | currency}}</td>
                            <td>{{item.out | currency}}</td>
                            <td>{{getBalance(item) | currency}}</td>
                            <td style="min-width: 7rem">{{item.close}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<app-popup-cash-register-opening [show]="popupDeposit.show"
                                 (openSuccess)="onSuccessOpen($event)"
                                 (onClose)="onCloseOpen()"></app-popup-cash-register-opening>
