import { OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TEMPLATE_VERSION } from "../../config/template_version";
import { RouterPaths } from "../../config/router-paths.model";
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "../../modules/auth/_services/authentication.service";
import * as i3 from "../../service/login.service";
import * as i4 from "../../service/menu.service";
import * as i5 from "@angular/router";
import * as i6 from "../../service/loader.service";
import * as i7 from "../../service/blurMenu.service";
import * as i8 from "../../service/rememberUser.service";
import * as i9 from "../../service/userRole.service";
import * as i10 from "../../shared/popup/popup.component";
var LoginComponent = /** @class */ (function () {
    function LoginComponent(formBuilder, fb, authenticationService, loginService, menuService, router, auth, loaderService, blurMenuService, rememberUserService, userRoleService, popupComponent) {
        this.formBuilder = formBuilder;
        this.fb = fb;
        this.authenticationService = authenticationService;
        this.loginService = loginService;
        this.menuService = menuService;
        this.router = router;
        this.auth = auth;
        this.loaderService = loaderService;
        this.blurMenuService = blurMenuService;
        this.rememberUserService = rememberUserService;
        this.userRoleService = userRoleService;
        this.popupComponent = popupComponent;
        this.TEMPLATE_VERSION = TEMPLATE_VERSION;
    }
    LoginComponent.prototype.clickInside = function (event) {
        var snakeBtns = document.querySelectorAll('.snake-btn');
        snakeBtns.forEach(function (el) {
            el.classList.remove('is-notouched');
        });
    };
    LoginComponent.prototype.ngOnInit = function () {
        this.auth.logout();
        this.userForm = this.formBuilder.group({
            password: [null, Validators.required],
            user_name: [null, Validators.required],
            remember_user: [false]
        });
        this.userForm.controls.password.setValue('');
        if (localStorage.remember_user !== "false") {
            this.userForm.controls.remember_user.setValue(true);
            this.userForm.controls.user_name.setValue(localStorage.usrname);
        }
        else {
            this.userForm.controls.remember_user.setValue(false);
            //this.userForm.controls.password.setValue('');
            this.userForm.controls.user_name.setValue('');
        }
        this.rememberUserService.checkSaveUser(this.userForm.controls.user_name, this.userForm.controls.password);
        this.blurMenuService.focus();
    };
    LoginComponent.prototype.onSubmit = function () {
        var _this = this;
        if (this.userForm.invalid) {
            return;
        }
        var userName = this.userForm.controls.user_name.value;
        var password = this.userForm.controls.password.value;
        this.loaderService.show();
        this.authenticationService.login(userName, password).subscribe(function (data) {
            _this.loaderService.hide();
            try {
                if (data.status) {
                    if (_this.userForm.controls.remember_user.value) {
                        // save username and password
                        localStorage.usrname = userName;
                    }
                    else {
                        localStorage.usrname = '';
                    }
                    localStorage.remember_user = _this.userForm.controls.remember_user.value;
                    _this.rememberUserService.setSaveUser(userName, password);
                    _this.checkTemplate();
                }
                else {
                    _this.popupComponent.showPopupError({ text: data.message });
                }
            }
            catch (e) {
                _this.popupComponent.showPopupError({ text: 'Sorry there was an error try again later.' });
            }
        });
    };
    LoginComponent.prototype.checkTemplate = function () {
        if (this.userRoleService.isUserRoleLocation() || this.userRoleService.isUserRoleCashier()) {
            this.router.navigate([RouterPaths.sell.locationNav]);
            return;
        }
        else {
            this.router.navigate(['/home/sell']);
        }
    };
    LoginComponent.prototype.onRememberUser = function (e) {
        var checked = e.target.checked;
        this.userForm.controls.remember_user.setValue(!!checked);
    };
    LoginComponent.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginComponent_Factory() { return new LoginComponent(i0.ɵɵinject(i1.FormBuilder), i0.ɵɵinject(i1.FormBuilder), i0.ɵɵinject(i2.AuthenticationService), i0.ɵɵinject(i3.LoginService), i0.ɵɵinject(i4.MenuService), i0.ɵɵinject(i5.Router), i0.ɵɵinject(i2.AuthenticationService), i0.ɵɵinject(i6.LoaderService), i0.ɵɵinject(i7.BlurMenuService), i0.ɵɵinject(i8.RememberUserService), i0.ɵɵinject(i9.UserRoleService), i0.ɵɵinject(i10.PopupComponent)); }, token: LoginComponent, providedIn: "root" });
    return LoginComponent;
}());
export { LoginComponent };
