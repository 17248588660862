<div class="sell">
    <div class="sell__inner">
        <div class="navigation">
            <a [routerLink]="['/home/sell/new-user']" class="navigation__button button snake-btn is-notouched">
                NEW USER
                <svg><rect></rect></svg>
            </a>
            <a [routerLink]="['/home/sell/existing-user']" class="navigation__button navigation__button--secondary button snake-btn is-notouched">
                EXISTING USER
                <svg><rect></rect></svg>
            </a>

            <div class="navigation__bottom">
                <button  *ngIf="hasCashRegister"
                         (click)="goToCashRegisterPage()" class="navigation__button navigation__button--default button snake-btn is-notouched">
                    Cash Register
                    <svg><rect></rect></svg>
                </button>
<!--                <div class="navigation__info">Your SHARE is {{rate}}%</div>-->
                <div class="navigation__info" *ngIf="!isGrossNet()">NET Percentage Rate is {{rate}}%</div>
                <div class="navigation__info" *ngIf="isGrossNet()">GROSS Percentages Rate is {{rate}}%</div>
                <div class="navigation__info">Your POS Balance is {{balance | currency : 'USD' : 'symbol'}}</div>
                <button (click)="requestRefill()" class="navigation__button navigation__button--default button snake-btn is-notouched">
                    REQUEST REFILL
                    <svg><rect></rect></svg>
                </button>
            </div>
        </div>
    </div>
</div>

