import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SUPPORT_CONFIG} from "../../config/api";
import {LoaderService} from "../loader.service";


@Injectable({
  providedIn: 'root'
})
export class SupportService {
  private config = SUPPORT_CONFIG;

  constructor(private http: HttpClient, public loaderService: LoaderService) {
  }

  getSession() {
    this.loaderService.show();
    return this.http.post<any>(this.config.session, {});
  }
}
