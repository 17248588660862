import {Inject, Injectable, NgZone} from '@angular/core';
import {REPORTS_CONFIG} from "../../config/api";
import {HttpClient} from "@angular/common/http";
import {LoaderService} from "../loader.service";

@Injectable({
    providedIn: 'root'
})
export class ReportsService {
    private config = REPORTS_CONFIG;

    constructor(private http: HttpClient,
                private loaderService: LoaderService) {

    }

    getPageInfo(page, date) {
        this.loaderService.show();
        return this.http.post<any>(this.config.getPageInfo, {page, date});
    }

    settle() {
        this.loaderService.show();
        return this.http.post<any>(this.config.settle, {});
    }

    tableSettle(id) {
        this.loaderService.show();
        return this.http.post<any>(this.config.tableSettle, {id});
    }

    confirmSettle(id) {
        this.loaderService.show();
        return this.http.post<any>(this.config.confirmSettle, {id});
    }
}
