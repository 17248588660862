import {Component, OnInit} from '@angular/core';
import {routerTransition, slideToLeft} from "../../../animations/router.animations";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ExistingUserService} from "../../../service/sell/existingUser.service";
import {ActivatedRoute, Router} from "@angular/router";
import {LoaderService} from "../../../service/loader.service";
import {PhoneMaskService} from "../../../service/phoneMask.service";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {ResetPageScrollService} from "../../../service/pageScroll/resetPageScroll";
import {PaginationData} from "../../../models/scrollPagination/paginationData";
import {PaginationDataModel, SetPaginationDataModel} from "../../../models/scrollPagination/paginationData.model";
import {PaginationScrollService} from "../../../service/pageScroll/paginationScroll.service";
import {TemplateService} from "../../../service/template.service";
import {RouterPaths} from "../../../config/router-paths.model";

@Component({
    selector: 'app-existing-user',
    templateUrl: './existing-user.component.html',
    styleUrls: ['./existing-user.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class ExistingUserComponent implements OnInit {
    existingUserForm: FormGroup;
    phoneMask = this.phoneMaskService.setPhoneMask();

    prevPageTopOffset = 0;
    prevChildTopOffset = 0;

    usersData: PaginationDataModel = new PaginationData('users', '.js-activity-item', '.page__body');
    constructor(private formBuilder: FormBuilder,
                private route: ActivatedRoute,
                private loaderService: LoaderService,
                private router: Router,
                private popupComponent: PopupComponent,
                private phoneMaskService: PhoneMaskService,
                private paginationScrollService: PaginationScrollService,
                private resetPageScrollService: ResetPageScrollService,
                private templateService: TemplateService,
                private existingUserService: ExistingUserService) {

    }
    ngOnInit() {
        this.existingUserForm = this.formBuilder.group({
            phone: [null, Validators.required]
        });

        document.body.addEventListener('touchstart', (e) => {
            this.resetPageScrollService.onTouch(e);
        });

        this.getUsers('');
    }

    initScrolledData(paginationDataName: PaginationDataModel, settings: SetPaginationDataModel) {
        paginationDataName = this.paginationScrollService.initScrolledData(paginationDataName, settings);
    }

    onSubmit() {
        this.getUsers(this.inputHasValue('phone'));
    }

    getUsers(phone){
        this.loaderService.show();
        this.existingUserService.getUsers(1, phone).subscribe(data => {
            this.paginationScrollService.resetPagination(this.usersData);
            try {
                if (data.status) {
                    const usersDataSettings: SetPaginationDataModel = {
                        itemsList: data.result,
                        scrollLimit: data.limit,
                        total: data.total
                    };
                    this.initScrolledData(this.usersData, usersDataSettings);

                }
            }catch (e) {

            }
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    inputHasValue(key) {
        return this.existingUserForm.controls[key].value;
    }

    resetInput(key) {
        this.existingUserForm.controls[key].setValue(null);
    }

    // openUserSettings(id) {
    //     this.router.navigate(['existing-user-info', id], {relativeTo: this.route});
    // }

    openUserSettings(id) {
        this.router.navigate(['existing-user-info', id], {relativeTo: this.route});
    }

    ///////---SCROLLING PAGINATION FUNCTIONAL---///////

    // DETECTING SCROLL
    scroll(e, settings) {
        e.stopImmediatePropagation();
        e.stopPropagation();

        const direction = this.prevChildTopOffset > e.target.scrollTop ? 'up' : 'down';

        if (direction === 'up' && e.target.scrollTop === 0 && (<HTMLElement>document.querySelectorAll('.page')[0]).style.overflowY === 'hidden') {
            (<HTMLElement>document.querySelectorAll('.page')[0]).style.overflowY = 'auto'
        }
        this.prevChildTopOffset = e.target.scrollTop;

        this.paginationScrollService.onScroll(e, settings, this.loadNewData.bind(this));
    }

    // REQUESTS ON ADDING NEW DATA
    loadNewData(page, step = 'end', settings):any {
        const phone = this.existingUserForm.controls.phone.value ? this.existingUserForm.controls.phone.value : '';
        this.existingUserService.getUsers(page, phone).subscribe(data => {
            this.paginationScrollService.dataAddingLogic(page, step, data, settings);
        }, () => {
            this.loaderService.hide();
        }, () => {
            this.loaderService.hide();
        });
    }

    isIos(){
        let userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test( userAgent );
    }

    pageScroll($event, to) {
        const direction = this.prevPageTopOffset > $event.target.scrollTop ? 'up' : 'down';
        const waitingTime = direction === 'up' ? 500 : 0;
        const pageHasScroll = $event.target.scrollHeight;

        const toOffset = 10;
        const toMaxPosition = to.offsetTop - toOffset;

        setTimeout(() => {
            if (($event.target.scrollTop > toMaxPosition) && pageHasScroll) {

                $event.target.style.overflowY = 'hidden';
                $event.target.scrollTop = toMaxPosition;
            }
        }, waitingTime);

        this.prevPageTopOffset = $event.target.scrollTop;
    }

    getBackLink() {
        return RouterPaths.sell.locationNav;
    }
}
