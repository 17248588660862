<div class="page page--scrolled" [class.page--blured]="editPopup.show">
    <div class="page__inner">
        <h1 class="page__title">Terminal</h1>

        <div class="page__body">
            <div class="header-wrapper">
                <div class="sell-header">
                    <div class="sell-header__balance">
                        <form [formGroup]="terminalsListForm" class="form-simple form-simple--primary">
                            <div class="form-simple__item" *ngIf="terminalsList && terminalsList.length">
                                <mat-form-field>
                                    <mat-select #terminalSelect
                                                formControlName="terminal"
                                                placeholder="Terminal"
                                                (ngModelChange)="onTerminalChanged($event)"
                                                [(value)]="selectedTerminal" panelClass="my-select-panel-border-yellow">
                                        <mat-select-trigger>
                                            {{terminalSelect?.value?.name}}
                                        </mat-select-trigger>
                                        <mat-option *ngFor="let terminal of terminalsList" [value]="terminal">
                                            {{terminal.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <!--                            <span class="form-simple__ad-info">Locations</span>-->
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="history-table-wrapper">
                <table class="history-table">
                    <thead class="history-table__head">
                        <tr class="js-history-item">
                            <th>Name</th>
                            <th style="text-align: center">Status</th>
                            <th style="text-align: center">Settings</th>
                        </tr>
                    </thead>
                    <tbody class="history-table__body">
                        <tr *ngFor="let item of pageData" class="js-stripe-item">
                            <td>{{item.name}}</td>
                            <td style="text-align: center">
                                <ng-container *ngIf="!isDisable(item.status)">
                                    Active
                                </ng-container>
                                <ng-container *ngIf="isDisable(item.status)">
                                    Disabled
                                </ng-container></td>
                            <td style="text-align: center">
                                <button type="button" class="edit-btn"
                                        (click)="onEditItem(item.id)">
                                    <svg>
                                        <use [attr.xlink:href]="'../../../../assets/images/sprite.svg#pencil'"></use>
                                    </svg>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<app-popup-terminal-settings [show]="editPopup.show"
                             [id]="editPopup.id"
                             (onClose)="onCloseEditPopup($event)"></app-popup-terminal-settings>
