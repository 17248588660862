import { SETTINGS_CONFIG } from "../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./loader.service";
var SettingsService = /** @class */ (function () {
    function SettingsService(http, loaderService) {
        this.http = http;
        this.loaderService = loaderService;
        this.config = SETTINGS_CONFIG;
    }
    SettingsService.prototype.getStripeInfo = function () {
        this.loaderService.show();
        return this.http.post(this.config.getStripeInfo, {});
    };
    SettingsService.prototype.addStripeItem = function (name, secretKey, webhookSecretKey) {
        this.loaderService.show();
        return this.http.post(this.config.addStripeItem, { name: name, secretKey: secretKey, webhookSecretKey: webhookSecretKey });
    };
    SettingsService.prototype.disableStripeItem = function (id) {
        this.loaderService.show();
        return this.http.post(this.config.disableStripeItem, { id: id });
    };
    SettingsService.prototype.getSquareInfo = function () {
        this.loaderService.show();
        return this.http.post(this.config.getSquareInfo, {});
    };
    SettingsService.prototype.addSquareItem = function (name, aplicationId, locationId, accessToken) {
        this.loaderService.show();
        return this.http.post(this.config.addSquareItem, { name: name, aplicationId: aplicationId, locationId: locationId, accessToken: accessToken });
    };
    SettingsService.prototype.disableSquareItem = function (id) {
        this.loaderService.show();
        return this.http.post(this.config.disableSquareItem, { id: id });
    };
    SettingsService.prototype.getPaypalInfo = function () {
        this.loaderService.show();
        return this.http.post(this.config.getPaypalInfo, {});
    };
    SettingsService.prototype.addPaypalItem = function (name, clientId, clientSecret) {
        this.loaderService.show();
        return this.http.post(this.config.addPaypalItem, { name: name, clientId: clientId, clientSecret: clientSecret });
    };
    SettingsService.prototype.disablePaypalItem = function (id) {
        this.loaderService.show();
        return this.http.post(this.config.disablePaypalItem, { id: id });
    };
    SettingsService.prototype.getPaynoteInfo = function () {
        this.loaderService.show();
        return this.http.post(this.config.getPaynoteInfo, {});
    };
    SettingsService.prototype.addPaynoteItem = function (name, privateToken) {
        this.loaderService.show();
        return this.http.post(this.config.addPaynoteItem, { name: name, privateToken: privateToken });
    };
    SettingsService.prototype.disablePaynoteItem = function (id) {
        this.loaderService.show();
        return this.http.post(this.config.disablePaynoteItem, { id: id });
    };
    SettingsService.prototype.getContactInfo = function () {
        this.loaderService.show();
        return this.http.post(this.config.getContactInfo, {});
    };
    SettingsService.prototype.saveContact = function (email) {
        this.loaderService.show();
        return this.http.post(this.config.saveContact, { email: email });
    };
    SettingsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SettingsService_Factory() { return new SettingsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: SettingsService, providedIn: "root" });
    return SettingsService;
}());
export { SettingsService };
