<div class="page page--scrolled" [class.is-ios]="isIos()" [class.page--blured]="popupReportsTable.show || popupReportsDetails.show">
    <div class="page__inner">
        <h1 class="page__title">Reports</h1>
        <div class="page__body" (scroll)="scroll($event, historyData)">
            <div class="page__form">
                <form [formGroup]="reportsForm" class="form-simple">
                    <div class="form-simple__row">
                        <div class="form-simple__item">
                            <mat-form-field appearance="outline">
                                <input (dateChange)="selectDate($event)"
                                       [min]="getMinReportsDate()"
                                       (click)="picker.open()"
                                       style="cursor: pointer" formControlName="date" readonly placeholder="From" matInput
                                       [matDatepicker]="picker">
                                <mat-datepicker-toggle matSuffix [for]="picker">
                                    <svg matDatepickerToggleIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.5338 2.43848H7.46387V3.84468H16.5338V2.43848Z" fill="white"/>
                                        <path d="M6.05727 3.14148H4.66699V3.68846H6.05727V3.14148Z" fill="white"/>
                                        <path d="M19.3307 3.14148H17.9404V3.68846H19.3307V3.14148Z" fill="white"/>
                                        <path d="M20.7372 2.43848V5.09479H16.5345V3.14155H7.46456V5.09479H3.26189V2.43848H0V6.79766H23.9991V2.43848H20.7372Z" fill="white"/>
                                        <path d="M0 8.20386V24.0001H23.9991V8.20386H0ZM5.24512 21.3752H2.92163V19.969H5.24512V21.3752ZM5.24512 18.3285H2.92163V16.9223H5.24512V18.3285ZM5.24512 15.2817H2.92163V13.8755H5.24512V15.2817ZM9.20306 21.3752H6.88003V19.969H9.20306V21.3752ZM9.20306 18.3285H6.88003V16.9223H9.20306V18.3285ZM9.20306 15.2817H6.88003V13.8755H9.20306V15.2817ZM13.161 21.3752H10.838V19.969H13.161V21.3752ZM13.161 18.3285H10.838V16.9223H13.161V18.3285ZM13.161 15.2817H10.838V13.8755H13.161V15.2817ZM13.161 12.235H10.838V10.8288H13.161V12.235ZM17.119 21.3752H14.796V19.969H17.119V21.3752ZM17.119 18.3285H14.796V16.9223H17.119V18.3285ZM17.119 15.2817H14.796V13.8755H17.119V15.2817ZM17.119 12.235H14.796V10.8288H17.119V12.235ZM21.0774 18.3285H18.7539V16.9223H21.0774V18.3285ZM21.0774 15.2817H18.7539V13.8755H21.0774V15.2817ZM21.0774 12.235H18.7539V10.8288H21.0774V12.235Z" fill="white"/>
                                        <path d="M6.05727 0H4.66699V3.68845H6.05727V0Z" fill="white"/>
                                        <path d="M19.3307 0H17.9404V3.68845H19.3307V0Z" fill="white"/>
                                    </svg>
                                </mat-datepicker-toggle>
                                <mat-datepicker [dateClass]="setClass()" (opened)="openDatepicker($event)" #picker></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="form-simple__info">
                        All dates and times are expressed in UTC.
                    </div>

<!--                    <div class="form-simple__btns">-->
<!--                        <button class="button snake-btn is-notouched">-->
<!--                            FILTER-->
<!--                            <svg><rect></rect></svg>-->
<!--                        </button>-->
<!--                    </div>-->

<!--                    <div class="form-simple__btns" *ngIf="!isUserRoleAdmin()">-->
<!--                        <button class="button snake-btn is-notouched"-->
<!--                                type="button"-->
<!--                                (click)="onSettle()">-->
<!--                            Settle-->
<!--                            <svg><rect></rect></svg>-->
<!--                        </button>-->
<!--                    </div>-->
                </form>
            </div>
            <div class="history-table-wrapper">
                <table class="history-table">
                    <thead class="history-table__head">
                    <tr class="js-history-item">
                        <th>Name</th>
                        <th style="text-align: center"
                            *ngIf="showNetCol()">Net</th>
                        <th style="text-align: center"
                            *ngIf="showGrossNetCol()">Gross Net</th>
                        <th style="min-width: 5rem; text-align: center">Earned share</th>
                        <th style="min-width: 6rem; text-align: center">CASH</th>
                        <th style="min-width: 6rem; text-align: center">Remote</th>
                        <th>Promo</th>
                        <th *ngIf="showNetCol()">Providers</th>
                        <th>Settle</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody class="history-table__body">
                    <tr *ngFor="let item of historyData.items" class="js-history-item">
                        <td>{{item.name}}</td>
                        <td *ngIf="showNetCol()">
                            <ng-container *ngIf="!isGrossNet(item.earning) || (!isUserRoleLocation() && !isUserRoleOperator() && !isUserRoleDistributor())">
                                {{item.net | currency}}
                            </ng-container>
                            <ng-container *ngIf="isGrossNet(item.earning) && (isUserRoleLocation() || isUserRoleOperator() || isUserRoleDistributor())">
                                -
                            </ng-container>

                        </td>
                        <td *ngIf="showGrossNetCol()">
                            <ng-container *ngIf="isGrossNet(item.earning)">
                                {{item.grossNet | currency}}
                            </ng-container>
                            <ng-container *ngIf="!isGrossNet(item.earning)">
                                -
                            </ng-container>

                        </td>
                        <td>
                            {{item.revenue | currency}}
                        </td>

                        <td  *ngIf="item.rate < 100"style="min-width: 5rem">
                            {{item.settleLocation | currency}} ({{100 - item.rate}}%)
                        </td>
                        <td *ngIf="item.rate >= 100" style="min-width: 5rem">
                            {{item.settleLocation | currency}} (100%)
                        </td>
                        <td style="min-width: 6rem">
                            {{item.settleDigital | currency}} ({{item.rate}}%)
                        </td>
                        <td style="min-width: 6rem">
                            {{item.settlePromo | currency}} ({{item.rate}}%)
                        </td>
                        <td *ngIf="showNetCol()" style="min-width: 6rem">
                            <ng-container>
                            {{item.settleGame | currency}} ({{item.rate}}%)
                            </ng-container>

                            <!--<ng-container *ngIf="!isGrossNet(item.earning) || (!isUserRoleLocation() && !isUserRoleOperator() && !isUserRoleDistributor())">-->
                                <!--{{item.settleGame | currency}} ({{item.rate}}%)-->
                            <!--</ng-container>-->
                            <!--<ng-container *ngIf="isGrossNet(item.earning) && (isUserRoleLocation() || isUserRoleOperator() || isUserRoleDistributor())">-->
                                <!-- - -->
                            <!--</ng-container>-->

                        </td>
                        <td>
                            {{settleName(item.settle)}} {{settle(item.settle)  | currency}}
                        </td>
                        <td style="width: 5rem">
                            <button type="button" class="reports-settle-button"
                                    (click)="onShowDetails(item)">
                                Details
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div class="history-table-wrapper" *ngIf="isUserRoleLocation()">
                <table class="history-table">
                    <thead class="history-table__head">
                    <tr>
                        <th style="min-width: 5rem; max-width: 5rem"></th>
                        <th style="min-width: 6rem; text-align: center">Date</th>
                        <th style="min-width: 6rem; text-align: center">Cash In</th>
                        <th style="min-width: 6rem; text-align: center">Cash Out</th>
                        <th style="min-width: 6rem; text-align: center">Gross</th>
                        <th style="min-width: 6rem; text-align: center">Digital In</th>
                        <th style="min-width: 6rem; text-align: center">Digital Out</th>
                        <th style="min-width: 6rem; text-align: center">Gross</th>
                    </tr>
                    </thead>
                    <tbody class="history-table__body">
                    <tr *ngFor="let item of weekLocation">
                        <td style="min-width: 5rem; max-width: 5rem">{{item.day}}</td>
                        <td style="min-width: 6rem">{{item.date}}</td>
                        <td style="min-width: 6rem">
                            {{item.cashIn | currency}}
                        </td>
                        <td style="min-width: 6rem">
                            {{item.cashOut | currency}}
                        </td>
                        <td style="min-width: 6rem">
                            {{item.cashGross | currency}}
                        </td>
                        <td>
                            {{item.digitalIn | currency}}
                        </td>
                        <td style="min-width: 6rem">
                            {{item.digitalOut | currency}}
                        </td>
                        <td style="min-width: 6rem">
                            {{item.digitalGross | currency}}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<app-popup-reports-table [show]="popupReportsTable.show"
                         [info]="popupReportsTable.info"
                         (onClose)="closePopupReportsTable()"></app-popup-reports-table>

<app-popup-reports-details [show]="popupReportsDetails.show"
                           [info]="popupReportsDetails.info"
                           (onClose)="closePopupReportsDetails()"></app-popup-reports-details>
