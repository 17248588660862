<div class="page page--scrolled" [class.page--blured]="showPopupAddStripe || showPopupAddPaypal || showPopupAddSquare">
    <div class="page__inner">
        <h1 class="page__title">Settings</h1>
        <div class="popup-details">
            <div class="popup-details__controls">
                <button class="popup-details__button js-tab-btn is-active"
                        [attr.data-id]="tabs.stripe"
                        (click)="showTab(tabs.stripe)">Stripe</button>
                <button class="popup-details__button js-tab-btn"
                        [attr.data-id]="tabs.square"
                        (click)="showTab(tabs.square)">Square</button>
                <button class="popup-details__button js-tab-btn"
                        [attr.data-id]="tabs.paypal"
                        (click)="showTab(tabs.paypal)">Paypal</button>
                <button class="popup-details__button js-tab-btn"
                        [attr.data-id]="tabs.paynote"
                        (click)="showTab(tabs.paynote)">Paynote</button>
                <!--<button class="popup-details__button js-tab-btn"-->
                        <!--[attr.data-id]="tabs.contact"-->
                        <!--(click)="showTab(tabs.contact)">Contact</button>-->
            </div>
            <button class="button snake-btn is-notouched sell-header__button sell-header__create-btn"
                    *ngIf="activeTab !== tabs.contact"
                    (click)="onShowPopupAdd()">
                Add
                <svg><rect></rect></svg>
            </button>
        </div>

        <div class="page__body page__body--stripe js-tab is-active"
             [attr.data-id]="1">
            <div class="history-table-wrapper">
                <table class="history-table">
                    <thead class="history-table__head">
                        <tr class="js-history-item">
                            <th>Name</th>
                            <th>Last</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody class="history-table__body">
                        <tr *ngFor="let item of stripeData" class="js-stripe-item">
                            <td>{{item.name}}</td>
                            <td>{{item.secret_key_last}}</td>
                            <td>
                                <button type="button" class="reports-settle-button"
                                        [disabled]="isDisable(item.status)"
                                        (click)="onDisableItem(item)">
                                    <ng-container *ngIf="!isDisable(item.status)">
                                        Disable
                                    </ng-container>
                                    <ng-container *ngIf="isDisable(item.status)">
                                        Disabled
                                    </ng-container>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="page__body page__body--square js-tab"
             [attr.data-id]="2">
            <div class="history-table-wrapper">
                <table class="history-table">
                    <thead class="history-table__head">
                    <tr class="js-history-item">
                        <th>Name</th>
                        <th>Last</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody class="history-table__body">
                    <tr *ngFor="let item of squareData" class="js-square-item">
                        <td>{{item.name}}</td>
                        <td>{{item.aplication_id_last}}</td>
                        <td>
                            <button type="button" class="reports-settle-button"
                                    [disabled]="isDisable(item.status)"
                                    (click)="onDisableItem(item)">
                                <ng-container *ngIf="!isDisable(item.status)">
                                    Disable
                                </ng-container>
                                <ng-container *ngIf="isDisable(item.status)">
                                    Disabled
                                </ng-container>
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="page__body page__back--paypal js-tab"
             [attr.data-id]="3">
            <div class="history-table-wrapper">
                <table class="history-table">
                    <thead class="history-table__head">
                    <tr class="js-history-item">
                        <th>Name</th>
                        <th>Last</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody class="history-table__body">
                    <tr *ngFor="let item of paypalData" class="js-paypal-item">
                        <td>{{item.name}}</td>
                        <td>{{item.client_id_last}}</td>
                        <td>
                            <button type="button" class="reports-settle-button"
                                    [disabled]="isDisable(item.status)"
                                    (click)="onDisableItem(item)">
                                <ng-container *ngIf="!isDisable(item.status)">
                                    Disable
                                </ng-container>
                                <ng-container *ngIf="isDisable(item.status)">
                                    Disabled
                                </ng-container>
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="page__body page__back--paypal js-tab"
             [attr.data-id]="4">
            <form [formGroup]="contactForm"
                  (ngSubmit)="onSubmitContactForm()"
                  class="form-simple form-simple--primary form-contact">
                <div class="form-simple__item">
                    <input formControlName="email"
                           placeholder="Email"
                           [email]="true"
                           [type]="'email'"
                           class="form-simple__input"
                           autocomplete="off">
                </div>

                <div class="form-simple__btns">
                    <button class="button snake-btn is-notouched"
                            [disabled]="contactForm.invalid">
                        Save
                        <svg><rect></rect></svg>
                    </button>
                </div>
            </form>
        </div>
        <div class="page__body page__body--stripe js-tab"
             [attr.data-id]="5">
            <div class="history-table-wrapper">
                <table class="history-table">
                    <thead class="history-table__head">
                    <tr class="js-history-item">
                        <th>Name</th>
                        <th>Last</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody class="history-table__body">
                    <tr *ngFor="let item of paynoteData" class="js-stripe-item">
                        <td>{{item.name}}</td>
                        <td>{{item.private_token_last}}</td>
                        <td>
                            <button type="button" class="reports-settle-button"
                                    [disabled]="isDisable(item.status)"
                                    (click)="onDisableItem(item)">
                                <ng-container *ngIf="!isDisable(item.status)">
                                    Disable
                                </ng-container>
                                <ng-container *ngIf="isDisable(item.status)">
                                    Disabled
                                </ng-container>
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<app-popup-settings-stripe [show]="showPopupAddStripe"
                           (onClose)="onClosePopupAdd($event)"></app-popup-settings-stripe>

<app-popup-settings-square [show]="showPopupAddSquare"
                           (onClose)="onClosePopupAdd($event)"></app-popup-settings-square>

<app-popup-settings-paypal [show]="showPopupAddPaypal"
                           (onClose)="onClosePopupAdd($event)"></app-popup-settings-paypal>

<app-popup-settings-paynote [show]="showPopupAddPaynote"
                           (onClose)="onClosePopupAdd($event)"></app-popup-settings-paynote>
