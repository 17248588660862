import { USER_ROLES_CONFIG } from "../config/user-roles.config";
import * as i0 from "@angular/core";
var UserRoleService = /** @class */ (function () {
    function UserRoleService() {
        this.USER_ROLES = USER_ROLES_CONFIG;
        this.userRoleSessionKey = 'userRole';
        this.storage = sessionStorage;
    }
    UserRoleService.prototype.setUserRole = function (role) {
        this.storage.setItem(this.userRoleSessionKey, role);
    };
    UserRoleService.prototype.getUserRole = function () {
        return this.storage.getItem(this.userRoleSessionKey);
    };
    UserRoleService.prototype.removeUserRole = function () {
        this.storage.removeItem(this.userRoleSessionKey);
    };
    UserRoleService.prototype.isUserRoleNotLocation = function () {
        return +this.getUserRole() !== this.USER_ROLES.location;
    };
    UserRoleService.prototype.isUserRoleLocation = function () {
        return +this.getUserRole() === this.USER_ROLES.location;
    };
    UserRoleService.prototype.isUserRoleCashier = function () {
        return +this.getUserRole() === this.USER_ROLES.cashier;
    };
    UserRoleService.prototype.isUserRoleAdmin = function () {
        return +this.getUserRole() === this.USER_ROLES.admin;
    };
    UserRoleService.prototype.isUserRoleDistributor = function () {
        return +this.getUserRole() === this.USER_ROLES.distributor;
    };
    UserRoleService.prototype.isUserRoleOperator = function () {
        return +this.getUserRole() === this.USER_ROLES.operator;
    };
    UserRoleService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserRoleService_Factory() { return new UserRoleService(); }, token: UserRoleService, providedIn: "root" });
    return UserRoleService;
}());
export { UserRoleService };
