import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { map } from 'rxjs/operators';
import { AuthConfig } from './auth-config';
import { AUTH_CONFIG } from '../../../config/api';
import * as i0 from "@angular/core";
import * as i1 from "./auth-config";
import * as i2 from "@angular/common/http";
import * as i3 from "../../../service/userRole.service";
import * as i4 from "../user-idle/user-idle.service";
import * as i5 from "@angular/router";
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(authConfig, http, userRoleService, userIdle, router) {
        this.authConfig = authConfig;
        this.http = http;
        this.userRoleService = userRoleService;
        this.userIdle = userIdle;
        this.router = router;
        this.config = AUTH_CONFIG;
        this.storage = sessionStorage;
        this.rolesObservable = new EventEmitter();
        this.userLoggedIn = new EventEmitter();
        this.partnerTypeKey = 'partnerType';
    }
    AuthenticationService.prototype.isUserLogged = function () {
        return !!this.storage.getItem('currentUser');
    };
    AuthenticationService.prototype.register = function (name, email, phone, zip, requests) {
        return this.http.post(this.config.register, { name: name, email: email, phone: phone, zip: zip, requests: requests }).pipe(map(function (res) {
            if (res.status) {
                //this.setData(res.result);
            }
            return res;
        }));
    };
    AuthenticationService.prototype.login = function (userName, password) {
        var _this = this;
        this.storage = sessionStorage;
        return this.http.post(this.config.login, { userName: userName, password: password })
            .pipe(map(function (res) {
            // if (environment.frontEndTest) {
            //     res.result.role_id = 7;
            // }
            if (res.status) {
                _this.setPartnerType(res.partnerType);
                _this.setData(res.result);
                _this.userRoleService.setUserRole(res.result.role_id);
                _this.userLoggedIn.emit(true);
            }
            return res;
        }));
    };
    AuthenticationService.prototype.setData = function (result) {
        this.storage = sessionStorage;
        this.storage.setItem('currentUser', JSON.stringify(result));
        //this.roles = [];
        //this.roles = [result.role];
        //this.rolesObservable.next(this.roles);
    };
    AuthenticationService.prototype.setPartnerType = function (type) {
        this.storage = sessionStorage;
        this.storage.setItem(this.partnerTypeKey, type);
    };
    AuthenticationService.prototype.getPartnerType = function () {
        this.storage = sessionStorage;
        return this.storage.getItem(this.partnerTypeKey);
    };
    AuthenticationService.prototype.removePartnerType = function () {
        this.storage = sessionStorage;
        this.storage.removeItem(this.partnerTypeKey);
    };
    AuthenticationService.prototype.logout = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.storage.removeItem('currentUser');
                        this.removePartnerType();
                        return [4 /*yield*/, this.checkSession()];
                    case 1:
                        _a.sent();
                        this.userLoggedIn.emit(false);
                        return [2 /*return*/];
                }
            });
        });
    };
    AuthenticationService.prototype.setStamp = function () {
        var now = Date.now();
        this.authStamp = now;
        var $this = this;
        setTimeout(function () {
            $this.setStamp();
        }, 1000);
    };
    AuthenticationService.prototype.checkStamp = function () {
        if (this.storage.getItem('currentUser')) {
            var now = Date.now();
            if (this.authStamp) {
                if (now > (this.authStamp + 3000000)) {
                    this.logout();
                    this.router.navigate(['/']);
                    return;
                }
                else {
                    this.refreshTokenNow();
                }
            }
            else {
                this.refreshTokenNow();
            }
        }
    };
    AuthenticationService.prototype.refreshTokenNow = function () {
        var _this = this;
        if (this.storage.getItem('currentUser')) {
            this.http.post(this.config.refreshToken, {}).subscribe(function (res) {
                if (res.status) {
                    _this.setData(res.result);
                }
                else {
                    _this.router.navigate(['/']);
                }
                return res;
            });
        }
        return false;
    };
    AuthenticationService.prototype.refreshToken = function () {
        var $this = this;
        setTimeout(function () {
            $this.refreshToken();
        }, 60 * 5 * 1000);
        this.refreshTokenNow();
    };
    AuthenticationService.prototype.checkSession = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/];
            });
        });
    };
    AuthenticationService.prototype.getCurrentUser = function () {
        if (sessionStorage.getItem('currentUser')) {
            try {
                var currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
                return currentUser;
            }
            catch (e) {
            }
        }
        return false;
    };
    AuthenticationService.prototype.getToken = function () {
        this.storage = sessionStorage;
        if (this.storage.getItem('currentUser')) {
            try {
                var currentUser = JSON.parse(this.storage.getItem('currentUser'));
                return currentUser.token;
            }
            catch (e) {
            }
        }
        return '';
    };
    AuthenticationService.prototype.getPointCountryId = function () {
        this.storage = sessionStorage;
        if (this.storage.getItem('currentUser')) {
            try {
                var currentUser = JSON.parse(this.storage.getItem('currentUser'));
                if (currentUser.point_country_id) {
                    return currentUser.point_country_id;
                }
            }
            catch (e) {
            }
        }
        return '';
    };
    AuthenticationService.prototype.getGameVendorShortName = function () {
        this.storage = sessionStorage;
        if (this.storage.getItem('currentUser')) {
            try {
                var currentUser = JSON.parse(this.storage.getItem('currentUser'));
                if (currentUser.game_vendor_short_name) {
                    return currentUser.game_vendor_short_name;
                }
            }
            catch (e) { }
        }
        return '';
    };
    AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.AuthConfig), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.UserRoleService), i0.ɵɵinject(i4.UserIdleService), i0.ɵɵinject(i5.Router)); }, token: AuthenticationService, providedIn: "root" });
    return AuthenticationService;
}());
export { AuthenticationService };
