import { CASH_REGISTER_CONFIG } from "../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./loader.service";
var CashRegisterService = /** @class */ (function () {
    function CashRegisterService(http, loaderService) {
        this.http = http;
        this.loaderService = loaderService;
        this.config = CASH_REGISTER_CONFIG;
    }
    CashRegisterService.prototype.getPageInfo = function (page) {
        this.loaderService.show();
        return this.http.post(this.config.getPageInfo, { page: page });
    };
    CashRegisterService.prototype.open = function (amount) {
        this.loaderService.show();
        return this.http.post(this.config.open, { amount: amount });
    };
    CashRegisterService.prototype.close = function () {
        this.loaderService.show();
        return this.http.post(this.config.close, {});
    };
    CashRegisterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CashRegisterService_Factory() { return new CashRegisterService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: CashRegisterService, providedIn: "root" });
    return CashRegisterService;
}());
export { CashRegisterService };
