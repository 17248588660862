import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-popup-reports-details',
    templateUrl: './popup-reports-details.component.html',
    styleUrls: ['./popup-reports-details.component.scss']
})
export class PopupReportsDetailsComponent implements OnInit {
    @Input() show: boolean;
    @Input() info: any;
    @Output('onClose') emitClose: EventEmitter<any> = new EventEmitter();

    overviewDigital = 0;
    overviewCash = 0;
    overviewProviders = 0;
    overviewPromo = 0;
    overviewChecks = 0;
    net = 0;
    grossNet = 0;
    earning = 0;

    digital;
    cash;
    commission;
    check;
    providers;
    providersGames = [];
    customer;

    constructor() {

    }

    ngOnInit() {
    }

    ngOnChanges(changes) {
        this.setData();
    }

    setData() {
        if (this.info) {
            this.overviewDigital = this.info.digital && this.info.digital.total ? this.info.digital.total : 0;
            this.overviewCash = this.info.cash && this.info.cash.total ? this.info.cash.total : 0;
            this.overviewProviders = this.info.providers && this.info.providers.total ? this.info.providers.total : 0;
            this.overviewPromo = this.info.customer && this.info.customer.total ? this.info.customer.total : 0;
            this.overviewChecks = this.info.checks && this.info.checks.total ? this.info.checks.total : 0;

            this.digital = this.info.digital;
            this.cash = this.info.cash;
            this.commission = +this.info.commission;
            this.check = this.info.checks;
            this.earning = +this.info.earning;
            this.customer = this.info.customer;
            this.providers = this.info.providers;
            this.providersGames = this.info.providers && this.info.providers.games ? Object.entries(this.providers.games) : [];
            this.net = this.info.net ? this.info.net : 0;
            this.grossNet = this.info.grossNet ? this.info.grossNet : 0;
        } else {
            this.net = 0;
            this.grossNet = 0;
            this.earning = 0;
            this.commission = 0;

            this.digital = {};
            this.cash = {};
            this.check = {};
            this.customer = {};
            this.providers = {};

            this.overviewDigital = 0;
            this.overviewCash = 0;
            this.overviewProviders = 0;
            this.overviewPromo = 0;
            this.overviewChecks = 0;

            this.providersGames = [];
        }
    }

    closePopup() {
        this.emitClose.emit();
    }

    showTab(id) {
        document.querySelectorAll('.js-tab').forEach(item => {
            if (+item.getAttribute('data-id') === id) {
                item.classList.add('is-active');
            } else {
                item.classList.remove('is-active');
            }

        });
        document.querySelectorAll('.js-tab-btn').forEach(item => {
            if (+item.getAttribute('data-id') === id) {
                item.classList.add('is-active');
            } else {
                item.classList.remove('is-active');
            }
        });
        document.body.querySelector('#details-table').scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }

    getAbs(num): number {
        return Math.abs(+num);
    }
}
