<app-popup-wrapper [show]="show" (onClose)="closePopup()">
    <form [formGroup]="popupForm" (ngSubmit)="onSubmit()" class="form-simple form-simple--primary">
        <div class="form-simple__item">
            <input formControlName="name"
                   placeholder="Name"
                   [type]="'text'"
                   class="form-simple__input"
                   autocomplete="off">
            <span class="form-simple__ad-info">Name</span>
        </div>

        <div class="form-simple__item">
            <input formControlName="location"
                   placeholder="Location"
                   [type]="'text'"
                   disabled="disabled"
                   class="form-simple__input"
                   autocomplete="off">
            <span class="form-simple__ad-info">Location</span>
        </div>

        <div class="form-simple__item">
            <input formControlName="teamViewerLogin"
                   placeholder="TeamViewer Login"
                   [type]="'text'"
                   class="form-simple__input"
                   autocomplete="off">
            <span class="form-simple__ad-info">TeamViewer Login</span>
        </div>

        <div class="form-simple__item">
            <input formControlName="teamViewerPassword"
                   placeholder="TeamViewer Password"
                   [type]="'text'"
                   class="form-simple__input"
                   autocomplete="off">
            <span class="form-simple__ad-info">TeamViewer Password</span>
        </div>

        <div style="min-width: 9rem; margin-top: 1rem; margin-bottom: 1rem;">
            <input formControlName="status"
                   id="status"
                   [type]="'checkbox'"
                   class="form-simple__radio"
                   [value]="status"
                   name="cashPayoutLimit"
                   [checked]="!!status"
                   (change)="onStatusChange()"
                   autocomplete="off">
            <label [for]="'status'">Active Status</label>
        </div>

        <div style="min-width: 9rem; margin-top: 1rem; margin-bottom: 2rem;">
            <input formControlName="keyboard"
                   id="keyboard"
                   [type]="'checkbox'"
                   class="form-simple__radio"
                   [value]="keyboard"
                   name="cashPayoutLimit"
                   [checked]="!!keyboard"
                   (change)="onKeyboardChange()"
                   autocomplete="off">
            <label [for]="'keyboard'">Keyboard</label>
        </div>

        <div class="form-simple__btns">
            <button class="button snake-btn is-notouched"
                    [disabled]="popupForm.invalid">
                Update
                <svg><rect></rect></svg>
            </button>
        </div>
    </form>
</app-popup-wrapper>



