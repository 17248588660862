export const USER_ROLES_CONFIG = {
    admin: 1,
    developer: 2,
    customers: 3,
    manager: 4,
    cashier: 5,
    distributor: 6,
    operator: 7,
    location: 8
};

export const USER_ROLES_SHORT_NAME_CONFIG = {
    admin: 'admin',
    developer: 'developer',
    customers: 'customers',
    manager: 'manager',
    cashier: 'cashier',
    distributor: 'distributor',
    operator: 'operator',
    ownerLocation: 'owner_location'
};

export const USERS_NAMES_BY_SHORT_NAME_CONFIG = {
    'admin': 'Admin',
    'developer': 'Developer',
    'customers': 'Customers',
    'manager': 'Manager',
    'cashier': 'Cashier',
    'distributor': 'Distributor',
    'operator': 'Operator',
    'owner_location': 'Owner'
};

// 2 => Developer
// 3 => Customers
// 4 => Manager
// 5 => Cashier
