import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from "../../modules/auth/_services/authentication.service";
import {Router} from "@angular/router";
import {AppVersionrService} from "../../service/app-version/appVersionr.service";
import {BlurMenuService} from "../../service/blurMenu.service";
import {TemplateService} from "../../service/template.service";
import {RouterPaths} from "../../config/router-paths.model";
import {UserRoleService} from "../../service/userRole.service";

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    menu = false;
    menuBlurred = false;

    ROUTER_PATHS = RouterPaths;

    constructor(
        private auth:AuthenticationService,
        private appVersionrService: AppVersionrService,
        private blurMenuService: BlurMenuService,
        private router: Router,
        private userRoleService: UserRoleService
    ) {
        this.blurMenuService.menuBlurred.subscribe(value => this.blurMenuChanged(value));

    }


    ngOnInit() {

    }

    logout(){
        this.auth.logout();

        this.router.navigate(['/']);
    }

    showMenu() {
        this.menu = !this.menu;
    }

    upgradeApp(){
        this.appVersionrService.upgradeApp();
    }

    blurMenuChanged(value) {
        this.menuBlurred = value;
    }

    goToTermsAndConditions() {
        this.router.navigate([this.ROUTER_PATHS.termsAndConditions.main]);
        this.showMenu();
    }

    getHomeLink() {
        return  this.userRoleService.isUserRoleLocation() || this.userRoleService.isUserRoleCashier() ? RouterPaths.sell.locationNav : RouterPaths.home.admin;
    }

    isUserRoleLocation() {
        return  this.userRoleService.isUserRoleLocation()
    }

    isUserRoleCashier() {
        return  this.userRoleService.isUserRoleCashier()
    }

    isUserRoleAdmin() {
        return  this.userRoleService.isUserRoleAdmin();
    }

    isUserRoleAdminOrOperator() {
        return  this.userRoleService.isUserRoleAdmin() || this.userRoleService.isUserRoleOperator();
    }

    getReportsLink() {
        return  RouterPaths.reports.main;
    }

    getTicketsLink() {
        return  RouterPaths.tickets.main;
    }

    goToTerminalPage(){
        this.router.navigate([this.ROUTER_PATHS.terminal.main]);
        this.showMenu();
    }

    goToUsersPage() {
        this.router.navigate([this.ROUTER_PATHS.users.main]);
        this.showMenu();
    }
}
