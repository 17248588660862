import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {MainComponent} from './main/main.component';
import {LoginComponent} from "./login/login.component";
import {SignUpComponent} from "./signup/signup.component";

import {AuthGuard} from '../modules/auth/_guards/auth.guard';
import {SellComponent} from "./sell/sell.component";
import {NewUserComponent} from "./sell/new-user/new-user.component";
import {ExistingUserComponent} from "./sell/existing-user/existing-user.component";
import {ExistingUserInfoComponent} from "./sell/existing-user-info/existing-user-info.component";
import {TermsAndConditionsComponent} from "./terms-and-conditions/terms-and-conditions.component";
import {ReportsComponent} from "./reports/reports.component";
import {SellLocationNavComponent} from "./sell-location-nav/sell-location-nav.component";
import {SettingsComponent} from "./settings/settings.component";
import {TicketsComponent} from "./tickets/tickets.component";
import {TerminalComponent} from "./terminal/terminal.component";
import {UsersComponent} from "./users/users.component";
import {CashRegisterComponent} from "./cash-register/cash-register.component";

const routes: Routes = [
    {
        path: '', component: MainComponent, children: [
            {path: '', component: LoginComponent},
            {path: 'login', component: LoginComponent},
            {path: 'signup', component: SignUpComponent},
            {path: 'home', component: HomeComponent, canActivate: [AuthGuard], children: [
            // {path: 'home', component: HomeComponent, children: [
                    {path: 'sell', component: SellComponent},
                    {path: 'sell/sell-location-nav', component: SellLocationNavComponent},
                    {path: 'terms-and-conditions', component: TermsAndConditionsComponent},
                    {path: 'sell/new-user', component: NewUserComponent},
                    {path: 'sell/existing-user', component: ExistingUserComponent},
                    {path: 'sell/existing-user/existing-user-info/:id', component: ExistingUserInfoComponent},
                    {path: 'reports', component: ReportsComponent},
                    {path: 'settings', component: SettingsComponent},
                    {path: 'tickets', component: TicketsComponent},
                    {path: 'terminal', component: TerminalComponent},
                    {path: 'users', component: UsersComponent},
                    {path: 'cash-register', component: CashRegisterComponent}
                ]},
            {path: '**', redirectTo: 'login', pathMatch: 'full'}
        ]
    }
];



@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PagesModule {
}
