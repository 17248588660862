import {Component, ElementRef, HostListener, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {slideToLeft} from "../../animations/router.animations";
import {ReportsService} from "../../service/reports/reports.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ExampleHeader} from "../../shared/datapicker-header/datapicker-header";
import {PopupComponent} from "../../shared/popup/popup.component";
import {LoaderService} from "../../service/loader.service";
import {DatesService} from "../../service/dates.service";
import {PaginationDataModel, SetPaginationDataModel} from "../../models/scrollPagination/paginationData.model";
import {PaginationData} from "../../models/scrollPagination/paginationData";
import {PaginationScrollService} from "../../service/pageScroll/paginationScroll.service";
import {BlurMenuService} from "../../service/blurMenu.service";
import {UserRoleService} from "../../service/userRole.service";
import {MatDatepicker} from "@angular/material";

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class ReportsComponent implements OnInit {
    reportsForm: FormGroup;
    exampleHeader = ExampleHeader;

    historyData: PaginationDataModel = new PaginationData('history', '.js-history-item', '.page__body');
    weekLocation = [];
    popupReportsTable = {
      show: false,
      info: {
        type: null,
        id: null
      }
    };

    popupReportsDetails = {
      show: false,
      info: {}
    };

    selectedDate;
    selectedMonday;
    selectedSunday;
    selectedMonMonth;
    selectedSunMonth;
    selectedMonYear;
    selectedSunYear;

    @ViewChild('picker',{static:false}) calendar: MatDatepicker<any>;
    constructor(private reportsService: ReportsService,
                private popupComponent: PopupComponent,
                private loaderService: LoaderService,
                private blurMenuService: BlurMenuService,
                private userRoleService: UserRoleService,
                private paginationScrollService: PaginationScrollService,
                private datesService: DatesService,
                private formBuilder: FormBuilder
                ) {
        this.reportsForm = this.formBuilder.group({
           date: [new Date()],
        });
    }

  ngOnInit() {
      setTimeout(() => {
        this.updateSelectedPeriod(new Date());
      });
  }

  setClass() {
    return (date: any) => {
      if (date.getDate() == 1) this.changeMonth(
        {month:date.getMonth()+1,
          year:date.getFullYear()
        });
    };
  }

  changeMonth(date) {
    if (date.month === this.selectedSunMonth || date.month === this.selectedMonMonth) {
      this.openDatepicker(date.month);
    }
  }

  isIos(){
      let userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone/.test( userAgent );
      // return /iphone|ipad|ipod/.test( userAgent );
  }

  getPageInfo() {
    // const from = this.getFromDate();
    // const to = this.getToDate();
    const date = this.getSelectedDate();

      this.reportsService.getPageInfo(1, date).subscribe(data => {
          this.setPageInfo(data);
            }, () => {
            this.popupComponent.showPopupTryAgain();
        }, () => {
            this.loaderService.hide();
      });
  }

  getFromDate() {
    return `${this.selectedMonMonth}/${this.selectedMonday}/${this.selectedMonYear}`;
  }

  getToDate() {
    return `${this.selectedSunMonth}/${this.selectedSunday}/${this.selectedSunYear}`;
  }

  getSelectedDate() {
    return (<HTMLInputElement>document.querySelector('[formcontrolname=date]')).value;
  }

  setPageInfo(data) {
      try {
          if (data.status) {
            this.paginationScrollService.resetPagination(this.historyData);

            const historyDataSettings: SetPaginationDataModel = {
              itemsList: data.result,
              scrollLimit: data.limit,
              total: data.total
            };
            this.initScrolledData(this.historyData, historyDataSettings);

            if (this.isUserRoleLocation()) {
              this.weekLocation = data.week;
            } else {
              this.weekLocation = [];
            }
          } else if (data.status === false) {

          } else {
              this.popupComponent.showPopupTryAgain();
          }
      } catch (e) {
          this.popupComponent.showPopupTryAgain();
      }
  }

  onSubmit() {
    this.getPageInfo();
  }

  onSettle(id?) {
    this.blurMenuService.blur();
    if (id) {
      this.popupReportsTable = {
        show: true,
        info: {
          type: 'single',
          id
        }
      }
    } else {
      this.popupReportsTable = {
        show: true,
        info: {
          type: 'common',
          id: null
        }
      }
    }
  }

  closePopupReportsTable() {
    this.blurMenuService.focus();
    this.popupReportsTable = {
      show: false,
      info: {
        type: null,
        id: null
      }
    }
  }

  isUserRoleLocation():boolean {
      return this.userRoleService.isUserRoleLocation() || this.userRoleService.isUserRoleCashier();
  }

  isUserRoleOperator():boolean {
    return this.userRoleService.isUserRoleOperator();
  }

  isUserRoleDistributor():boolean {
    return this.userRoleService.isUserRoleDistributor();
  }

  isUserRoleAdmin():boolean {
    return this.userRoleService.isUserRoleAdmin();
  }

  updateSelectedPeriod(date) {
    this.getSliderDays(date);
    this.getPageInfo();
  }

  getSliderDays(date) {
    const selectedMondayFullDate = this.datesService.getMonday(date);
    const selectedSundayFullDate = this.datesService.getSunday(date);

    this.selectedDate = date;
    this.selectedMonday = selectedMondayFullDate.getDate();
    this.selectedMonMonth = selectedMondayFullDate.getMonth() + 1;
    this.selectedMonYear = selectedMondayFullDate.getFullYear();

    this.selectedSunday = selectedSundayFullDate.getDate();
    this.selectedSunMonth = selectedSundayFullDate.getMonth() + 1;
    this.selectedSunYear = selectedSundayFullDate.getFullYear();
  }

  selectDate($event) {
    this.updateSelectedPeriod($event.value);
  }

  openDatepicker(month?) {
    setTimeout(() => {
      const parent = document.querySelector('.mat-calendar-body-active').parentElement;
      if ((this.selectedMonMonth === this.selectedSunMonth) || !month) {
        parent.querySelectorAll('.mat-calendar-body-cell').forEach(el => {
          el.classList.add('is-selected-week')
        });
      } else {
        if (month) {
          if (month === this.selectedMonMonth) {
            parent.parentElement.lastElementChild.querySelectorAll('.mat-calendar-body-cell').forEach(el => {
              el.classList.add('is-selected-week')
            });
          } else {
            parent.parentElement.firstElementChild.querySelectorAll('.mat-calendar-body-cell').forEach(el => {
              el.classList.add('is-selected-week')
            });
          }
        }
      }
    });

  }

  ///////---SCROLLING PAGINATION FUNCTIONAL---///////
  // DETECTING SCROLL
  scroll(e, settings: PaginationDataModel) {
      if (!this.isUserRoleLocation()) {
        e.stopImmediatePropagation();
        e.stopPropagation();

        this.paginationScrollService.onScroll(e, settings, this.loadNewData.bind(this), true);
      }
  }

  // REQUESTS ON ADDING NEW DATA
  loadNewData(page: number, step = 'end', settings: PaginationDataModel): void {
    if (settings.name ===  'history') {
      // const from = this.getFromDate();
      // const to = this.getToDate();
      const date = this.getSelectedDate();
      this.reportsService.getPageInfo(page, date).subscribe(data => {
          this.paginationScrollService.dataAddingLogic(page, step, data, settings, true)
        },
        () => { this.loaderService.hideAndShowTryAgain() },
        () => { this.loaderService.hide() }
      );
    }
  }

  initScrolledData(paginationDataName: PaginationDataModel, settings: SetPaginationDataModel) {
    paginationDataName = this.paginationScrollService.initScrolledData(paginationDataName, settings);
  }

  getExpenses(item) {
      let result = null;
      if (item) {
        return +item.fee + +item.game + +item.player;
      }
      return result;
  }

  onShowDetails(item) {
    this.blurMenuService.blur();
    this.popupReportsDetails = {
      show: true,
      info: item
    }
  }

  closePopupReportsDetails() {
    this.blurMenuService.focus();
    this.popupReportsDetails = {
      show: false,
      info: {}
    }
  }

  getMinReportsDate() {
    const today = new Date();
    return new Date(today.setMonth(today.getMonth() - 3));
  }

  isGrossNet(earning) {
      return earning && (+earning === 1);
  }

  showNetCol() {
     return !this.isUserRoleLocation() ||
            (this.isUserRoleLocation() && this.historyData.items && this.historyData.items[0] && !this.isGrossNet(this.historyData.items[0].earning));
  }

  showGrossNetCol() {
    return this.isUserRoleOperator() || this.isUserRoleDistributor() ||
          (this.isUserRoleLocation() && this.historyData.items && this.historyData.items[0] && this.isGrossNet(this.historyData.items[0].earning));
  }

    settleName(amount){
        if(this.isUserRoleLocation()){
            if( amount < 0){
                return 'Pay';
            }

            return 'Get';
        }

        if(amount < 0){
            return 'Get';
        }

        return 'Pay';
    }
    settle(amount){
        if(amount < 0){
            amount = amount * -1;
        }

        return amount;
    }
}
