import {Component, OnInit} from '@angular/core';
import {routerTransition, slideToLeft} from "../../../animations/router.animations";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {NewUserService} from "../../../service/sell/newUser.service";
import {formatNumber,formatCurrency} from '@angular/common';
import {LoaderService} from "../../../service/loader.service";
import {PhoneMaskService} from "../../../service/phoneMask.service";
import {ActivatedRoute, Params} from "@angular/router";
import {RouterPaths} from "../../../config/router-paths.model";

@Component({
    selector: 'app-new-user',
    templateUrl: './new-user.component.html',
    styleUrls: ['./new-user.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class NewUserComponent implements OnInit {
    newUserForm: FormGroup;
    phoneMask = this.phoneMaskService.setPhoneMask();

    constructor(private formBuilder: FormBuilder,
                private newUserService: NewUserService,
                private loaderService: LoaderService,
                private phoneMaskService: PhoneMaskService,
                private route: ActivatedRoute,
                private popupComponent: PopupComponent) {

    }

    ngOnInit() {
        this.newUserForm = this.formBuilder.group({
            phone: [null, Validators.required],
            amount: [null]
        });
    }

    onSubmit() {
        const phone = this.newUserForm.controls.phone.value;
        const amount = this.newUserForm.controls.amount.value;

        const text = `${phone} <br><br> Membership/Sweeps: $${formatNumber(amount, 'en-US')}<br>`;

        this.popupComponent.showPopupAgreement({text: text,
            confirmButtonText: 'CONFIRM<svg><rect></rect></svg>',
            cancelButtonText: '<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                              '<path d="M25.5 24.1719L18.3281 17L25.5 9.82812C25.8668 9.46136 25.8668 8.86676 25.5 8.5C25.1332 8.13324 24.5386 8.13324 24.1719 8.5L17 15.6719L9.82813 8.5C9.46136 8.13324 8.86676 8.13324 8.5 8.5C8.13324 8.86676 8.13324 9.46136 8.5 9.82812L15.6719 17L8.5 24.1719C8.13324 24.5386 8.13324 25.1332 8.5 25.5C8.86676 25.8668 9.46136 25.8668 9.82813 25.5L17 18.3281L24.1719 25.5C24.5386 25.8668 25.1332 25.8668 25.5 25.5C25.8668 25.1332 25.8668 24.5386 25.5 24.1719Z" fill="#FDFD88"/>\n' +
                              '</svg>'}, (status) => {
            if (status) {
                this.submit();
            }
        });
    }

    submit() {
        const phone = this.newUserForm.controls.phone.value.replace(/\D+/g, '');
        const amount = this.newUserForm.controls.amount.value ? this.newUserForm.controls.amount.value : 0;

        this.newUserService.sell(phone, amount).subscribe(data => {
            if(data.status){
                this.popupComponent.showPopupSuccess({text:data.message});
                this.newUserForm.reset();
            } else {
                this.popupComponent.showPopupError({text:data.message});
            }
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    getBackLink() {
        return RouterPaths.sell.locationNav;
    }

}
