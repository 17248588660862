import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {LoaderService} from "./loader.service";
import {SETTINGS_CONFIG, TERMINAL_CONFIG} from "../config/api";

@Injectable({
    providedIn: 'root'
})
export class TerminalService {
    private config = TERMINAL_CONFIG;

    constructor(private http: HttpClient,
                private loaderService: LoaderService) {

    }

    getTerminalsList() {
        this.loaderService.show();
        return this.http.post<any>(this.config.getTerminalsList, {});
    }

    getPageInfo(id) {
        this.loaderService.show();
        return this.http.post<any>(this.config.getPageInfo, {id});
    }

    getItemInfo(id) {
        this.loaderService.show();
        return this.http.post<any>(this.config.getItemInfo, {id});
    }

    setItemInfo(id, name, location, status, teamViewerLogin, teamViewerPassword, keyboard) {
        this.loaderService.show();
        return this.http.post<any>(this.config.setItemInfo, {id, name, location, status, teamViewerLogin, teamViewerPassword, keyboard});
    }

}
