import {Component, OnInit} from '@angular/core';
import {slideToLeft} from "../../animations/router.animations";
import {PopupComponent} from "../../shared/popup/popup.component";
import {LoaderService} from "../../service/loader.service";
import {PaginationScrollService} from "../../service/pageScroll/paginationScroll.service";
import {BlurMenuService} from "../../service/blurMenu.service";
import {SettingsService} from "../../service/settings.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class SettingsComponent implements OnInit {
    tabs = {
      stripe: 1,
      square: 2,
      paypal: 3,
      contact: 4,
      paynote: 5
    };

    activeTab = this.tabs.stripe;

    stripeData = [];
    squareData = [];
    paypalData = [];
    paynoteData = [];
    contactData;

    showPopupAddStripe = false;
    showPopupAddSquare = false;
    showPopupAddPaypal = false;
    showPopupAddPaynote = false;

    contactForm: FormGroup;
    constructor(private popupComponent: PopupComponent,
                private loaderService: LoaderService,
                private blurMenuService: BlurMenuService,
                private formBuilder: FormBuilder,
                private paginationScrollService: PaginationScrollService,
                private settingsService: SettingsService) {
      this.contactForm = this.formBuilder.group({
        email: [null, Validators.required]
      });
    }

  ngOnInit() {
    this.getPageInfo();
  }

  getPageInfo() {
    switch (this.activeTab) {
      case this.tabs.stripe:
        this.getStripeInfo();
        break;
      case this.tabs.square:
        this.getSquareInfo();
        break;
      case this.tabs.paypal:
        this.getPaypalInfo();
        break;
      case this.tabs.contact:
        this.getContactInfo();
        break;
      case this.tabs.paynote:
        this.getPaynoteInfo();
        break;
    }
  }

  getStripeInfo() {
    this.settingsService.getStripeInfo().subscribe(data => {
      this.setPageInfo(data);
    }, () => {
      this.popupComponent.showPopupTryAgain();
    }, () => {
      this.loaderService.hide();
    });
  }

  getSquareInfo() {
    this.settingsService.getSquareInfo().subscribe(data => {
      this.setPageInfo(data);
    }, () => {
      this.popupComponent.showPopupTryAgain();
    }, () => {
      this.loaderService.hide();
    });
  }

  getPaypalInfo() {
    this.settingsService.getPaypalInfo().subscribe(data => {
      this.setPageInfo(data);
    }, () => {
      this.popupComponent.showPopupTryAgain();
    }, () => {
      this.loaderService.hide();
    });
  }

  getContactInfo() {
    this.settingsService.getContactInfo().subscribe(data => {
      this.setPageInfo(data);
    }, () => {
      this.popupComponent.showPopupTryAgain();
    }, () => {
      this.loaderService.hide();
    });
  }

  getPaynoteInfo() {
    this.settingsService.getPaynoteInfo().subscribe(data => {
      this.setPageInfo(data);
    }, () => {
      this.popupComponent.showPopupTryAgain();
    }, () => {
      this.loaderService.hide();
    });
  }

  setPageInfo(data) {
      try {
          if (data.status) {
            switch (this.activeTab) {
              case this.tabs.stripe:
                this.stripeData = data.result;
                break;
              case this.tabs.square:
                this.squareData = data.result;
                break;
              case this.tabs.paypal:
                this.paypalData = data.result;
                break;
              case this.tabs.paynote:
                this.paynoteData = data.result;
                break;
              case this.tabs.contact:
                this.contactData = data.email;
                this.contactForm.reset();
                if (this.contactData) {
                  this.contactForm.controls.email.setValue(this.contactData)
                }
                break;
            }
          } else if (data.status === false) {
              //this.popupComponent.showPopupError({text: data.message})
          } else {
              this.popupComponent.showPopupTryAgain();
          }
      } catch (e) {
          this.popupComponent.showPopupTryAgain();
      }
  }

  onShowPopupAdd() {
    this.blurMenuService.blur();

    switch (this.activeTab) {
      case this.tabs.stripe:
        this.showPopupAddStripe = true;
        break;
      case this.tabs.square:
        this.showPopupAddSquare = true;
        break;
      case this.tabs.paypal:
        this.showPopupAddPaypal = true;
        break;
      case this.tabs.paynote:
        this.showPopupAddPaynote = true;
        break;
    }
  }

  onClosePopupAdd(needUpdate) {
    this.blurMenuService.focus();
    this.showPopupAddSquare = false;
    this.showPopupAddStripe = false;
    this.showPopupAddPaypal = false;
    this.showPopupAddPaynote = false;
    if (needUpdate) {
      this.getPageInfo();
    }
  }


  showTab(id) {
    document.querySelectorAll('.js-tab').forEach(item => {
      if (+item.getAttribute('data-id') === id) {
        item.classList.add('is-active');
      } else {
        item.classList.remove('is-active');
      }

    });
    document.querySelectorAll('.js-tab-btn').forEach(item => {
      if (+item.getAttribute('data-id') === id) {
        item.classList.add('is-active');
      } else {
        item.classList.remove('is-active');
      }
    });
    this.activeTab = id;
    this.getPageInfo();
  }

  onDisableItem(item) {
      const text = `Are you sure you want to disable ${item.name}?`;
      const id = item.id;
      this.popupComponent.showPopupAgreement({text: text}, (result) => {
        if (result) {
          switch (this.activeTab) {
            case this.tabs.stripe:
              this.onDisableStripeItem(id);
              break;
            case this.tabs.square:
              this.onDisableSquareItem(id);
              break;
            case this.tabs.paypal:
              this.onDisablePaypalItem(id);
              break;
            case this.tabs.paynote:
              this.onDisablePaynoteItem(id);
              break;
          }
        }
      });
  }

  onDisableStripeItem(id) {
      this.settingsService.disableStripeItem(id).subscribe(data => {
        this.handleDisableResult(data);
      }, () => {
        this.loaderService.hideAndShowTryAgain();
      }, () => {
        this.loaderService.hide();
      });
  }

  onDisableSquareItem(id) {
    this.settingsService.disableSquareItem(id).subscribe(data => {
      this.handleDisableResult(data);
    }, () => {
      this.loaderService.hideAndShowTryAgain();
    }, () => {
      this.loaderService.hide();
    });
  }

  onDisablePaypalItem(id) {
    this.settingsService.disablePaypalItem(id).subscribe(data => {
      this.handleDisableResult(data);
    }, () => {
      this.loaderService.hideAndShowTryAgain();
    }, () => {
      this.loaderService.hide();
    });
  }

  onDisablePaynoteItem(id) {
    this.settingsService.disablePaynoteItem(id).subscribe(data => {
      this.handleDisableResult(data);
    }, () => {
      this.loaderService.hideAndShowTryAgain();
    }, () => {
      this.loaderService.hide();
    });
  }

  handleDisableResult(data) {
      try {
        if (data.status) {
          this.popupComponent.showPopupSuccess({text: data.message});
          this.getPageInfo();
        } else {
          this.popupComponent.showPopupError({text: data.message});
        }
      } catch (e) {
        this.loaderService.hideAndShowTryAgain();
      }
  }

  isDisable(item){
      if(item == 2){
        return true;
      }else{
        return false;
      }
  }

  onSubmitContactForm() {
      const email = this.contactForm.controls.email.value;
      this.settingsService.saveContact(email).subscribe(data => {
        this.saveContactResult(data);
      }, () => {
        this.loaderService.hideAndShowTryAgain();
      }, () => {
        this.loaderService.hide();
      });
  }

  saveContactResult(data) {
      try {
        if (data.status) {
          this.popupComponent.showPopupSuccess({text: data.message});
        } else {
          this.popupComponent.showPopupError({text: data.message});
        }
      } catch (e) {
        this.loaderService.hideAndShowTryAgain();
      }
  }
}
