import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {PROJECT_MESSAGES} from "../../../config/project";
import {LoaderService} from "../../../service/loader.service";
import {UsersService} from "../../../service/users.service";

@Component({
    selector: 'app-popup-users-settings',
    templateUrl: './popup-users-settings.component.html',
    styleUrls: ['./popup-users-settings.component.scss']
})
export class PopupUsersSettingsComponent implements OnInit {
    @Input() show: boolean;
    @Input() isEditing: boolean;
    @Input() id: any;
    @Output('onClose') emitClose: EventEmitter<any> = new EventEmitter();

    popupForm: FormGroup;

    constructor(private formBuilder: FormBuilder,
                private popupComponent: PopupComponent,
                private loaderService: LoaderService,
                private usersService: UsersService) {
        this.popupForm = this.formBuilder.group({
          name: [null, Validators.required],
          password: [null, Validators.required],
          repeatPassword: [null, Validators.required]
        });
    }

    ngOnInit() {}

    ngOnChanges(changes) {
        if (this.popupForm) {
            this.popupForm.reset();
            this.popupForm.controls.name.enable();
        }

        if (changes.show && changes.show.currentValue) {
          if (changes.id && changes.id.currentValue && changes.isEditing && changes.isEditing.currentValue) {
            this.getItemInfo();
          }
        }
    }

    getItemInfo() {
        this.usersService.getItem(this.id).subscribe(data => {
           this.setItemInfo(data);
        }, () => {
          this.loaderService.hideAndShowTryAgain();
        }, () => {
          this.loaderService.hide();
        });
    }

    setItemInfo(data) {
      try {
        if (data.status) {
          const name = data.result.name;
          this.popupForm.controls.name.setValue(name);
          this.popupForm.controls.name.disable();
        } else {
          this.popupComponent.showPopupError({text: data.mmessage});
          this.closePopup();
        }
      } catch (e) {
        this.loaderService.hideAndShowTryAgain();
      }
    }

    closePopup(needUpdate = false) {
        this.popupForm.reset();
        this.emitClose.emit(needUpdate);
    }

    onSubmit() {
      const name = this.popupForm.controls.name.value;
      const password = this.popupForm.controls.password.value;
      if (this.isEditing) {
        this.usersService.editItem(this.id, password).subscribe((data) => {
          this.handleSubmitResult(data);
        }, () => {
          this.loaderService.hideAndShowTryAgain();
        }, () => {
          this.loaderService.hide();
        });
      } else {
        if (name && password) {
          this.usersService.createItem(name, password).subscribe((data) => {
            this.handleSubmitResult(data);
          }, () => {
            this.loaderService.hideAndShowTryAgain();
          }, () => {
            this.loaderService.hide();
          });
        } else {
          this.popupComponent.showPopupError({text: PROJECT_MESSAGES.notAllFieldsAreFilled});
        }
      }
    }

    handleSubmitResult(data) {
        try {
            if (data.status) {
                this.popupComponent.showPopupSuccess({text: data.message});
                this.closePopup(true);
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }
}
