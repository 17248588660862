<app-popup-wrapper [show]="show" (onClose)="closePopup()">
    <div class="dropdown js-dropdown">
        <div class="dropdown__head" (click)="toggleDropdown($event)">
            <div class="dropdown__title">
                STRIPE
            </div>
            <svg class="dropdown__arrow" width="21" height="12">
                <use xlink:href="../../../../assets/images/sprite.svg#arrow-bottom"></use>
            </svg>
        </div>
        <div class="dropdown__body">
            <form [formGroup]="stripeForm" (ngSubmit)="onStripeFormSubmit()" class="form-simple form-simple--primary">
                <div class="form-simple__item">
                    <input formControlName="privateKey"
                           placeholder="Private Key"
                           [type]="'text'"
                           class="form-simple__input"
                           autocomplete="off">
                </div>

                <div class="form-simple__item">
                    <input formControlName="webhookKey"
                           placeholder="Webhook Key"
                           [type]="'text'"
                           class="form-simple__input"
                           autocomplete="off">
                </div>

                <div class="form-simple__btns">
                    <button class="button snake-btn is-notouched">
                        Save
                        <svg><rect></rect></svg>
                    </button>
                </div>
            </form>
        </div>
    </div>

    <div class="dropdown js-dropdown is-hidden">
        <div class="dropdown__head" (click)="toggleDropdown($event)">
            <div class="dropdown__title">
                Square
            </div>
            <svg class="dropdown__arrow" width="21" height="12">
                <use xlink:href="../../../../assets/images/sprite.svg#arrow-bottom"></use>
            </svg>
        </div>
        <div class="dropdown__body">
            <form [formGroup]="squareForm" (ngSubmit)="onSquareFormSubmit()" class="form-simple form-simple--primary">
                <div class="form-simple__item">
                    <input formControlName="accessToken"
                           placeholder="Access Token"
                           [type]="'text'"
                           class="form-simple__input"
                           autocomplete="off">
                </div>

                <div class="form-simple__item">
                    <input formControlName="applicationId"
                           placeholder="Application Id"
                           [type]="'text'"
                           class="form-simple__input"
                           autocomplete="off">
                </div>

                <div class="form-simple__item">
                    <input formControlName="locationId"
                           placeholder="Location Id"
                           [type]="'text'"
                           class="form-simple__input"
                           autocomplete="off">
                </div>

                <div class="form-simple__btns">
                    <button class="button snake-btn is-notouched">
                        Save
                        <svg><rect></rect></svg>
                    </button>
                </div>
            </form>
        </div>
    </div>
    <div class="dropdown js-dropdown is-hidden">
        <div class="dropdown__head" (click)="toggleDropdown($event)">
            <div class="dropdown__title">
                Paypal
            </div>
            <svg class="dropdown__arrow" width="21" height="12">
                <use xlink:href="../../../../assets/images/sprite.svg#arrow-bottom"></use>
            </svg>
        </div>
        <div class="dropdown__body">
            <form [formGroup]="payPalForm" (ngSubmit)="onPayPalFormSubmit()" class="form-simple form-simple--primary">
                <div class="form-simple__item">
                    <input formControlName="clientId"
                           placeholder="Client Id"
                           [type]="'text'"
                           class="form-simple__input"
                           autocomplete="off">
                </div>

                <div class="form-simple__item">
                    <input formControlName="clientSecret"
                           placeholder="Client Secret"
                           [type]="'text'"
                           class="form-simple__input"
                           autocomplete="off">
                </div>

                <div class="form-simple__btns">
                    <button class="button snake-btn is-notouched">
                        Save
                        <svg><rect></rect></svg>
                    </button>
                </div>
            </form>
        </div>
    </div>
</app-popup-wrapper>



