import {DOMAIN_API} from "./local_settings";
import {AuthConfig} from '../modules/auth/_services/auth-config';
import {NewUser} from "../models/new-user";
import {ExistingUser} from "../models/existing-user";
import {AppVersion} from "../models/appVersion";
import {environment} from "../../environments/environment";

import {DOMAIN_DEMO_NAME, DOMAIN_DEMO_API} from "./demo_settings";

let domainApiUrl = DOMAIN_API;
if(window.location.hostname == DOMAIN_DEMO_NAME){
    domainApiUrl = DOMAIN_DEMO_API;
}

let API = domainApiUrl + '/agents';
if(environment.frontEndTest){
    API = domainApiUrl + '/pos';
}

const AUTH = API + '/users/session';
const USERS = API + '/users';
const USERS_USERS = USERS + '/users';
const SHOPS = API + '/shops';
const TERMINALS = SHOPS + '/terminals';
const LOCATION = SHOPS + '/location';
const CASHIER = SHOPS + '/cashier';

const SETTINGS = API + '/settings';
const STRIPE = SETTINGS + '/stripe';
const SQUARE = SETTINGS + '/square';
const PAYPAL = SETTINGS + '/paypal';
const PAYNOTE = SETTINGS + '/paynote';
const CONTACT = SETTINGS + '/contact';

export const AUTH_CONFIG: AuthConfig = {
    register: USERS + '/register',
    login: AUTH + '/login',
    refreshToken: AUTH + '/refresh',
};

export const SELL_CONFIG = {
    getPageInfo: SHOPS + '/list',
    onDeposit: SHOPS + '/deposit/create',
    getItemInfo: SHOPS + '/shop',
    onCreate: SHOPS + '/shop/create',
    onEdit: SHOPS + '/shop/save',
    getLimit: LOCATION,
    requestRefill: SHOPS + '/refill/request',
    getCredentialsList: SHOPS + '/payments/credential'
};
if (environment.frontEndTest) {
    SELL_CONFIG.getPageInfo = API + '/jsons/find/file?name=senetPosSellGetPageInfo';
    SELL_CONFIG.getItemInfo = API + '/jsons/find/file?name=senetPosSellGetItemInfo';
    SELL_CONFIG.onDeposit = API + '/jsons/find/file?name=success';
    SELL_CONFIG.onCreate = API + '/jsons/find/file?name=success';
    SELL_CONFIG.onEdit = API + '/jsons/find/file?name=success';
    SELL_CONFIG.getLimit = API + '/jsons/find/file?name=getBalance';
    SELL_CONFIG.requestRefill = API + '/jsons/find/file?name=success';
    SELL_CONFIG.getCredentialsList = API + '/jsons/find/file?name=senetPosSellGetItemAdditionInfo';
}

export const NEW_USER_CONFIG: NewUser = {
    save: LOCATION + '/create',
};
if (environment.frontEndTest) {
    NEW_USER_CONFIG.save = API + '/jsons/find/file?name=success';
}

export const EXISTING_USER_CONFIG: ExistingUser = {
    getUsers: LOCATION + '/search',
    userInfo: LOCATION + '/user',
    sell: LOCATION + '/deposit',
    buy: LOCATION + '/redeem',
    reverse: SHOPS + '/reverse',
    reverseCreate: SHOPS + '/reverse/create'
};

if (environment.frontEndTest) {
    EXISTING_USER_CONFIG.getUsers = API + '/users/users';
    EXISTING_USER_CONFIG.userInfo = API + '/jsons/find/file?name=senetPosExistingUserInfo';
    EXISTING_USER_CONFIG.sell = API + '/jsons/find/file?name=success';
    EXISTING_USER_CONFIG.buy = API + '/jsons/find/file?name=success';
    EXISTING_USER_CONFIG.reverse = API + '/jsons/find/file?name=success';
    EXISTING_USER_CONFIG.reverseCreate = API + '/jsons/find/file?name=success';
}

export const APP_VERSION_CONFIG: AppVersion = {
    version: API+'/app/version',
};

export const REPORTS_CONFIG = {
    getPageInfo: SHOPS + '/report',
    settle: SHOPS + '/settle',
    tableSettle: SHOPS + '/settle',
    confirmSettle: SHOPS + '/settle/confirm',
}
if (environment.frontEndTest) {
    REPORTS_CONFIG.getPageInfo = API + '/jsons/find/file?name=senetPosReportsGetPageInfo';
    REPORTS_CONFIG.settle = API + '/jsons/find/file?name=senetPosReportsSettle';
    REPORTS_CONFIG.tableSettle = API + '/jsons/find/file?name=senetPosReportsTableSettle';
    REPORTS_CONFIG.confirmSettle = API + '/jsons/find/file?name=success';

}


export const PAYMENT_SETTINGS_CONFIG = {
    getInfo: API + '/jsons/find/file?name=senetPosSellGetPageInfo',
    saveStripe: API + '/jsons/find/file?name=success',
    saveSquare: API + '/jsons/find/file?name=success',
    savePayPal: API + '/jsons/find/file?name=success'
}
if (environment.frontEndTest) {
    PAYMENT_SETTINGS_CONFIG.getInfo = API + '/jsons/find/file?name=senetPosSellPaymentSettingsInfo';
    PAYMENT_SETTINGS_CONFIG.saveStripe = API + '/jsons/find/file?name=success';
    PAYMENT_SETTINGS_CONFIG.saveSquare = API + '/jsons/find/file?name=success';
    PAYMENT_SETTINGS_CONFIG.savePayPal = API + '/jsons/find/file?name=success';
}

export const SETTINGS_CONFIG = {
    getStripeInfo: STRIPE,
    addStripeItem: STRIPE + '/create',
    disableStripeItem: STRIPE + '/disable',

    getSquareInfo: SQUARE,
    addSquareItem: SQUARE + '/create',
    disableSquareItem: SQUARE + '/disable',

    getPaypalInfo: PAYPAL,
    addPaypalItem: PAYPAL + '/create',
    disablePaypalItem: PAYPAL + '/disable',

    getContactInfo: CONTACT,
    saveContact: CONTACT + '/save',

    getPaynoteInfo: PAYNOTE,
    addPaynoteItem: PAYNOTE + '/create',
    disablePaynoteItem: PAYNOTE + '/disable',
};

if (environment.frontEndTest) {
    SETTINGS_CONFIG.getStripeInfo = API + '/jsons/find/file?name=senetPosSettingsGetStripeInfo';
    SETTINGS_CONFIG.getSquareInfo = API + '/jsons/find/file?name=senetPosSettingsGetSquareInfo';
    SETTINGS_CONFIG.getPaypalInfo = API + '/jsons/find/file?name=senetPosSettingsGetPaypalInfo';
    SETTINGS_CONFIG.getPaynoteInfo = API + '/jsons/find/file?name=senetPosSettingsGetPaynoteInfo';
    SETTINGS_CONFIG.addStripeItem = API + '/jsons/find/file?name=success';
    SETTINGS_CONFIG.addSquareItem = API + '/jsons/find/file?name=success';
    SETTINGS_CONFIG.addPaypalItem = API + '/jsons/find/file?name=success';
    SETTINGS_CONFIG.addPaynoteItem = API + '/jsons/find/file?name=success';
    SETTINGS_CONFIG.disableStripeItem = API + '/jsons/find/file?name=success';
    SETTINGS_CONFIG.disableSquareItem = API + '/jsons/find/file?name=success';
    SETTINGS_CONFIG.disablePaypalItem = API + '/jsons/find/file?name=error';
    SETTINGS_CONFIG.disablePaynoteItem = API + '/jsons/find/file?name=success';
    SETTINGS_CONFIG.getContactInfo = API + '/jsons/find/file?name=senetPosSettingsGetContactInfo';
    SETTINGS_CONFIG.saveContact = API + '/jsons/find/file?name=success';
}

export const SUPPORT_CONFIG = {
    session: API + '/chat/session/create',
};

if (environment.frontEndTest) {
    SUPPORT_CONFIG.session = API + '/jsons/find/file?name=getChatSession'
}

export const TERMINAL_CONFIG = {
    getTerminalsList: TERMINALS,
    getPageInfo: TERMINALS + '/location',
    getItemInfo: TERMINALS + '/terminal',
    setItemInfo: TERMINALS + '/save',
};

if (environment.frontEndTest) {
    TERMINAL_CONFIG.getTerminalsList = API + '/jsons/find/file?name=senetPosTerminalGetTerminalsList';
    TERMINAL_CONFIG.getPageInfo = API + '/jsons/find/file?name=senetPosTerminalGetPageInfo';
    TERMINAL_CONFIG.getItemInfo = API + '/jsons/find/file?name=senetPosTerminalGetItemInfo';
    TERMINAL_CONFIG.setItemInfo = API + '/jsons/find/file?name=success';
}

export const USERS_CONFIG = {
    getPageInfo: USERS_USERS,
    getItem: USERS_USERS + '/user',
    createItem: USERS_USERS + '/create',
    editItem: USERS_USERS + '/save',
    deleteItem: USERS_USERS + '/delete'
};

if (environment.frontEndTest) {
    USERS_CONFIG.getPageInfo = API + '/jsons/find/file?name=senetPosUsersGetPageInfo';
    USERS_CONFIG.getItem = API + '/jsons/find/file?name=senetPosUsersGetItemInfo';
    USERS_CONFIG.createItem = API + '/jsons/find/file?name=success';
    USERS_CONFIG.editItem = API + '/jsons/find/file?name=success';
    USERS_CONFIG.deleteItem = API + '/jsons/find/file?name=success';
}

export const CASH_REGISTER_CONFIG = {
    getPageInfo: CASHIER,
    open: CASHIER + '/open',
    close: CASHIER + '/close',
};

if (environment.frontEndTest) {
    CASH_REGISTER_CONFIG.getPageInfo = API + '/jsons/find/file?name=senetPosCashRegisterGetPageInfo';
    CASH_REGISTER_CONFIG.open = API + '/jsons/find/file?name=senetPosCashRegisterOpen';
    CASH_REGISTER_CONFIG.close = API + '/jsons/find/file?name=senetPosCashRegisterClose';
}
