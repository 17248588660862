import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PopupComponent} from "../../../shared/popup/popup.component";
import {LoaderService} from "../../../service/loader.service";
import {ReportsService} from "../../../service/reports/reports.service";

@Component({
    selector: 'app-popup-reports-table',
    templateUrl: './popup-reports-table.component.html',
    styleUrls: ['./popup-reports-table.component.scss']
})
export class PopupReportsTableComponent implements OnInit {
    @Input() show: boolean;
    @Input() info: any;
    @Output('onClose') emitClose: EventEmitter<any> = new EventEmitter();

    tableItems = [];
    totalAmount;
    constructor(private loaderService: LoaderService,
                private popupComponent: PopupComponent,
                private reportsService: ReportsService) {

    }

    ngOnInit() {}

    ngOnChanges(changes) {
        if (changes.show && changes.show.currentValue) {
           this.getTableData();
        }
    }

    getTableData() {
        if (this.info.type === 'single') {
            this.reportsService.tableSettle(this.info.id).subscribe(data => {
                this.setTableData(data);
            }, () => {
                this.loaderService.hideAndShowTryAgain();
                this.closePopup();
            }, () => {
                this.loaderService.hide();
            });
        } else {
            this.reportsService.settle().subscribe(data => {
                this.setTableData(data);
            }, () => {
                this.loaderService.hideAndShowTryAgain();
                this.closePopup();
            }, () => {
                this.loaderService.hide();
            });
        }
    }

    setTableData(data) {
        try {
            if (data.status) {
                this.tableItems = data.result;
                this.totalAmount = data.totalAmount;
                if (this.info.type == 'single') {
                    this.tableItems.map(el => el.amount *= -1);
                }
            } else {
                this.popupComponent.showPopupError({text: data.message});
                this.closePopup();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
            this.closePopup();
        }
    }

    onSubmit(id) {
        const text = `Please confirm settling`;

        this.popupComponent.showPopupAgreement({text: text,
            confirmButtonText: 'CONFIRM<svg><rect></rect></svg>',
            cancelButtonText: '<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                              '<path d="M25.5 24.1719L18.3281 17L25.5 9.82812C25.8668 9.46136 25.8668 8.86676 25.5 8.5C25.1332 8.13324 24.5386 8.13324 24.1719 8.5L17 15.6719L9.82813 8.5C9.46136 8.13324 8.86676 8.13324 8.5 8.5C8.13324 8.86676 8.13324 9.46136 8.5 9.82812L15.6719 17L8.5 24.1719C8.13324 24.5386 8.13324 25.1332 8.5 25.5C8.86676 25.8668 9.46136 25.8668 9.82813 25.5L17 18.3281L24.1719 25.5C24.5386 25.8668 25.1332 25.8668 25.5 25.5C25.8668 25.1332 25.8668 24.5386 25.5 24.1719Z" fill="#FDFD88"/>\n' +
                              '</svg>'}, (status) => {
            if (status) {
                this.submit(id);
            }
        });
    }

    submit(id) {
        this.reportsService.confirmSettle(id).subscribe(data => {
            if(data.status){
                this.popupComponent.showPopupSuccess({text:data.message});
                this.getTableData();
            } else {
                this.popupComponent.showPopupError({text:data.message});
            }
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    closePopup() {
        this.emitClose.emit();
    }
}
