import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {LoaderService} from "./loader.service";
import {SETTINGS_CONFIG} from "../config/api";

@Injectable({
    providedIn: 'root'
})
export class SettingsService {
    private config = SETTINGS_CONFIG;

    constructor(private http: HttpClient,
                private loaderService: LoaderService) {

    }

    getStripeInfo() {
        this.loaderService.show();
        return this.http.post<any>(this.config.getStripeInfo, {});
    }

    addStripeItem(name, secretKey, webhookSecretKey) {
        this.loaderService.show();
        return this.http.post<any>(this.config.addStripeItem, {name, secretKey, webhookSecretKey});
    }

    disableStripeItem(id) {
        this.loaderService.show();
        return this.http.post<any>(this.config.disableStripeItem, {id});
    }

    getSquareInfo() {
        this.loaderService.show();
        return this.http.post<any>(this.config.getSquareInfo, {});
    }

    addSquareItem(name, aplicationId, locationId, accessToken) {
        this.loaderService.show();
        return this.http.post<any>(this.config.addSquareItem, {name, aplicationId, locationId, accessToken});
    }

    disableSquareItem(id) {
        this.loaderService.show();
        return this.http.post<any>(this.config.disableSquareItem, {id});
    }

    getPaypalInfo() {
        this.loaderService.show();
        return this.http.post<any>(this.config.getPaypalInfo, {});
    }

    addPaypalItem(name, clientId, clientSecret) {
        this.loaderService.show();
        return this.http.post<any>(this.config.addPaypalItem, {name, clientId, clientSecret});
    }

    disablePaypalItem(id) {
        this.loaderService.show();
        return this.http.post<any>(this.config.disablePaypalItem, {id});
    }

    getPaynoteInfo() {
        this.loaderService.show();
        return this.http.post<any>(this.config.getPaynoteInfo, {});
    }

    addPaynoteItem(name, privateToken) {
        this.loaderService.show();
        return this.http.post<any>(this.config.addPaynoteItem, {name, privateToken});
    }

    disablePaynoteItem(id) {
        this.loaderService.show();
        return this.http.post<any>(this.config.disablePaynoteItem, {id});
    }

    getContactInfo() {
        this.loaderService.show();
        return this.http.post<any>(this.config.getContactInfo, {});
    }

    saveContact(email) {
        this.loaderService.show();
        return this.http.post<any>(this.config.saveContact, {email});
    }
}
