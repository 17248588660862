import {Injectable} from '@angular/core';
import {USER_ROLES_CONFIG} from "../config/user-roles.config";

@Injectable({
  providedIn: 'root'
})
export class UserRoleService {
  private USER_ROLES = USER_ROLES_CONFIG;
  private userRoleSessionKey = 'userRole';
  private storage = sessionStorage;

  constructor() {}

  setUserRole(role) {
      this.storage.setItem(this.userRoleSessionKey, role);
  }

  getUserRole(): string {
    return this.storage.getItem(this.userRoleSessionKey);
  }

  removeUserRole(): void {
    this.storage.removeItem(this.userRoleSessionKey);
  }

  isUserRoleNotLocation(): boolean {
    return +this.getUserRole() !== this.USER_ROLES.location;
  }

  isUserRoleLocation(): boolean {
    return +this.getUserRole() === this.USER_ROLES.location;
  }

  isUserRoleCashier(): boolean {
    return +this.getUserRole() === this.USER_ROLES.cashier;
  }

  isUserRoleAdmin(): boolean {
    return +this.getUserRole() === this.USER_ROLES.admin;
  }

  isUserRoleDistributor(): boolean {
    return +this.getUserRole() === this.USER_ROLES.distributor;
  }

  isUserRoleOperator(): boolean {
    return +this.getUserRole() === this.USER_ROLES.operator;
  }
}
