<div class="login">
    <div class="login__inner">
        <div class="login__header"></div>
        <div class="login__title">POS</div>
        <div class="login__forms">
            <div class="text-center login-tab">
                <form [formGroup]="userForm" (ngSubmit)="onSubmit()" class="login__form">
                    <div class="login__item">
                        <input formControlName="user_name"
                               placeholder="Username"
                               [type]="'text'"
                               class="login-input"
                               autocomplete="off">

                    </div>
                    <div class="login__item">
                        <input formControlName="password"
                               placeholder="Password"
                               [type]="'password'"
                               class="login-input"
                               autocomplete="off">

                    </div>
                    <div class="login__item">

                        <input formControlName="remember_user"
                               id="remember_user"
                               [type]="'checkbox'"
                               class="login-checkbox"
                               (change)="onRememberUser($event)"
                               [checked]="!!userForm.controls.remember_user.value"
                               autocomplete="off">
                        <label for="remember_user">Remember Username</label>
                    </div>
                    <div class="form-btn-submit">
                        <button class="button snake-btn is-notouched" [disabled]="!userForm.valid">
                            Login
                            <svg><rect></rect></svg>
                        </button>

                    </div>
                </form>
            </div>
        </div>
        <div class="login__footer">
<!--            <a [routerLink]="['/signup']">Don’t have an account?</a>-->
<!--            <a>Don’t have an account?</a>-->
        </div>
    </div>
</div>
