import { SELL_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../loader.service";
var SellService = /** @class */ (function () {
    function SellService(http, loaderService) {
        this.http = http;
        this.loaderService = loaderService;
        this.config = SELL_CONFIG;
    }
    SellService.prototype.getPageInfo = function (page) {
        this.loaderService.show();
        return this.http.post(this.config.getPageInfo, { page: page });
    };
    SellService.prototype.getItemInfo = function (id) {
        this.loaderService.show();
        return this.http.post(this.config.getItemInfo, { id: id });
    };
    SellService.prototype.onDeposit = function (id, amount) {
        this.loaderService.show();
        return this.http.post(this.config.onDeposit, { id: id, amount: amount });
    };
    SellService.prototype.onCreate = function (name, login, password, rate, email, games, payments, credentials, redeems, cashMessage, cashTickets, timeZoneId, cashPayoutLimit, referralBonus, cashRegister, nftBonusWheel, commission, support, promoCode) {
        this.loaderService.show();
        if (promoCode !== null) {
            return this.http.post(this.config.onCreate, { name: name, login: login, password: password, rate: rate, email: email, games: games, payments: payments, credentials: credentials, redeems: redeems, cashMessage: cashMessage, cashTickets: cashTickets, timeZoneId: timeZoneId, cashPayoutLimit: cashPayoutLimit, referralBonus: referralBonus, cashRegister: cashRegister, nftBonusWheel: nftBonusWheel, commission: commission, support: support, promoCode: promoCode });
        }
        else {
            return this.http.post(this.config.onCreate, { name: name, login: login, password: password, rate: rate, email: email, games: games, payments: payments, credentials: credentials, redeems: redeems, cashMessage: cashMessage, cashTickets: cashTickets, timeZoneId: timeZoneId, cashPayoutLimit: cashPayoutLimit, referralBonus: referralBonus, cashRegister: cashRegister, nftBonusWheel: nftBonusWheel, commission: commission, support: support });
        }
    };
    SellService.prototype.onEdit = function (id, name, login, password, rate, email, games, payments, credentials, redeems, cashMessage, cashTickets, timeZoneId, cashPayoutLimit, referralBonus, cashRegister, nftBonusWheel, commission, support, promoCode) {
        this.loaderService.show();
        if (promoCode !== null) {
            return this.http.post(this.config.onEdit, { id: id, name: name, login: login, password: password, rate: rate, email: email, games: games, payments: payments, credentials: credentials, redeems: redeems, cashMessage: cashMessage, cashTickets: cashTickets, timeZoneId: timeZoneId, cashPayoutLimit: cashPayoutLimit, referralBonus: referralBonus, cashRegister: cashRegister, nftBonusWheel: nftBonusWheel, commission: commission, support: support, promoCode: promoCode });
        }
        else {
            return this.http.post(this.config.onEdit, { id: id, name: name, login: login, password: password, rate: rate, email: email, games: games, payments: payments, credentials: credentials, redeems: redeems, cashMessage: cashMessage, cashTickets: cashTickets, timeZoneId: timeZoneId, cashPayoutLimit: cashPayoutLimit, referralBonus: referralBonus, cashRegister: cashRegister, nftBonusWheel: nftBonusWheel, commission: commission, support: support });
        }
    };
    SellService.prototype.getLimit = function () {
        this.loaderService.show();
        return this.http.post(this.config.getLimit, {});
    };
    SellService.prototype.requestRefill = function () {
        this.loaderService.show();
        return this.http.post(this.config.requestRefill, {});
    };
    SellService.prototype.getCredentialsList = function (shortName) {
        this.loaderService.show();
        return this.http.post(this.config.getCredentialsList, { shortName: shortName });
    };
    SellService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SellService_Factory() { return new SellService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: SellService, providedIn: "root" });
    return SellService;
}());
export { SellService };
